@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap");

/* document-file-template.css */
/* FIX FOR DOCUMENT LIBRARY */

.file-template input[type="text"],
.file-template input[type="date"],
.file-template input[type="time"],
.file-template select.form-select-lg {
  height: 25px;
  border-radius: 5px;
  width: 95%;
  padding: 0.375rem 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  display: flex;
  max-height: 40px !important;
}
.file-template select.form-select-lg {
  font-weight: 500 !important;
}

.k-pdf-export .input[type="checkbox"],
.k-pdf-export .input[type="radio"],
.k-pdf-export .radio-input {
  font-size: 1px;
}

.k-pdf-export b {
  font-weight: 700;
  font-size: 13px;
}

.k-pdf-export strong {
  font-weight: 700;
  font-size: 13px;
}

.k-pdf-export .radio-label {
  font-size: 12px;
  font-weight: 800;
}

.k-pdf-export select {
  max-height: 10px !important;
  border: 1px solid #ced4da;
  font-size: 12px !important;
}

.file-template select {
  max-height: 30px !important;
}

.st-radio-container,
.st-checkbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
}

.st-radio-label,
.st-checkbox-label {
  flex-basis: calc(50% - 10px);
  margin-left: 4px;
}

.st-radio-content,
.st-checkbox-content {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.st-radio-item,
.st-checkbox-item {
  display: flex;
  align-items: center;
  margin-bottom: -10px;
}

.st-radio-input,
.st-checkbox-input {
  margin-right: 8px;
}

.file-template .view-only {
  font-size: 14px;
}

.file-template .pdf-right-margin {
  margin-right: 20px;
  margin-left: 0;
}

.file-template .margin-0 {
  margin: 0px;
}

.pdf-content-right-margin {
  margin-right: 50px;
}

.pdf-signature-right-margin {
  margin-right: 320px;
}

.k-pdf-export .view-only {
  font-size: 18px;
}

.file-template {
  margin-top: 23px;
}

.file-template select {
  display: flex;
  border: 1px solid #ced4da;
  height: 30px;
}

.file-template textarea {
  width: 95%;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.k-pdf-export textarea {
  width: 90%;
  padding-right: 25px !important;
}

.file-template .col-align {
  display: flex;
  align-items: center;
}

.file-template .altRows tr:nth-child(odd) {
  background-color: #f2f2f2;
  max-width: 750px;
}

.k-pdf-export .altRows tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.file-template .w-1,
.k-pdf-export .w-1 {
  width: 1px !important;
}

.file-template .w-2,
.k-pdf-export .w-2 {
  width: 2px !important;
}
.file-template .w-3,
.k-pdf-export .w-3 {
  width: 3px !important;
}

.file-template .w-4,
.k-pdf-export .w-4 {
  width: 4px !important;
}

.file-template .w-5,
.k-pdf-export .w-5 {
  width: 5px !important;
}

.file-template .w-6,
.k-pdf-export .w-6 {
  width: 6px !important;
}

.file-template .w-7,
.k-pdf-export .w-7 {
  width: 7px !important;
}

.file-template .w-8,
.k-pdf-export .w-8 {
  width: 8px !important;
}

.file-template .w-9,
.k-pdf-export .w-9 {
  width: 9px !important;
}

.file-template .w-10,
.k-pdf-export .w-10 {
  width: 10px !important;
}

.file-template .w-11,
.k-pdf-export .w-11 {
  width: 11px !important;
}

.file-template .w-12,
.k-pdf-export .w-12 {
  width: 12px !important;
}

.file-template .w-13,
.k-pdf-export .w-13 {
  width: 13px !important;
}

.file-template .w-14,
.k-pdf-export .w-14 {
  width: 14px !important;
}

.file-template .w-15,
.k-pdf-export .w-15 {
  width: 15px !important;
}

.file-template .w-16,
.k-pdf-export .w-16 {
  width: 16px !important;
}

.file-template .w-17,
.k-pdf-export .w-17  {
  width: 17px !important;
}

.file-template .w-18,
.k-pdf-export .w-18 {
  width: 18px !important;
}


.file-template .w-19,
.k-pdf-export .w-19 {
  width: 19px !important;
}


.file-template .w-20,
.k-pdf-export .w-20 {
  width: 20px !important;
}


.file-template .w-21,
.k-pdf-export .w-21 {
  width: 21px !important;
}


.file-template .w-22,
.k-pdf-export .w-22 {
  width: 22px !important;
}

.file-template .w-23,
.k-pdf-export .w-23 {
  width: 23px !important;
}

.file-template .w-24,
.k-pdf-export .w-24 {
  width: 24px !important;
}

.file-template .w-25,
.k-pdf-export .w-25 {
  width: 25px !important;
}


.file-template .w-26,
.k-pdf-export .w-26 {
  width: 26px !important;
}

.file-template .w-27,
.k-pdf-export .w-27 {
  width: 27px !important;
}

.file-template .w-28,
.k-pdf-export .w-28 {
  width: 28px !important;
}


.file-template .w-29,
.k-pdf-export .w-29 {
  width: 29px !important;
}

.file-template .w-30,
.k-pdf-export .w-30 {
  width: 30px !important;
}


.file-template .w-31,
.k-pdf-export .w-31 {
  width: 31px !important;
}
.file-template .w-32,
.k-pdf-export .w-32 {
  width: 32px !important;
}
.file-template .w-33,
.k-pdf-export .w-33 {
  width: 33px !important;
}

.file-template .w-34,
.k-pdf-export .w-34 {
  width: 34px !important;
}

.file-template .w-35,
.k-pdf-export .w-35 {
  width: 35px !important;
}

.file-template .w-36,
.k-pdf-export .w-36 {
  width: 36px !important;
}


.file-template .w-37,
.k-pdf-export .w-37 {
  width: 37px !important;
}


.file-template .w-38,
.k-pdf-export .w-38 {
  width: 38px !important;
}
.file-template .w-39,
.k-pdf-export .w-39 {
  width: 39px !important;
}

.file-template .w-40,
.k-pdf-export .w-40 {
  width: 40px !important;
}
.file-template .w-41,
.k-pdf-export .w-41 {
  width: 41px !important;
}

.file-template .w-42,
.k-pdf-export .w-42 {
  width: 42px !important;
}

.file-template .w-43,
.k-pdf-export .w-43 {
  width: 43px !important;
}

.file-template .w-44,
.k-pdf-export .w-44 {
  width: 44px !important;
}

.file-template .w-45 ,
.k-pdf-export .w-45 {
  width: 45px !important;
}

.file-template .w-46,
.k-pdf-export .w-46 {
  width: 46px !important;
}

.file-template .w-47,
.k-pdf-export .w-47 {
  width: 47px !important;
}

.file-template .w-48,
.k-pdf-export .w-48 {
  width: 48px !important;
}

.file-template .w-49,
.k-pdf-export .w-49 {
  width: 49px !important;
}

.file-template .w-50,
.k-pdf-export .w-50 {
  width: 50px !important;
}

.file-template .w-51,
.k-pdf-export .w-51 {
  width: 51px !important;
}

.file-template .w-52,
.k-pdf-export .w-52 {
  width: 52px !important;
}

.file-template .w-53,
.k-pdf-export .w-53 {
  width: 53px !important;
}

.file-template .w-54,
.k-pdf-export .w-54 {
  width: 54px !important;
}

.file-template .w-55,
.k-pdf-export .w-55 {
  width: 55px !important;
}

.file-template .w-56,
.k-pdf-export .w-56 {
  width: 56px !important;
}

.file-template .w-57,
.k-pdf-export .w-57 {
  width: 57px !important;
}

.file-template .w-58,
.k-pdf-export .w-58 {
  width: 58px !important;
}

.file-template .w-59,
.k-pdf-export .w-59 {
  width: 59px !important;
}

.file-template .w-60,
.k-pdf-export .w-60 {
  width: 60px !important;
}

.file-template .w-61,
.k-pdf-export .w-61 {
  width: 61px !important;
}

.file-template .w-62,
.k-pdf-export .w-62 {
  width: 62px !important;
}

.file-template .w-63,
.k-pdf-export .w-63 {
  width: 63px !important;
}

.file-template .w-64,
.k-pdf-export .w-64 {
  width: 64px !important;
}

.file-template .w-65,
.k-pdf-export .w-65 {
  width: 65px !important;
}

.file-template .w-66,
.k-pdf-export .w-66 {
  width: 66px !important;
}

.file-template .w-67,
.k-pdf-export .w-67 {
  width: 67px !important;
}

.file-template .w-68,
.k-pdf-export .w-68 {
  width: 68px !important;
}

.file-template .w-69,
.k-pdf-export .w-69 {
  width: 69px !important;
}

.file-template .w-70,
.k-pdf-export .w-70 {
  width: 70px !important;
}

.file-template .w-71,
.k-pdf-export .w-71 {
  width: 71px !important;
}

.file-template .w-72,
.k-pdf-export .w-72 {
  width: 72px !important;
}

.file-template .w-73,
.k-pdf-export .w-73 {
  width: 73px !important;
}

.file-template .w-74,
.k-pdf-export .w-74 {
  width: 74px !important;
}

.file-template .w-75,
.k-pdf-export .w-75 {
  width: 75px !important;
}

.file-template .w-76,
.k-pdf-export .w-76 {
  width: 76px !important;
}

.file-template .w-77,
.k-pdf-export .w-77 {
  width: 77px !important;
}

.file-template .w-78,
.k-pdf-export .w-78 {
  width: 78px !important;
}

.file-template .w-79,
.k-pdf-export .w-79 {
  width: 79px !important;
}

.file-template .w-80,
.k-pdf-export .w-80 {
  width: 80px !important;
}

.file-template .w-81,
.k-pdf-export .w-81 {
  width: 81px !important;
}

.file-template .w-82,
.k-pdf-export .w-82 {
  width: 82px !important;
}

.file-template .w-83,
.k-pdf-export .w-83 {
  width: 83px !important;
}

.file-template .w-84,
.k-pdf-export .w-84 {
  width: 84px !important;
}

.file-template .w-85,
.k-pdf-export .w-85 {
  width: 85px !important;
}

.file-template .w-86,
.k-pdf-export .w-86 {
  width: 86px !important;
}

.file-template .w-87,
.k-pdf-export .w-87 {
  width: 87px !important;
}

.file-template .w-88 ,
.k-pdf-export .w-88 {
  width: 88px !important;
}

.file-template .w-89,
.k-pdf-export .w-89 {
  width: 89px !important;
}

.file-template .w-90,
.k-pdf-export .w-90 {
  width: 90px !important;
}

.file-template .w-91,
.k-pdf-export .w-91 {
  width: 91px !important;
}

.file-template .w-92,
.k-pdf-export .w-92 {
  width: 92px !important;
}

.file-template .w-93,
.k-pdf-export .w-93 {
  width: 93px !important;
}

.file-template .w-94,
.k-pdf-export .w-94 {
  width: 94px !important;
}

.file-template .w-95,
.k-pdf-export .w-95 {
  width: 95px !important;
}

.file-template .w-96,
.k-pdf-export .w-96 {
  width: 96px !important;
}

.file-template .w-97,
.k-pdf-export .w-97 {
  width: 97px !important;
}

.file-template .w-98,
.k-pdf-export .w-98 {
  width: 98px !important;
}

.file-template .w-99,
.k-pdf-export .w-99 {
  width: 99px !important;
}

.file-template .w-100,
.k-pdf-export .w-100 {
  width: 100px !important;
}

.file-template .w-101 {
  width: 101px !important;
}

.k-pdf-export .w-101 {
  width: 101px !important;
}

.file-template .w-102 {
  width: 102px !important;
}

.k-pdf-export .w-102 {
  width: 102px !important;
}

.file-template .w-103 {
  width: 103px !important;
}

.k-pdf-export .w-103 {
  width: 103px !important;
}

.file-template .w-104 {
  width: 104px !important;
}

.k-pdf-export .w-104 {
  width: 104px !important;
}

.file-template .w-105 {
  width: 105px !important;
}

.k-pdf-export .w-105 {
  width: 105px !important;
}

.file-template .w-106 {
  width: 106px !important;
}

.k-pdf-export .w-106 {
  width: 106px !important;
}

.file-template .w-107 {
  width: 107px !important;
}

.k-pdf-export .w-107 {
  width: 107px !important;
}

.file-template .w-108 {
  width: 108px !important;
}

.k-pdf-export .w-108 {
  width: 108px !important;
}

.file-template .w-109 {
  width: 109px !important;
}

.k-pdf-export .w-109 {
  width: 109px !important;
}

.file-template .w-110 {
  width: 110px !important;
}

.k-pdf-export .w-110 {
  width: 110px !important;
}

.file-template .w-111 {
  width: 111px !important;
}

.k-pdf-export .w-111 {
  width: 111px !important;
}

.file-template .w-112 {
  width: 112px !important;
}

.k-pdf-export .w-112 {
  width: 112px !important;
}

.file-template .w-113 {
  width: 113px !important;
}

.k-pdf-export .w-113 {
  width: 113px !important;
}

.file-template .w-114 {
  width: 114px !important;
}

.k-pdf-export .w-114 {
  width: 114px !important;
}

.file-template .w-115 {
  width: 115px !important;
}

.k-pdf-export .w-115 {
  width: 115px !important;
}

.file-template .w-116 {
  width: 116px !important;
}

.k-pdf-export .w-116 {
  width: 116px !important;
}

.file-template .w-117 {
  width: 117px !important;
}

.k-pdf-export .w-117 {
  width: 117px !important;
}

.file-template .w-118 {
  width: 118px !important;
}

.k-pdf-export .w-118 {
  width: 118px !important;
}

.file-template .w-119 {
  width: 119px !important;
}

.k-pdf-export .w-119 {
  width: 119px !important;
}

.file-template .w-120 {
  width: 120px !important;
}

.k-pdf-export .w-120 {
  width: 120px !important;
}

.file-template .w-121 {
  width: 121px !important;
}

.k-pdf-export .w-121 {
  width: 121px !important;
}

.file-template .w-122 {
  width: 122px !important;
}

.k-pdf-export .w-122 {
  width: 122px !important;
}

.file-template .w-123 {
  width: 123px !important;
}

.k-pdf-export .w-123 {
  width: 123px !important;
}

.file-template .w-124 {
  width: 124px !important;
}

.k-pdf-export .w-124 {
  width: 124px !important;
}

.file-template .w-125 {
  width: 125px !important;
}

.k-pdf-export .w-125 {
  width: 125px !important;
}

.file-template .w-126 {
  width: 126px !important;
}

.k-pdf-export .w-126 {
  width: 126px !important;
}

.file-template .w-127 {
  width: 127px !important;
}

.k-pdf-export .w-127 {
  width: 127px !important;
}

.file-template .w-128 {
  width: 128px !important;
}

.k-pdf-export .w-128 {
  width: 128px !important;
}

.file-template .w-129 {
  width: 129px !important;
}

.k-pdf-export .w-129 {
  width: 129px !important;
}

.file-template .w-130 {
  width: 130px !important;
}

.k-pdf-export .w-130 {
  width: 130px !important;
}

.file-template .w-131 {
  width: 131px !important;
}

.k-pdf-export .w-131 {
  width: 131px !important;
}

.file-template .w-132 {
  width: 132px !important;
}

.k-pdf-export .w-132 {
  width: 132px !important;
}

.file-template .w-133 {
  width: 133px !important;
}

.k-pdf-export .w-133 {
  width: 133px !important;
}

.file-template .w-134 {
  width: 134px !important;
}

.k-pdf-export .w-134 {
  width: 134px !important;
}

.file-template .w-135 {
  width: 135px !important;
}

.k-pdf-export .w-135 {
  width: 135px !important;
}

.file-template .w-136 {
  width: 136px !important;
}

.k-pdf-export .w-136 {
  width: 136px !important;
}

.file-template .w-137 {
  width: 137px !important;
}

.k-pdf-export .w-137 {
  width: 137px !important;
}

.file-template .w-138 {
  width: 138px !important;
}

.k-pdf-export .w-138 {
  width: 138px !important;
}

.file-template .w-139 {
  width: 139px !important;
}

.k-pdf-export .w-139 {
  width: 139px !important;
}

.file-template .w-140 {
  width: 140px !important;
}

.k-pdf-export .w-140 {
  width: 140px !important;
}

.file-template .w-141 {
  width: 141px !important;
}

.k-pdf-export .w-141 {
  width: 141px !important;
}

.file-template .w-142 {
  width: 142px !important;
}

.k-pdf-export .w-142 {
  width: 142px !important;
}

.file-template .w-143 {
  width: 143px !important;
}

.k-pdf-export .w-143 {
  width: 143px !important;
}

.file-template .w-144 {
  width: 144px !important;
}

.k-pdf-export .w-144 {
  width: 144px !important;
}

.file-template .w-145 {
  width: 145px !important;
}

.k-pdf-export .w-145 {
  width: 145px !important;
}

.file-template .w-146 {
  width: 146px !important;
}

.k-pdf-export .w-146 {
  width: 146px !important;
}

.file-template .w-147 {
  width: 147px !important;
}

.k-pdf-export .w-147 {
  width: 147px !important;
}

.file-template .w-148 {
  width: 148px !important;
}

.k-pdf-export .w-148 {
  width: 148px !important;
}

.file-template .w-149 {
  width: 149px !important;
}

.k-pdf-export .w-149 {
  width: 149px !important;
}

.file-template .w-150 {
  width: 150px !important;
}

.k-pdf-export .w-150 {
  width: 150px !important;
}

.file-template .w-151 {
  width: 151px !important;
}

.k-pdf-export .w-151 {
  width: 151px !important;
}

.file-template .w-152 {
  width: 152px !important;
}

.k-pdf-export .w-152 {
  width: 152px !important;
}

.file-template .w-153 {
  width: 153px !important;
}

.k-pdf-export .w-153 {
  width: 153px !important;
}

.file-template .w-154 {
  width: 154px !important;
}

.k-pdf-export .w-154 {
  width: 154px !important;
}

.file-template .w-155 {
  width: 155px !important;
}

.k-pdf-export .w-155 {
  width: 155px !important;
}

.file-template .w-156 {
  width: 156px !important;
}

.k-pdf-export .w-156 {
  width: 156px !important;
}

.file-template .w-157 {
  width: 157px !important;
}

.k-pdf-export .w-157 {
  width: 157px !important;
}

.file-template .w-158 {
  width: 158px !important;
}

.k-pdf-export .w-158 {
  width: 158px !important;
}

.file-template .w-159 {
  width: 159px !important;
}

.k-pdf-export .w-159 {
  width: 159px !important;
}

.file-template .w-160 {
  width: 160px !important;
}

.k-pdf-export .w-160 {
  width: 160px !important;
}

.file-template .w-161 {
  width: 161px !important;
}

.k-pdf-export .w-161 {
  width: 161px !important;
}

.file-template .w-162 {
  width: 162px !important;
}

.k-pdf-export .w-162 {
  width: 162px !important;
}

.file-template .w-163 {
  width: 163px !important;
}

.k-pdf-export .w-163 {
  width: 163px !important;
}

.file-template .w-164 {
  width: 164px !important;
}

.k-pdf-export .w-164 {
  width: 164px !important;
}

.file-template .w-165 {
  width: 165px !important;
}

.k-pdf-export .w-165 {
  width: 165px !important;
}

.file-template .w-166 {
  width: 166px !important;
}

.k-pdf-export .w-166 {
  width: 166px !important;
}

.file-template .w-167 {
  width: 167px !important;
}

.k-pdf-export .w-167 {
  width: 167px !important;
}

.file-template .w-168 {
  width: 168px !important;
}

.k-pdf-export .w-168 {
  width: 168px !important;
}

.file-template .w-169 {
  width: 169px !important;
}

.k-pdf-export .w-169 {
  width: 169px !important;
}

.file-template .w-170 {
  width: 170px !important;
}

.k-pdf-export .w-170 {
  width: 170px !important;
}

.file-template .w-171 {
  width: 171px !important;
}

.k-pdf-export .w-171 {
  width: 171px !important;
}

.file-template .w-172 {
  width: 172px !important;
}

.k-pdf-export .w-172 {
  width: 172px !important;
}

.file-template .w-173 {
  width: 173px !important;
}

.k-pdf-export .w-173 {
  width: 173px !important;
}

.file-template .w-174 {
  width: 174px !important;
}

.k-pdf-export .w-174 {
  width: 174px !important;
}

.file-template .w-175 {
  width: 175px !important;
}

.k-pdf-export .w-175 {
  width: 175px !important;
}

.file-template .w-176 {
  width: 176px !important;
}

.k-pdf-export .w-176 {
  width: 176px !important;
}

.file-template .w-177 {
  width: 177px !important;
}

.k-pdf-export .w-177 {
  width: 177px !important;
}

.file-template .w-178 {
  width: 178px !important;
}

.k-pdf-export .w-178 {
  width: 178px !important;
}

.file-template .w-179 {
  width: 179px !important;
}

.k-pdf-export .w-179 {
  width: 179px !important;
}

.file-template .w-180 {
  width: 180px !important;
}

.k-pdf-export .w-180 {
  width: 180px !important;
}

.file-template .w-181 {
  width: 181px !important;
}

.k-pdf-export .w-181 {
  width: 181px !important;
}

.file-template .w-182 {
  width: 182px !important;
}

.k-pdf-export .w-182 {
  width: 182px !important;
}

.file-template .w-183 {
  width: 183px !important;
}

.k-pdf-export .w-183 {
  width: 183px !important;
}

.file-template .w-184 {
  width: 184px !important;
}

.k-pdf-export .w-184 {
  width: 184px !important;
}

.file-template .w-185 {
  width: 185px !important;
}

.k-pdf-export .w-185 {
  width: 185px !important;
}

.file-template .w-186 {
  width: 186px !important;
}

.k-pdf-export .w-186 {
  width: 186px !important;
}

.file-template .w-187 {
  width: 187px !important;
}

.k-pdf-export .w-187 {
  width: 187px !important;
}

.file-template .w-188 {
  width: 188px !important;
}

.k-pdf-export .w-188 {
  width: 188px !important;
}

.file-template .w-189 {
  width: 189px !important;
}

.k-pdf-export .w-189 {
  width: 189px !important;
}

.file-template .w-190 {
  width: 190px !important;
}

.k-pdf-export .w-190 {
  width: 190px !important;
}

.file-template .w-191 {
  width: 191px !important;
}

.k-pdf-export .w-191 {
  width: 191px !important;
}

.file-template .w-192 {
  width: 192px !important;
}

.k-pdf-export .w-192 {
  width: 192px !important;
}

.file-template .w-193 {
  width: 193px !important;
}

.k-pdf-export .w-193 {
  width: 193px !important;
}

.file-template .w-194 {
  width: 194px !important;
}

.k-pdf-export .w-194 {
  width: 194px !important;
}

.file-template .w-195 {
  width: 195px !important;
}

.k-pdf-export .w-195 {
  width: 195px !important;
}

.file-template .w-196 {
  width: 196px !important;
}

.k-pdf-export .w-196 {
  width: 196px !important;
}

.file-template .w-197 {
  width: 197px !important;
}

.k-pdf-export .w-197 {
  width: 197px !important;
}

.file-template .w-198 {
  width: 198px !important;
}

.k-pdf-export .w-198 {
  width: 198px !important;
}

.file-template .w-199 {
  width: 199px !important;
}

.k-pdf-export .w-199 {
  width: 199px !important;
}

.file-template .w-200 {
  width: 200px !important;
}

.k-pdf-export .w-200 {
  width: 200px !important;
}

.file-template .w-201 {
  width: 201px !important;
}

.k-pdf-export .w-201 {
  width: 201px !important;
}

.file-template .w-202 {
  width: 202px !important;
}

.k-pdf-export .w-202 {
  width: 202px !important;
}

.file-template .w-203 {
  width: 203px !important;
}

.k-pdf-export .w-203 {
  width: 203px !important;
}

.file-template .w-204 {
  width: 204px !important;
}

.k-pdf-export .w-204 {
  width: 204px !important;
}

.file-template .w-205 {
  width: 205px !important;
}

.k-pdf-export .w-205 {
  width: 205px !important;
}

.file-template .w-206 {
  width: 206px !important;
}

.k-pdf-export .w-206 {
  width: 206px !important;
}

.file-template .w-207 {
  width: 207px !important;
}

.k-pdf-export .w-207 {
  width: 207px !important;
}

.file-template .w-208 {
  width: 208px !important;
}

.k-pdf-export .w-208 {
  width: 208px !important;
}

.file-template .w-209 {
  width: 209px !important;
}

.k-pdf-export .w-209 {
  width: 209px !important;
}

.file-template .w-210 {
  width: 210px !important;
}

.k-pdf-export .w-210 {
  width: 210px !important;
}

.file-template .w-211 {
  width: 211px !important;
}

.k-pdf-export .w-211 {
  width: 211px !important;
}

.file-template .w-212 {
  width: 212px !important;
}

.k-pdf-export .w-212 {
  width: 212px !important;
}

.file-template .w-213 {
  width: 213px !important;
}

.k-pdf-export .w-213 {
  width: 213px !important;
}

.file-template .w-214 {
  width: 214px !important;
}

.k-pdf-export .w-214 {
  width: 214px !important;
}

.file-template .w-215 {
  width: 215px !important;
}

.k-pdf-export .w-215 {
  width: 215px !important;
}

.file-template .w-216 {
  width: 216px !important;
}

.k-pdf-export .w-216 {
  width: 216px !important;
}

.file-template .w-217 {
  width: 217px !important;
}

.k-pdf-export .w-217 {
  width: 217px !important;
}

.file-template .w-218 {
  width: 218px !important;
}

.k-pdf-export .w-218 {
  width: 218px !important;
}

.file-template .w-219 {
  width: 219px !important;
}

.k-pdf-export .w-219 {
  width: 219px !important;
}

.file-template .w-220 {
  width: 220px !important;
}

.k-pdf-export .w-220 {
  width: 220px !important;
}

.file-template .w-221 {
  width: 221px !important;
}

.k-pdf-export .w-221 {
  width: 221px !important;
}

.file-template .w-222 {
  width: 222px !important;
}

.k-pdf-export .w-222 {
  width: 222px !important;
}

.file-template .w-223 {
  width: 223px !important;
}

.k-pdf-export .w-223 {
  width: 223px !important;
}

.file-template .w-224 {
  width: 224px !important;
}

.k-pdf-export .w-224 {
  width: 224px !important;
}

.file-template .w-225 {
  width: 225px !important;
}

.k-pdf-export .w-225 {
  width: 225px !important;
}

.file-template .w-226 {
  width: 226px !important;
}

.k-pdf-export .w-226 {
  width: 226px !important;
}

.file-template .w-227 {
  width: 227px !important;
}

.k-pdf-export .w-227 {
  width: 227px !important;
}

.file-template .w-228 {
  width: 228px !important;
}

.k-pdf-export .w-228 {
  width: 228px !important;
}

.file-template .w-229 {
  width: 229px !important;
}

.k-pdf-export .w-229 {
  width: 229px !important;
}

.file-template .w-230 {
  width: 230px !important;
}

.k-pdf-export .w-230 {
  width: 230px !important;
}

.file-template .w-231 {
  width: 231px !important;
}

.k-pdf-export .w-231 {
  width: 231px !important;
}

.file-template .w-232 {
  width: 232px !important;
}

.k-pdf-export .w-232 {
  width: 232px !important;
}

.file-template .w-233 {
  width: 233px !important;
}

.k-pdf-export .w-233 {
  width: 233px !important;
}

.file-template .w-234 {
  width: 234px !important;
}

.k-pdf-export .w-234 {
  width: 234px !important;
}

.file-template .w-235 {
  width: 235px !important;
}

.k-pdf-export .w-235 {
  width: 235px !important;
}

.file-template .w-236 {
  width: 236px !important;
}

.k-pdf-export .w-236 {
  width: 236px !important;
}

.file-template .w-237 {
  width: 237px !important;
}

.k-pdf-export .w-237 {
  width: 237px !important;
}

.file-template .w-238 {
  width: 238px !important;
}

.k-pdf-export .w-238 {
  width: 238px !important;
}

.file-template .w-239 {
  width: 239px !important;
}

.k-pdf-export .w-239 {
  width: 239px !important;
}

.file-template .w-240 {
  width: 240px !important;
}

.k-pdf-export .w-240 {
  width: 240px !important;
}

.file-template .w-241 {
  width: 241px !important;
}

.k-pdf-export .w-241 {
  width: 241px !important;
}

.file-template .w-242 {
  width: 242px !important;
}

.k-pdf-export .w-242 {
  width: 242px !important;
}

.file-template .w-243 {
  width: 243px !important;
}

.k-pdf-export .w-243 {
  width: 243px !important;
}

.file-template .w-244 {
  width: 244px !important;
}

.k-pdf-export .w-244 {
  width: 244px !important;
}

.file-template .w-245 {
  width: 245px !important;
}

.file-template .w-246 {
  width: 246px !important;
}

.k-pdf-export .w-246 {
  width: 246px !important;
}

.file-template .w-247 {
  width: 247px !important;
}

.k-pdf-export .w-247 {
  width: 247px !important;
}

.file-template .w-248 {
  width: 248px !important;
}

.k-pdf-export .w-248 {
  width: 248px !important;
}

.file-template .w-249 {
  width: 249px !important;
}

.k-pdf-export .w-249 {
  width: 249px !important;
}

.file-template .w-250 {
  width: 250px !important;
}

.k-pdf-export .w-250 {
  width: 250px !important;
}

.file-template .w-251 {
  width: 251px !important;
}

.k-pdf-export .w-251 {
  width: 251px !important;
}

.file-template .w-252 {
  width: 252px !important;
}

.k-pdf-export .w-252 {
  width: 252px !important;
}

.file-template .w-253 {
  width: 253px !important;
}

.k-pdf-export .w-253 {
  width: 253px !important;
}

.file-template .w-254 {
  width: 254px !important;
}

.k-pdf-export .w-254 {
  width: 254px !important;
}

.file-template .w-255 {
  width: 255px !important;
}

.k-pdf-export .w-255 {
  width: 255px !important;
}

.file-template .w-256 {
  width: 256px !important;
}

.k-pdf-export .w-256 {
  width: 256px !important;
}

.file-template .w-257 {
  width: 257px !important;
}

.k-pdf-export .w-257 {
  width: 257px !important;
}

.file-template .w-258 {
  width: 258px !important;
}

.k-pdf-export .w-258 {
  width: 258px !important;
}

.file-template .w-259 {
  width: 259px !important;
}

.k-pdf-export .w-259 {
  width: 259px !important;
}

.file-template .w-260 {
  width: 260px !important;
}

.k-pdf-export .w-260 {
  width: 260px !important;
}

.file-template .w-261 {
  width: 261px !important;
}

.k-pdf-export .w-261 {
  width: 261px !important;
}

.file-template .w-262 {
  width: 262px !important;
}

.k-pdf-export .w-262 {
  width: 262px !important;
}

.file-template .w-263 {
  width: 263px !important;
}

.k-pdf-export .w-263 {
  width: 263px !important;
}

.file-template .w-264 {
  width: 264px !important;
}

.k-pdf-export .w-264 {
  width: 264px !important;
}

.file-template .w-265 {
  width: 265px !important;
}

.k-pdf-export .w-265 {
  width: 265px !important;
}

.file-template .w-266 {
  width: 266px !important;
}

.k-pdf-export .w-266 {
  width: 266px !important;
}

.file-template .w-267 {
  width: 267px !important;
}

.k-pdf-export .w-267 {
  width: 267px !important;
}

.file-template .w-268 {
  width: 268px !important;
}

.k-pdf-export .w-268 {
  width: 268px !important;
}

.file-template .w-269 {
  width: 269px !important;
}

.k-pdf-export .w-269 {
  width: 269px !important;
}

.file-template .w-270 {
  width: 270px !important;
}

.k-pdf-export .w-270 {
  width: 270px !important;
}

.file-template .w-271 {
  width: 271px !important;
}

.k-pdf-export .w-271 {
  width: 271px !important;
}

.file-template .w-272 {
  width: 272px !important;
}

.k-pdf-export .w-272 {
  width: 272px !important;
}

.file-template .w-273 {
  width: 273px !important;
}

.k-pdf-export .w-273 {
  width: 273px !important;
}

.file-template .w-274 {
  width: 274px !important;
}

.k-pdf-export .w-274 {
  width: 274px !important;
}

.file-template .w-275 {
  width: 275px !important;
}

.k-pdf-export .w-275 {
  width: 275px !important;
}

.file-template .w-276 {
  width: 276px !important;
}

.k-pdf-export .w-276 {
  width: 276px !important;
}

.file-template .w-277 {
  width: 277px !important;
}

.k-pdf-export .w-277 {
  width: 277px !important;
}

.file-template .w-278 {
  width: 278px !important;
}

.k-pdf-export .w-278 {
  width: 278px !important;
}

.file-template .w-279 {
  width: 279px !important;
}

.k-pdf-export .w-279 {
  width: 279px !important;
}

.file-template .w-280 {
  width: 280px !important;
}

.k-pdf-export .w-280 {
  width: 280px !important;
}

.file-template .w-281 {
  width: 281px !important;
}

.k-pdf-export .w-281 {
  width: 281px !important;
}

.file-template .w-282 {
  width: 282px !important;
}

.k-pdf-export .w-282 {
  width: 282px !important;
}

.file-template .w-283 {
  width: 283px !important;
}

.k-pdf-export .w-283 {
  width: 283px !important;
}

.file-template .w-284 {
  width: 284px !important;
}

.k-pdf-export .w-284 {
  width: 284px !important;
}

.file-template .w-285 {
  width: 285px !important;
}

.k-pdf-export .w-285 {
  width: 285px !important;
}

.file-template .w-286 {
  width: 286px !important;
}

.k-pdf-export .w-286 {
  width: 286px !important;
}

.file-template .w-287 {
  width: 287px !important;
}

.k-pdf-export .w-287 {
  width: 287px !important;
}

.file-template .w-288 {
  width: 288px !important;
}

.k-pdf-export .w-288 {
  width: 288px !important;
}

.file-template .w-289 {
  width: 289px !important;
}

.k-pdf-export .w-289 {
  width: 289px !important;
}

.file-template .w-290 {
  width: 290px !important;
}

.k-pdf-export .w-290 {
  width: 290px !important;
}

.file-template .w-291 {
  width: 291px !important;
}

.k-pdf-export .w-291 {
  width: 291px !important;
}

.file-template .w-292 {
  width: 292px !important;
}

.k-pdf-export .w-292 {
  width: 292px !important;
}

.file-template .w-293 {
  width: 293px !important;
}

.k-pdf-export .w-293 {
  width: 293px !important;
}

.file-template .w-294 {
  width: 294px !important;
}

.k-pdf-export .w-294 {
  width: 294px !important;
}

.file-template .w-295 {
  width: 295px !important;
}

.k-pdf-export .w-295 {
  width: 295px !important;
}

.file-template .w-296 {
  width: 296px !important;
}

.k-pdf-export .w-296 {
  width: 296px !important;
}

.file-template .w-297 {
  width: 297px !important;
}

.k-pdf-export .w-297 {
  width: 297px !important;
}

.file-template .w-298 {
  width: 298px !important;
}

.k-pdf-export .w-298 {
  width: 298px !important;
}

.file-template .w-299 {
  width: 299px !important;
}

.k-pdf-export .w-299 {
  width: 299px !important;
}

.file-template .w-300 {
  width: 300px !important;
}

.k-pdf-export .w-300 {
  width: 300px !important;
}

.file-template .w-301 {
  width: 301px !important;
}

.k-pdf-export .w-301 {
  width: 301px !important;
}

.file-template .w-302 {
  width: 302px !important;
}

.k-pdf-export .w-302 {
  width: 302px !important;
}

.file-template .w-303 {
  width: 303px !important;
}

.k-pdf-export .w-303 {
  width: 303px !important;
}

.file-template .w-304 {
  width: 304px !important;
}

.k-pdf-export .w-304 {
  width: 304px !important;
}

.file-template .w-305 {
  width: 305px !important;
}

.k-pdf-export .w-305 {
  width: 305px !important;
}

.file-template .w-306 {
  width: 306px !important;
}

.k-pdf-export .w-306 {
  width: 306px !important;
}

.file-template .w-307 {
  width: 307px !important;
}

.k-pdf-export .w-307 {
  width: 307px !important;
}

.file-template .w-308 {
  width: 308px !important;
}

.k-pdf-export .w-308 {
  width: 308px !important;
}

.file-template .w-309 {
  width: 309px !important;
}

.k-pdf-export .w-309 {
  width: 309px !important;
}

.file-template .w-310 {
  width: 310px !important;
}

.k-pdf-export .w-310 {
  width: 310px !important;
}

.file-template .w-311 {
  width: 311px !important;
}

.k-pdf-export .w-311 {
  width: 311px !important;
}

.file-template .w-312 {
  width: 312px !important;
}

.k-pdf-export .w-312 {
  width: 312px !important;
}

.file-template .w-313 {
  width: 313px !important;
}

.k-pdf-export .w-313 {
  width: 313px !important;
}

.file-template .w-314 {
  width: 314px !important;
}

.k-pdf-export .w-314 {
  width: 314px !important;
}

.file-template .w-315 {
  width: 315px !important;
}

.k-pdf-export .w-315 {
  width: 315px !important;
}

.file-template .w-316 {
  width: 316px !important;
}

.k-pdf-export .w-316 {
  width: 316px !important;
}

.file-template .w-317 {
  width: 317px !important;
}

.k-pdf-export .w-317 {
  width: 317px !important;
}

.file-template .w-318 {
  width: 318px !important;
}

.k-pdf-export .w-318 {
  width: 318px !important;
}

.file-template .w-319 {
  width: 319px !important;
}

.k-pdf-export .w-319 {
  width: 319px !important;
}

.file-template .w-320 {
  width: 320px !important;
}

.k-pdf-export .w-320 {
  width: 320px !important;
}

.file-template .w-321 {
  width: 321px !important;
}

.k-pdf-export .w-321 {
  width: 321px !important;
}

.file-template .w-322 {
  width: 322px !important;
}

.k-pdf-export .w-322 {
  width: 322px !important;
}

.file-template .w-323 {
  width: 323px !important;
}

.k-pdf-export .w-323 {
  width: 323px !important;
}

.file-template .w-324 {
  width: 324px !important;
}

.k-pdf-export .w-324 {
  width: 324px !important;
}

.file-template .w-325 {
  width: 325px !important;
}

.k-pdf-export .w-325 {
  width: 325px !important;
}

.file-template .w-326 {
  width: 326px !important;
}

.k-pdf-export .w-326 {
  width: 326px !important;
}

.file-template .w-327 {
  width: 327px !important;
}

.k-pdf-export .w-327 {
  width: 327px !important;
}

.file-template .w-328 {
  width: 328px !important;
}

.k-pdf-export .w-328 {
  width: 328px !important;
}

.file-template .w-329 {
  width: 329px !important;
}

.k-pdf-export .w-329 {
  width: 329px !important;
}

.file-template .w-330 {
  width: 330px !important;
}

.k-pdf-export .w-330 {
  width: 330px !important;
}

.file-template .w-331 {
  width: 331px !important;
}

.k-pdf-export .w-331 {
  width: 331px !important;
}

.file-template .w-332 {
  width: 332px !important;
}

.k-pdf-export .w-332 {
  width: 332px !important;
}

.file-template .w-333 {
  width: 333px !important;
}

.k-pdf-export .w-333 {
  width: 333px !important;
}

.file-template .w-334 {
  width: 334px !important;
}

.k-pdf-export .w-334 {
  width: 334px !important;
}

.file-template .w-335 {
  width: 335px !important;
}

.k-pdf-export .w-335 {
  width: 335px !important;
}

.file-template .w-336 {
  width: 336px !important;
}

.k-pdf-export .w-336 {
  width: 336px !important;
}

.file-template .w-337 {
  width: 337px !important;
}

.k-pdf-export .w-337 {
  width: 337px !important;
}

.file-template .w-338 {
  width: 338px !important;
}

.k-pdf-export .w-338 {
  width: 338px !important;
}

.file-template .w-339 {
  width: 339px !important;
}

.k-pdf-export .w-339 {
  width: 339px !important;
}

.file-template .w-340 {
  width: 340px !important;
}

.k-pdf-export .w-340 {
  width: 340px !important;
}

.file-template .w-341 {
  width: 341px !important;
}

.k-pdf-export .w-341 {
  width: 341px !important;
}

.file-template .w-342 {
  width: 342px !important;
}

.k-pdf-export .w-342 {
  width: 342px !important;
}

.file-template .w-343 {
  width: 343px !important;
}

.k-pdf-export .w-343 {
  width: 343px !important;
}

.file-template .w-344 {
  width: 344px !important;
}

.k-pdf-export .w-344 {
  width: 344px !important;
}

.file-template .w-345 {
  width: 345px !important;
}

.k-pdf-export .w-345 {
  width: 345px !important;
}

.file-template .w-346 {
  width: 346px !important;
}

.k-pdf-export .w-346 {
  width: 346px !important;
}

.file-template .w-347 {
  width: 347px !important;
}

.k-pdf-export .w-347 {
  width: 347px !important;
}

.file-template .w-348 {
  width: 348px !important;
}

.k-pdf-export .w-348 {
  width: 348px !important;
}

.file-template .w-349 {
  width: 349px !important;
}

.k-pdf-export .w-349 {
  width: 349px !important;
}

.file-template .w-350 {
  width: 350px !important;
}

.k-pdf-export .w-350 {
  width: 350px !important;
}

.file-template .w-351 {
  width: 351px !important;
}

.k-pdf-export .w-351 {
  width: 351px !important;
}

.file-template .w-352 {
  width: 352px !important;
}

.k-pdf-export .w-352 {
  width: 352px !important;
}

.file-template .w-353 {
  width: 353px !important;
}

.k-pdf-export .w-353 {
  width: 353px !important;
}

.file-template .w-354 {
  width: 354px !important;
}

.k-pdf-export .w-354 {
  width: 354px !important;
}

.file-template .w-355 {
  width: 355px !important;
}

.k-pdf-export .w-355 {
  width: 355px !important;
}

.file-template .w-356 {
  width: 356px !important;
}

.k-pdf-export .w-356 {
  width: 356px !important;
}

.file-template .w-357 {
  width: 357px !important;
}

.k-pdf-export .w-357 {
  width: 357px !important;
}

.file-template .w-358 {
  width: 358px !important;
}

.k-pdf-export .w-358 {
  width: 358px !important;
}

.file-template .w-359 {
  width: 359px !important;
}

.k-pdf-export .w-359 {
  width: 359px !important;
}

.file-template .w-360 {
  width: 360px !important;
}

.k-pdf-export .w-360 {
  width: 360px !important;
}

.file-template .w-361 {
  width: 361px !important;
}

.k-pdf-export .w-361 {
  width: 361px !important;
}

.file-template .w-362 {
  width: 362px !important;
}

.k-pdf-export .w-362 {
  width: 362px !important;
}

.file-template .w-363 {
  width: 363px !important;
}

.k-pdf-export .w-363 {
  width: 363px !important;
}

.file-template .w-364 {
  width: 364px !important;
}

.k-pdf-export .w-364 {
  width: 364px !important;
}

.file-template .w-365 {
  width: 365px !important;
}

.k-pdf-export .w-365 {
  width: 365px !important;
}

.file-template .w-366 {
  width: 366px !important;
}

.k-pdf-export .w-366 {
  width: 366px !important;
}

.file-template .w-367 {
  width: 367px !important;
}

.k-pdf-export .w-367 {
  width: 367px !important;
}

.file-template .w-368 {
  width: 368px !important;
}

.k-pdf-export .w-368 {
  width: 368px !important;
}

.file-template .w-369 {
  width: 369px !important;
}

.k-pdf-export .w-369 {
  width: 369px !important;
}

.file-template .w-370 {
  width: 370px !important;
}

.k-pdf-export .w-370 {
  width: 370px !important;
}

.file-template .w-371 {
  width: 371px !important;
}

.k-pdf-export .w-371 {
  width: 371px !important;
}

.file-template .w-372 {
  width: 372px !important;
}

.k-pdf-export .w-372 {
  width: 372px !important;
}

.file-template .w-373 {
  width: 373px !important;
}

.k-pdf-export .w-373 {
  width: 373px !important;
}

.file-template .w-374 {
  width: 374px !important;
}

.k-pdf-export .w-374 {
  width: 374px !important;
}

.file-template .w-375 {
  width: 375px !important;
}

.k-pdf-export .w-375 {
  width: 375px !important;
}

.file-template .w-376 {
  width: 376px !important;
}

.k-pdf-export .w-376 {
  width: 376px !important;
}

.file-template .w-377 {
  width: 377px !important;
}

.k-pdf-export .w-377 {
  width: 377px !important;
}

.file-template .w-378 {
  width: 378px !important;
}

.k-pdf-export .w-378 {
  width: 378px !important;
}

.file-template .w-379 {
  width: 379px !important;
}

.k-pdf-export .w-379 {
  width: 379px !important;
}

.file-template .w-380 {
  width: 380px !important;
}

.k-pdf-export .w-380 {
  width: 380px !important;
}

.file-template .w-381 {
  width: 381px !important;
}

.k-pdf-export .w-381 {
  width: 381px !important;
}

.file-template .w-382 {
  width: 382px !important;
}

.k-pdf-export .w-382 {
  width: 382px !important;
}

.file-template .w-383 {
  width: 383px !important;
}

.k-pdf-export .w-383 {
  width: 383px !important;
}

.file-template .w-384 {
  width: 384px !important;
}

.k-pdf-export .w-384 {
  width: 384px !important;
}

.file-template .w-385 {
  width: 385px !important;
}

.k-pdf-export .w-385 {
  width: 385px !important;
}

.file-template .w-386 {
  width: 386px !important;
}

.k-pdf-export .w-386 {
  width: 386px !important;
}

.file-template .w-387 {
  width: 387px !important;
}

.k-pdf-export .w-387 {
  width: 387px !important;
}

.file-template .w-388 {
  width: 388px !important;
}

.k-pdf-export .w-388 {
  width: 388px !important;
}

.file-template .w-389 {
  width: 389px !important;
}

.k-pdf-export .w-389 {
  width: 389px !important;
}

.file-template .w-390 {
  width: 390px !important;
}

.k-pdf-export .w-390 {
  width: 390px !important;
}

.file-template .w-391 {
  width: 391px !important;
}

.k-pdf-export .w-391 {
  width: 391px !important;
}

.file-template .w-392 {
  width: 392px !important;
}

.k-pdf-export .w-392 {
  width: 392px !important;
}

.file-template .w-393 {
  width: 393px !important;
}

.k-pdf-export .w-393 {
  width: 393px !important;
}

.file-template .w-394 {
  width: 394px !important;
}

.k-pdf-export .w-394 {
  width: 394px !important;
}

.file-template .w-395 {
  width: 395px !important;
}

.k-pdf-export .w-395 {
  width: 395px !important;
}

.file-template .w-396 {
  width: 396px !important;
}

.k-pdf-export .w-396 {
  width: 396px !important;
}

.file-template .w-397 {
  width: 397px !important;
}

.k-pdf-export .w-397 {
  width: 397px !important;
}

.file-template .w-398 {
  width: 398px !important;
}

.k-pdf-export .w-398 {
  width: 398px !important;
}

.file-template .w-399 {
  width: 399px !important;
}

.k-pdf-export .w-399 {
  width: 399px !important;
}

.file-template .w-400 {
  width: 400px !important;
}

.k-pdf-export .w-400 {
  width: 400px !important;
}

.file-template .w-401 {
  width: 401px !important;
}

.k-pdf-export .w-401 {
  width: 401px !important;
}

.file-template .w-402 {
  width: 402px !important;
}

.k-pdf-export .w-402 {
  width: 402px !important;
}

.file-template .w-403 {
  width: 403px !important;
}

.k-pdf-export .w-403 {
  width: 403px !important;
}

.file-template .w-404 {
  width: 404px !important;
}

.k-pdf-export .w-404 {
  width: 404px !important;
}

.file-template .w-405 {
  width: 405px !important;
}

.k-pdf-export .w-405 {
  width: 405px !important;
}

.file-template .w-406 {
  width: 406px !important;
}

.k-pdf-export .w-406 {
  width: 406px !important;
}

.file-template .w-407 {
  width: 407px !important;
}

.k-pdf-export .w-407 {
  width: 407px !important;
}

.file-template .w-408 {
  width: 408px !important;
}

.k-pdf-export .w-408 {
  width: 408px !important;
}

.file-template .w-409 {
  width: 409px !important;
}

.k-pdf-export .w-409 {
  width: 409px !important;
}

.file-template .w-410 {
  width: 410px !important;
}

.k-pdf-export .w-410 {
  width: 410px !important;
}

.file-template .w-411 {
  width: 411px !important;
}

.k-pdf-export .w-411 {
  width: 411px !important;
}

.file-template .w-412 {
  width: 412px !important;
}

.k-pdf-export .w-412 {
  width: 412px !important;
}

.file-template .w-413 {
  width: 413px !important;
}

.k-pdf-export .w-413 {
  width: 413px !important;
}

.file-template .w-414 {
  width: 414px !important;
}

.k-pdf-export .w-414 {
  width: 414px !important;
}

.file-template .w-415 {
  width: 415px !important;
}

.k-pdf-export .w-415 {
  width: 415px !important;
}

.file-template .w-416 {
  width: 416px !important;
}

.k-pdf-export .w-416 {
  width: 416px !important;
}

.file-template .w-417 {
  width: 417px !important;
}

.k-pdf-export .w-417 {
  width: 417px !important;
}

.file-template .w-418 {
  width: 418px !important;
}

.k-pdf-export .w-418 {
  width: 418px !important;
}

.file-template .w-419 {
  width: 419px !important;
}

.k-pdf-export .w-419 {
  width: 419px !important;
}

.file-template .w-420 {
  width: 420px !important;
}

.k-pdf-export .w-420 {
  width: 420px !important;
}

.file-template .w-421 {
  width: 421px !important;
}

.k-pdf-export .w-421 {
  width: 421px !important;
}

.file-template .w-422 {
  width: 422px !important;
}

.k-pdf-export .w-422 {
  width: 422px !important;
}

.file-template .w-423 {
  width: 423px !important;
}

.k-pdf-export .w-423 {
  width: 423px !important;
}

.file-template .w-424 {
  width: 424px !important;
}

.k-pdf-export .w-424 {
  width: 424px !important;
}

.file-template .w-425 {
  width: 425px !important;
}

.k-pdf-export .w-425 {
  width: 425px !important;
}

.file-template .w-426 {
  width: 426px !important;
}

.k-pdf-export .w-426 {
  width: 426px !important;
}

.file-template .w-427 {
  width: 427px !important;
}

.k-pdf-export .w-427 {
  width: 427px !important;
}

.file-template .w-428 {
  width: 428px !important;
}

.k-pdf-export .w-428 {
  width: 428px !important;
}

.file-template .w-429 {
  width: 429px !important;
}

.k-pdf-export .w-429 {
  width: 429px !important;
}

.file-template .w-430 {
  width: 430px !important;
}

.k-pdf-export .w-430 {
  width: 430px !important;
}

.file-template .w-431 {
  width: 431px !important;
}

.k-pdf-export .w-431 {
  width: 431px !important;
}

.file-template .w-432 {
  width: 432px !important;
}

.k-pdf-export .w-432 {
  width: 432px !important;
}

.file-template .w-433 {
  width: 433px !important;
}

.k-pdf-export .w-433 {
  width: 433px !important;
}

.file-template .w-434 {
  width: 434px !important;
}

.k-pdf-export .w-434 {
  width: 434px !important;
}

.file-template .w-435 {
  width: 435px !important;
}

.k-pdf-export .w-435 {
  width: 435px !important;
}

.file-template .w-436 {
  width: 436px !important;
}

.k-pdf-export .w-436 {
  width: 436px !important;
}

.file-template .w-437 {
  width: 437px !important;
}

.k-pdf-export .w-437 {
  width: 437px !important;
}

.file-template .w-438 {
  width: 438px !important;
}

.k-pdf-export .w-438 {
  width: 438px !important;
}

.file-template .w-439 {
  width: 439px !important;
}

.k-pdf-export .w-439 {
  width: 439px !important;
}

.file-template .w-440 {
  width: 440px !important;
}

.k-pdf-export .w-440 {
  width: 440px !important;
}

.file-template .w-441 {
  width: 441px !important;
}

.k-pdf-export .w-441 {
  width: 441px !important;
}

.file-template .w-442 {
  width: 442px !important;
}

.k-pdf-export .w-442 {
  width: 442px !important;
}

.file-template .w-443 {
  width: 443px !important;
}

.k-pdf-export .w-443 {
  width: 443px !important;
}

.file-template .w-444 {
  width: 444px !important;
}

.k-pdf-export .w-444 {
  width: 444px !important;
}

.file-template .w-445 {
  width: 445px !important;
}

.k-pdf-export .w-445 {
  width: 445px !important;
}

.file-template .w-446 {
  width: 446px !important;
}

.k-pdf-export .w-446 {
  width: 446px !important;
}

.file-template .w-447 {
  width: 447px !important;
}

.k-pdf-export .w-447 {
  width: 447px !important;
}

.file-template .w-448 {
  width: 448px !important;
}

.k-pdf-export .w-448 {
  width: 448px !important;
}

.file-template .w-449 {
  width: 449px !important;
}

.k-pdf-export .w-449 {
  width: 449px !important;
}

.file-template .w-450 {
  width: 450px !important;
}

.k-pdf-export .w-450 {
  width: 450px !important;
}

.file-template .w-451 {
  width: 451px !important;
}

.k-pdf-export .w-451 {
  width: 451px !important;
}

.file-template .w-452 {
  width: 452px !important;
}

.k-pdf-export .w-452 {
  width: 452px !important;
}

.file-template .w-453 {
  width: 453px !important;
}

.k-pdf-export .w-453 {
  width: 453px !important;
}

.file-template .w-454 {
  width: 454px !important;
}

.k-pdf-export .w-454 {
  width: 454px !important;
}

.file-template .w-455 {
  width: 455px !important;
}

.k-pdf-export .w-455 {
  width: 455px !important;
}

.file-template .w-456 {
  width: 456px !important;
}

.k-pdf-export .w-456 {
  width: 456px !important;
}

.file-template .w-457 {
  width: 457px !important;
}

.k-pdf-export .w-457 {
  width: 457px !important;
}

.file-template .w-458 {
  width: 458px !important;
}

.k-pdf-export .w-458 {
  width: 458px !important;
}

.file-template .w-459 {
  width: 459px !important;
}

.k-pdf-export .w-459 {
  width: 459px !important;
}

.file-template .w-460 {
  width: 460px !important;
}

.k-pdf-export .w-460 {
  width: 460px !important;
}

.file-template .w-461 {
  width: 461px !important;
}

.k-pdf-export .w-461 {
  width: 461px !important;
}

.file-template .w-462 {
  width: 462px !important;
}

.k-pdf-export .w-462 {
  width: 462px !important;
}

.file-template .w-463 {
  width: 463px !important;
}

.k-pdf-export .w-463 {
  width: 463px !important;
}

.file-template .w-464 {
  width: 464px !important;
}

.k-pdf-export .w-464 {
  width: 464px !important;
}

.file-template .w-465 {
  width: 465px !important;
}

.k-pdf-export .w-465 {
  width: 465px !important;
}

.file-template .w-466 {
  width: 466px !important;
}

.k-pdf-export .w-466 {
  width: 466px !important;
}

.file-template .w-467 {
  width: 467px !important;
}

.k-pdf-export .w-467 {
  width: 467px !important;
}

.file-template .w-468 {
  width: 468px !important;
}

.k-pdf-export .w-468 {
  width: 468px !important;
}

.file-template .w-469 {
  width: 469px !important;
}

.k-pdf-export .w-469 {
  width: 469px !important;
}

.file-template .w-470 {
  width: 470px !important;
}

.k-pdf-export .w-470 {
  width: 470px !important;
}

.file-template .w-471 {
  width: 471px !important;
}

.k-pdf-export .w-471 {
  width: 471px !important;
}

.file-template .w-472 {
  width: 472px !important;
}

.k-pdf-export .w-472 {
  width: 472px !important;
}

.file-template .w-473 {
  width: 473px !important;
}

.k-pdf-export .w-473 {
  width: 473px !important;
}

.file-template .w-474 {
  width: 474px !important;
}

.k-pdf-export .w-474 {
  width: 474px !important;
}

.file-template .w-475 {
  width: 475px !important;
}

.k-pdf-export .w-475 {
  width: 475px !important;
}

.file-template .w-476 {
  width: 476px !important;
}

.k-pdf-export .w-476 {
  width: 476px !important;
}

.file-template .w-477 {
  width: 477px !important;
}

.k-pdf-export .w-477 {
  width: 477px !important;
}

.file-template .w-478 {
  width: 478px !important;
}

.k-pdf-export .w-478 {
  width: 478px !important;
}

.file-template .w-479 {
  width: 479px !important;
}

.k-pdf-export .w-479 {
  width: 479px !important;
}

.file-template .w-480 {
  width: 480px !important;
}

.k-pdf-export .w-480 {
  width: 480px !important;
}

.file-template .w-481 {
  width: 481px !important;
}

.k-pdf-export .w-481 {
  width: 481px !important;
}

.file-template .w-482 {
  width: 482px !important;
}

.k-pdf-export .w-482 {
  width: 482px !important;
}

.file-template .w-483 {
  width: 483px !important;
}

.k-pdf-export .w-483 {
  width: 483px !important;
}

.file-template .w-484 {
  width: 484px !important;
}

.k-pdf-export .w-484 {
  width: 484px !important;
}

.file-template .w-485 {
  width: 485px !important;
}

.k-pdf-export .w-485 {
  width: 485px !important;
}

.file-template .w-486 {
  width: 486px !important;
}

.k-pdf-export .w-486 {
  width: 486px !important;
}

.file-template .w-487 {
  width: 487px !important;
}

.k-pdf-export .w-487 {
  width: 487px !important;
}

.file-template .w-488 {
  width: 488px !important;
}

.k-pdf-export .w-488 {
  width: 488px !important;
}

.file-template .w-489 {
  width: 489px !important;
}

.k-pdf-export .w-489 {
  width: 489px !important;
}

.file-template .w-490 {
  width: 490px !important;
}

.k-pdf-export .w-490 {
  width: 490px !important;
}

.file-template .w-491 {
  width: 491px !important;
}

.k-pdf-export .w-491 {
  width: 491px !important;
}

.file-template .w-492 {
  width: 492px !important;
}

.k-pdf-export .w-492 {
  width: 492px !important;
}

.file-template .w-493 {
  width: 493px !important;
}

.k-pdf-export .w-493 {
  width: 493px !important;
}

.file-template .w-494 {
  width: 494px !important;
}

.k-pdf-export .w-494 {
  width: 494px !important;
}

.file-template .w-495 {
  width: 495px !important;
}

.k-pdf-export .w-495 {
  width: 495px !important;
}

.file-template .w-496 {
  width: 496px !important;
}

.k-pdf-export .w-496 {
  width: 496px !important;
}

.file-template .w-497 {
  width: 497px !important;
}

.k-pdf-export .w-497 {
  width: 497px !important;
}

.file-template .w-498 {
  width: 498px !important;
}

.k-pdf-export .w-498 {
  width: 498px !important;
}

.file-template .w-499 {
  width: 499px !important;
}

.k-pdf-export .w-499 {
  width: 499px !important;
}

.file-template .w-500 {
  width: 500px !important;
}

.k-pdf-export .w-500 {
  width: 500px !important;
}

.file-template .w-501 {
  width: 501px !important;
}

.k-pdf-export .w-501 {
  width: 501px !important;
}

.file-template .w-502 {
  width: 502px !important;
}

.k-pdf-export .w-502 {
  width: 502px !important;
}

.file-template .w-503 {
  width: 503px !important;
}

.k-pdf-export .w-503 {
  width: 503px !important;
}

.file-template .w-504 {
  width: 504px !important;
}

.k-pdf-export .w-504 {
  width: 504px !important;
}

.file-template .w-505 {
  width: 505px !important;
}

.k-pdf-export .w-505 {
  width: 505px !important;
}

.file-template .w-506 {
  width: 506px !important;
}

.k-pdf-export .w-506 {
  width: 506px !important;
}

.file-template .w-507 {
  width: 507px !important;
}

.k-pdf-export .w-507 {
  width: 507px !important;
}

.file-template .w-508 {
  width: 508px !important;
}

.k-pdf-export .w-508 {
  width: 508px !important;
}

.file-template .w-509 {
  width: 509px !important;
}

.k-pdf-export .w-509 {
  width: 509px !important;
}

.file-template .w-510 {
  width: 510px !important;
}

.k-pdf-export .w-510 {
  width: 510px !important;
}

.file-template .w-511 {
  width: 511px !important;
}

.k-pdf-export .w-511 {
  width: 511px !important;
}

.file-template .w-512 {
  width: 512px !important;
}

.k-pdf-export .w-512 {
  width: 512px !important;
}

.file-template .w-513 {
  width: 513px !important;
}

.k-pdf-export .w-513 {
  width: 513px !important;
}

.file-template .w-514 {
  width: 514px !important;
}

.k-pdf-export .w-514 {
  width: 514px !important;
}

.file-template .w-515 {
  width: 515px !important;
}

.k-pdf-export .w-515 {
  width: 515px !important;
}

.file-template .w-516 {
  width: 516px !important;
}

.k-pdf-export .w-516 {
  width: 516px !important;
}

.file-template .w-517 {
  width: 517px !important;
}

.k-pdf-export .w-517 {
  width: 517px !important;
}

.file-template .w-518 {
  width: 518px !important;
}

.k-pdf-export .w-518 {
  width: 518px !important;
}

.file-template .w-519 {
  width: 519px !important;
}

.k-pdf-export .w-519 {
  width: 519px !important;
}

.file-template .w-520 {
  width: 520px !important;
}

.k-pdf-export .w-520 {
  width: 520px !important;
}

.file-template .w-521 {
  width: 521px !important;
}

.k-pdf-export .w-521 {
  width: 521px !important;
}

.file-template .w-522 {
  width: 522px !important;
}

.k-pdf-export .w-522 {
  width: 522px !important;
}

.file-template .w-523 {
  width: 523px !important;
}

.k-pdf-export .w-523 {
  width: 523px !important;
}

.file-template .w-524 {
  width: 524px !important;
}

.k-pdf-export .w-524 {
  width: 524px !important;
}

.file-template .w-525 {
  width: 525px !important;
}

.k-pdf-export .w-525 {
  width: 525px !important;
}

.file-template .w-526 {
  width: 526px !important;
}

.k-pdf-export .w-526 {
  width: 526px !important;
}

.file-template .w-527 {
  width: 527px !important;
}

.k-pdf-export .w-527 {
  width: 527px !important;
}

.file-template .w-528 {
  width: 528px !important;
}

.k-pdf-export .w-528 {
  width: 528px !important;
}

.file-template .w-529 {
  width: 529px !important;
}

.k-pdf-export .w-529 {
  width: 529px !important;
}

.file-template .w-530 {
  width: 530px !important;
}

.k-pdf-export .w-530 {
  width: 530px !important;
}

.file-template .w-531 {
  width: 531px !important;
}

.k-pdf-export .w-531 {
  width: 531px !important;
}

.file-template .w-532 {
  width: 532px !important;
}

.k-pdf-export .w-532 {
  width: 532px !important;
}

.file-template .w-533 {
  width: 533px !important;
}

.k-pdf-export .w-533 {
  width: 533px !important;
}

.file-template .w-534 {
  width: 534px !important;
}

.k-pdf-export .w-534 {
  width: 534px !important;
}

.file-template .w-535 {
  width: 535px !important;
}

.k-pdf-export .w-535 {
  width: 535px !important;
}

.file-template .w-536 {
  width: 536px !important;
}

.k-pdf-export .w-536 {
  width: 536px !important;
}

.file-template .w-537 {
  width: 537px !important;
}

.k-pdf-export .w-537 {
  width: 537px !important;
}

.file-template .w-538 {
  width: 538px !important;
}

.k-pdf-export .w-538 {
  width: 538px !important;
}

.file-template .w-539 {
  width: 539px !important;
}

.k-pdf-export .w-539 {
  width: 539px !important;
}

.file-template .w-540 {
  width: 540px !important;
}

.k-pdf-export .w-540 {
  width: 540px !important;
}

.file-template .w-541 {
  width: 541px !important;
}

.k-pdf-export .w-541 {
  width: 541px !important;
}

.file-template .w-542 {
  width: 542px !important;
}

.k-pdf-export .w-542 {
  width: 542px !important;
}

.file-template .w-543 {
  width: 543px !important;
}

.k-pdf-export .w-543 {
  width: 543px !important;
}

.file-template .w-544 {
  width: 544px !important;
}

.k-pdf-export .w-544 {
  width: 544px !important;
}

.file-template .w-545 {
  width: 545px !important;
}

.k-pdf-export .w-545 {
  width: 545px !important;
}

.file-template .w-546 {
  width: 546px !important;
}

.k-pdf-export .w-546 {
  width: 546px !important;
}

.file-template .w-547 {
  width: 547px !important;
}

.k-pdf-export .w-547 {
  width: 547px !important;
}

.file-template .w-548 {
  width: 548px !important;
}

.k-pdf-export .w-548 {
  width: 548px !important;
}

.file-template .w-549 {
  width: 549px !important;
}

.k-pdf-export .w-549 {
  width: 549px !important;
}

.file-template .w-550 {
  width: 550px !important;
}

.k-pdf-export .w-550 {
  width: 550px !important;
}

.file-template .w-551 {
  width: 551px !important;
}

.k-pdf-export .w-551 {
  width: 551px !important;
}

.file-template .w-552 {
  width: 552px !important;
}

.k-pdf-export .w-552 {
  width: 552px !important;
}

.file-template .w-553 {
  width: 553px !important;
}

.k-pdf-export .w-553 {
  width: 553px !important;
}

.file-template .w-554 {
  width: 554px !important;
}

.k-pdf-export .w-554 {
  width: 554px !important;
}

.file-template .w-555 {
  width: 555px !important;
}

.k-pdf-export .w-555 {
  width: 555px !important;
}

.file-template .w-556 {
  width: 556px !important;
}

.k-pdf-export .w-556 {
  width: 556px !important;
}

.file-template .w-557 {
  width: 557px !important;
}

.k-pdf-export .w-557 {
  width: 557px !important;
}

.file-template .w-558 {
  width: 558px !important;
}

.k-pdf-export .w-558 {
  width: 558px !important;
}

.file-template .w-559 {
  width: 559px !important;
}

.k-pdf-export .w-559 {
  width: 559px !important;
}

.file-template .w-560 {
  width: 560px !important;
}

.k-pdf-export .w-560 {
  width: 560px !important;
}

.file-template .w-561 {
  width: 561px !important;
}

.k-pdf-export .w-561 {
  width: 561px !important;
}

.file-template .w-562 {
  width: 562px !important;
}

.k-pdf-export .w-562 {
  width: 562px !important;
}

.file-template .w-563 {
  width: 563px !important;
}

.k-pdf-export .w-563 {
  width: 563px !important;
}

.file-template .w-564 {
  width: 564px !important;
}

.k-pdf-export .w-564 {
  width: 564px !important;
}

.file-template .w-565 {
  width: 565px !important;
}

.k-pdf-export .w-565 {
  width: 565px !important;
}

.file-template .w-566 {
  width: 566px !important;
}

.k-pdf-export .w-566 {
  width: 566px !important;
}

.file-template .w-567 {
  width: 567px !important;
}

.k-pdf-export .w-567 {
  width: 567px !important;
}

.file-template .w-568 {
  width: 568px !important;
}

.k-pdf-export .w-568 {
  width: 568px !important;
}

.file-template .w-569 {
  width: 569px !important;
}

.k-pdf-export .w-569 {
  width: 569px !important;
}

.file-template .w-570 {
  width: 570px !important;
}

.k-pdf-export .w-570 {
  width: 570px !important;
}

.file-template .w-571 {
  width: 571px !important;
}

.k-pdf-export .w-571 {
  width: 571px !important;
}

.file-template .w-572 {
  width: 572px !important;
}

.k-pdf-export .w-572 {
  width: 572px !important;
}

.file-template .w-573 {
  width: 573px !important;
}

.k-pdf-export .w-573 {
  width: 573px !important;
}

.file-template .w-574 {
  width: 574px !important;
}

.k-pdf-export .w-574 {
  width: 574px !important;
}

.file-template .w-575 {
  width: 575px !important;
}

.k-pdf-export .w-575 {
  width: 575px !important;
}

.file-template .w-576 {
  width: 576px !important;
}

.k-pdf-export .w-576 {
  width: 576px !important;
}

.file-template .w-577 {
  width: 577px !important;
}

.k-pdf-export .w-577 {
  width: 577px !important;
}

.file-template .w-578 {
  width: 578px !important;
}

.k-pdf-export .w-578 {
  width: 578px !important;
}

.file-template .w-579 {
  width: 579px !important;
}

.k-pdf-export .w-579 {
  width: 579px !important;
}

.file-template .w-580 {
  width: 580px !important;
}

.k-pdf-export .w-580 {
  width: 580px !important;
}

.file-template .w-581 {
  width: 581px !important;
}

.k-pdf-export .w-581 {
  width: 581px !important;
}

.file-template .w-582 {
  width: 582px !important;
}

.k-pdf-export .w-582 {
  width: 582px !important;
}

.file-template .w-583 {
  width: 583px !important;
}

.k-pdf-export .w-583 {
  width: 583px !important;
}

.file-template .w-584 {
  width: 584px !important;
}

.k-pdf-export .w-584 {
  width: 584px !important;
}

.file-template .w-585 {
  width: 585px !important;
}

.k-pdf-export .w-585 {
  width: 585px !important;
}

.file-template .w-586 {
  width: 586px !important;
}

.k-pdf-export .w-586 {
  width: 586px !important;
}

.file-template .w-587 {
  width: 587px !important;
}

.k-pdf-export .w-587 {
  width: 587px !important;
}

.file-template .w-588 {
  width: 588px !important;
}

.k-pdf-export .w-588 {
  width: 588px !important;
}

.file-template .w-589 {
  width: 589px !important;
}

.k-pdf-export .w-589 {
  width: 589px !important;
}

.file-template .w-590 {
  width: 590px !important;
}

.k-pdf-export .w-590 {
  width: 590px !important;
}

.file-template .w-591 {
  width: 591px !important;
}

.k-pdf-export .w-591 {
  width: 591px !important;
}

.file-template .w-592 {
  width: 592px !important;
}

.k-pdf-export .w-592 {
  width: 592px !important;
}

.file-template .w-593 {
  width: 593px !important;
}

.k-pdf-export .w-593 {
  width: 593px !important;
}

.file-template .w-594 {
  width: 594px !important;
}

.k-pdf-export .w-594 {
  width: 594px !important;
}

.file-template .w-595 {
  width: 595px !important;
}

.k-pdf-export .w-595 {
  width: 595px !important;
}

.file-template .w-596 {
  width: 596px !important;
}

.k-pdf-export .w-596 {
  width: 596px !important;
}

.file-template .w-597 {
  width: 597px !important;
}

.k-pdf-export .w-597 {
  width: 597px !important;
}

.file-template .w-598 {
  width: 598px !important;
}

.k-pdf-export .w-598 {
  width: 598px !important;
}

.file-template .w-599 {
  width: 599px !important;
}

.k-pdf-export .w-599 {
  width: 599px !important;
}

.file-template .w-600 {
  width: 600px !important;
}

.k-pdf-export .w-600 {
  width: 600px !important;
}

.file-template .w-601 {
  width: 601px !important;
}

.k-pdf-export .w-601 {
  width: 601px !important;
}

.file-template .w-602 {
  width: 602px !important;
}

.k-pdf-export .w-602 {
  width: 602px !important;
}

.file-template .w-603 {
  width: 603px !important;
}

.k-pdf-export .w-603 {
  width: 603px !important;
}

.file-template .w-604 {
  width: 604px !important;
}

.k-pdf-export .w-604 {
  width: 604px !important;
}

.file-template .w-605 {
  width: 605px !important;
}

.k-pdf-export .w-605 {
  width: 605px !important;
}

.file-template .w-606 {
  width: 606px !important;
}

.k-pdf-export .w-606 {
  width: 606px !important;
}

.file-template .w-607 {
  width: 607px !important;
}

.k-pdf-export .w-607 {
  width: 607px !important;
}

.file-template .w-608 {
  width: 608px !important;
}

.k-pdf-export .w-608 {
  width: 608px !important;
}

.file-template .w-609 {
  width: 609px !important;
}

.k-pdf-export .w-609 {
  width: 609px !important;
}

.file-template .w-610 {
  width: 610px !important;
}

.k-pdf-export .w-610 {
  width: 610px !important;
}

.file-template .w-611 {
  width: 611px !important;
}

.k-pdf-export .w-611 {
  width: 611px !important;
}

.file-template .w-612 {
  width: 612px !important;
}

.k-pdf-export .w-612 {
  width: 612px !important;
}

.file-template .w-613 {
  width: 613px !important;
}

.k-pdf-export .w-613 {
  width: 613px !important;
}

.file-template .w-614 {
  width: 614px !important;
}

.k-pdf-export .w-614 {
  width: 614px !important;
}

.file-template .w-615 {
  width: 615px !important;
}

.k-pdf-export .w-615 {
  width: 615px !important;
}

.file-template .w-616 {
  width: 616px !important;
}

.k-pdf-export .w-616 {
  width: 616px !important;
}

.file-template .w-617 {
  width: 617px !important;
}

.k-pdf-export .w-617 {
  width: 617px !important;
}

.file-template .w-618 {
  width: 618px !important;
}

.k-pdf-export .w-618 {
  width: 618px !important;
}

.file-template .w-619 {
  width: 619px !important;
}

.k-pdf-export .w-619 {
  width: 619px !important;
}

.file-template .w-620 {
  width: 620px !important;
}

.k-pdf-export .w-620 {
  width: 620px !important;
}

.file-template .w-621 {
  width: 621px !important;
}

.k-pdf-export .w-621 {
  width: 621px !important;
}

.file-template .w-622 {
  width: 622px !important;
}

.k-pdf-export .w-622 {
  width: 622px !important;
}

.file-template .w-623 {
  width: 623px !important;
}

.k-pdf-export .w-623 {
  width: 623px !important;
}

.file-template .w-624 {
  width: 624px !important;
}

.k-pdf-export .w-624 {
  width: 624px !important;
}

.file-template .w-625 {
  width: 625px !important;
}

.k-pdf-export .w-625 {
  width: 625px !important;
}

.file-template .w-626 {
  width: 626px !important;
}

.k-pdf-export .w-626 {
  width: 626px !important;
}

.file-template .w-627 {
  width: 627px !important;
}

.k-pdf-export .w-627 {
  width: 627px !important;
}

.file-template .w-628 {
  width: 628px !important;
}

.k-pdf-export .w-628 {
  width: 628px !important;
}

.file-template .w-629 {
  width: 629px !important;
}

.k-pdf-export .w-629 {
  width: 629px !important;
}

.file-template .w-630 {
  width: 630px !important;
}

.k-pdf-export .w-630 {
  width: 630px !important;
}

.file-template .w-631 {
  width: 631px !important;
}

.k-pdf-export .w-631 {
  width: 631px !important;
}

.file-template .w-632 {
  width: 632px !important;
}

.k-pdf-export .w-632 {
  width: 632px !important;
}

.file-template .w-633 {
  width: 633px !important;
}

.k-pdf-export .w-633 {
  width: 633px !important;
}

.file-template .w-634 {
  width: 634px !important;
}

.k-pdf-export .w-634 {
  width: 634px !important;
}

.file-template .w-635 {
  width: 635px !important;
}

.k-pdf-export .w-635 {
  width: 635px !important;
}

.file-template .w-636 {
  width: 636px !important;
}

.k-pdf-export .w-636 {
  width: 636px !important;
}

.file-template .w-637 {
  width: 637px !important;
}

.k-pdf-export .w-637 {
  width: 637px !important;
}

.file-template .w-638 {
  width: 638px !important;
}

.k-pdf-export .w-638 {
  width: 638px !important;
}

.file-template .w-639 {
  width: 639px !important;
}

.k-pdf-export .w-639 {
  width: 639px !important;
}

.file-template .w-640 {
  width: 640px !important;
}

.k-pdf-export .w-640 {
  width: 640px !important;
}

.file-template .w-641 {
  width: 641px !important;
}

.k-pdf-export .w-641 {
  width: 641px !important;
}

.file-template .w-642 {
  width: 642px !important;
}

.k-pdf-export .w-642 {
  width: 642px !important;
}

.file-template .w-643 {
  width: 643px !important;
}

.k-pdf-export .w-643 {
  width: 643px !important;
}

.file-template .w-644 {
  width: 644px !important;
}

.k-pdf-export .w-644 {
  width: 644px !important;
}

.file-template .w-645 {
  width: 645px !important;
}

.k-pdf-export .w-645 {
  width: 645px !important;
}

.file-template .w-646 {
  width: 646px !important;
}

.k-pdf-export .w-646 {
  width: 646px !important;
}

.file-template .w-647 {
  width: 647px !important;
}

.k-pdf-export .w-647 {
  width: 647px !important;
}

.file-template .w-648 {
  width: 648px !important;
}

.k-pdf-export .w-648 {
  width: 648px !important;
}

.file-template .w-649 {
  width: 649px !important;
}

.k-pdf-export .w-649 {
  width: 649px !important;
}

.file-template .w-650 {
  width: 650px !important;
}

.k-pdf-export .w-650 {
  width: 650px !important;
}

.file-template .w-651 {
  width: 651px !important;
}

.k-pdf-export .w-651 {
  width: 651px !important;
}

.file-template .w-652 {
  width: 652px !important;
}

.k-pdf-export .w-652 {
  width: 652px !important;
}

.file-template .w-653 {
  width: 653px !important;
}

.k-pdf-export .w-653 {
  width: 653px !important;
}

.file-template .w-654 {
  width: 654px !important;
}

.k-pdf-export .w-654 {
  width: 654px !important;
}

.file-template .w-655 {
  width: 655px !important;
}

.k-pdf-export .w-655 {
  width: 655px !important;
}

.file-template .w-656 {
  width: 656px !important;
}

.k-pdf-export .w-656 {
  width: 656px !important;
}

.file-template .w-657 {
  width: 657px !important;
}

.k-pdf-export .w-657 {
  width: 657px !important;
}

.file-template .w-658 {
  width: 658px !important;
}

.k-pdf-export .w-658 {
  width: 658px !important;
}

.file-template .w-659 {
  width: 659px !important;
}

.k-pdf-export .w-659 {
  width: 659px !important;
}

.file-template .w-660 {
  width: 660px !important;
}

.k-pdf-export .w-660 {
  width: 660px !important;
}

.file-template .w-661 {
  width: 661px !important;
}

.k-pdf-export .w-661 {
  width: 661px !important;
}

.file-template .w-662 {
  width: 662px !important;
}

.k-pdf-export .w-662 {
  width: 662px !important;
}

.file-template .w-663 {
  width: 663px !important;
}

.k-pdf-export .w-663 {
  width: 663px !important;
}

.file-template .w-664 {
  width: 664px !important;
}

.k-pdf-export .w-664 {
  width: 664px !important;
}

.file-template .w-665 {
  width: 665px !important;
}

.k-pdf-export .w-665 {
  width: 665px !important;
}

.file-template .w-666 {
  width: 666px !important;
}

.k-pdf-export .w-666 {
  width: 666px !important;
}

.file-template .w-667 {
  width: 667px !important;
}

.k-pdf-export .w-667 {
  width: 667px !important;
}

.file-template .w-668 {
  width: 668px !important;
}

.k-pdf-export .w-668 {
  width: 668px !important;
}

.file-template .w-669 {
  width: 669px !important;
}

.k-pdf-export .w-669 {
  width: 669px !important;
}

.file-template .w-670 {
  width: 670px !important;
}

.k-pdf-export .w-670 {
  width: 670px !important;
}

.file-template .w-671 {
  width: 671px !important;
}

.k-pdf-export .w-671 {
  width: 671px !important;
}

.file-template .w-672 {
  width: 672px !important;
}

.k-pdf-export .w-672 {
  width: 672px !important;
}

.file-template .w-673 {
  width: 673px !important;
}

.k-pdf-export .w-673 {
  width: 673px !important;
}

.file-template .w-674 {
  width: 674px !important;
}

.k-pdf-export .w-674 {
  width: 674px !important;
}

.file-template .w-675 {
  width: 675px !important;
}

.k-pdf-export .w-675 {
  width: 675px !important;
}

.file-template .w-676 {
  width: 676px !important;
}

.k-pdf-export .w-676 {
  width: 676px !important;
}

.file-template .w-677 {
  width: 677px !important;
}

.k-pdf-export .w-677 {
  width: 677px !important;
}

.file-template .w-678 {
  width: 678px !important;
}

.k-pdf-export .w-678 {
  width: 678px !important;
}

.file-template .w-679 {
  width: 679px !important;
}

.k-pdf-export .w-679 {
  width: 679px !important;
}

.file-template .w-680 {
  width: 680px !important;
}

.k-pdf-export .w-680 {
  width: 680px !important;
}

.file-template .w-681 {
  width: 681px !important;
}

.k-pdf-export .w-681 {
  width: 681px !important;
}

.file-template .w-682 {
  width: 682px !important;
}

.k-pdf-export .w-682 {
  width: 682px !important;
}

.file-template .w-683 {
  width: 683px !important;
}

.k-pdf-export .w-683 {
  width: 683px !important;
}

.file-template .w-684 {
  width: 684px !important;
}

.k-pdf-export .w-684 {
  width: 684px !important;
}

.file-template .w-685 {
  width: 685px !important;
}

.k-pdf-export .w-685 {
  width: 685px !important;
}

.file-template .w-686 {
  width: 686px !important;
}

.k-pdf-export .w-686 {
  width: 686px !important;
}

.file-template .w-687 {
  width: 687px !important;
}

.k-pdf-export .w-687 {
  width: 687px !important;
}

.file-template .w-688 {
  width: 688px !important;
}

.k-pdf-export .w-688 {
  width: 688px !important;
}

.file-template .w-689 {
  width: 689px !important;
}

.k-pdf-export .w-689 {
  width: 689px !important;
}

.file-template .w-690 {
  width: 690px !important;
}

.k-pdf-export .w-690 {
  width: 690px !important;
}

.file-template .w-691 {
  width: 691px !important;
}

.k-pdf-export .w-691 {
  width: 691px !important;
}

.file-template .w-692 {
  width: 692px !important;
}

.k-pdf-export .w-692 {
  width: 692px !important;
}

.file-template .w-693 {
  width: 693px !important;
}

.k-pdf-export .w-693 {
  width: 693px !important;
}

.file-template .w-694 {
  width: 694px !important;
}

.k-pdf-export .w-694 {
  width: 694px !important;
}

.file-template .w-695 {
  width: 695px !important;
}

.k-pdf-export .w-695 {
  width: 695px !important;
}

.file-template .w-696 {
  width: 696px !important;
}

.k-pdf-export .w-696 {
  width: 696px !important;
}

.file-template .w-697 {
  width: 697px !important;
}

.k-pdf-export .w-697 {
  width: 697px !important;
}

.file-template .w-698 {
  width: 698px !important;
}

.k-pdf-export .w-698 {
  width: 698px !important;
}

.file-template .w-699 {
  width: 699px !important;
}

.k-pdf-export .w-699 {
  width: 699px !important;
}

.file-template .w-700,
.k-pdf-export .w-700 {
  width: 700px !important;
}

.file-template .w-701,
.k-pdf-export .w-701 {
  width: 701px !important;
}

.file-template .w-702 ,
.k-pdf-export .w-702 {
  width: 702px !important;
}

.file-template .w-703 ,
.k-pdf-export .w-703 {
  width: 703px !important;
}

.file-template .w-704 ,
.k-pdf-export .w-704 {
  width: 704px !important;
}

.file-template .w-705 ,
.k-pdf-export .w-705 {
  width: 705px !important;
}

.file-template .w-706,
.k-pdf-export .w-706 {
  width: 706px !important;
}

.file-template .w-707,
.k-pdf-export .w-707 {
  width: 707px !important;
}

.file-template .w-708,
.k-pdf-export .w-708 {
  width: 708px !important;
}

.file-template .w-709,
.k-pdf-export .w-709 {
  width: 709px !important;
}

.file-template .w-710,
.k-pdf-export .w-710 {
  width: 710px !important;
}

.file-template .w-711,
.k-pdf-export .w-711 {
  width: 711px !important;
}

.file-template .w-712,
.k-pdf-export .w-712 {
  width: 712px !important;
}

.file-template .w-713,
.k-pdf-export .w-713 {
  width: 713px !important;
}

.file-template .w-714,
.k-pdf-export .w-714 {
  width: 714px !important;
}

.file-template .w-715,
.k-pdf-export .w-715 {
  width: 715px !important;
}

.file-template .w-716,
.k-pdf-export .w-716 {
  width: 716px !important;
}

.file-template .w-717 ,
.k-pdf-export .w-717 {
  width: 717px !important;
}

.file-template .w-718,
.k-pdf-export .w-718 {
  width: 718px !important;
}

.file-template .w-719,
.k-pdf-export .w-719 {
  width: 719px !important;
}

.file-template .w-720,
.k-pdf-export .w-720 {
  width: 720px !important;
}

.file-template .w-721,
.k-pdf-export .w-721 {
  width: 721px !important;
}

.file-template .w-722,
.k-pdf-export .w-722 {
  width: 722px !important;
}

.file-template .w-723,
.k-pdf-export .w-723 {
  width: 723px !important;
}

.file-template .w-724,
.k-pdf-export .w-724 {
  width: 724px !important;
}

.file-template .w-725,
.k-pdf-export .w-725 {
  width: 725px !important;
}

.file-template .w-726,
.k-pdf-export .w-726 {
  width: 726px !important;
}

.file-template .w-727,
.k-pdf-export .w-727 {
  width: 727px !important;
}

.file-template .w-728,
.k-pdf-export .w-728 {
  width: 728px !important;
}

.file-template .w-729,
.k-pdf-export .w-729 {
  width: 729px !important;
}

.file-template .w-730,
.k-pdf-export .w-730 {
  width: 730px !important;
}

.file-template .w-731,
.k-pdf-export .w-731 {
  width: 731px !important;
}

.file-template .w-732,
.k-pdf-export .w-732 {
  width: 732px !important;
}

.file-template .w-733,
.k-pdf-export .w-733 {
  width: 733px !important;
}

.file-template .w-734,
.k-pdf-export .w-734 {
  width: 734px !important;
}

.file-template .w-735,
.k-pdf-export .w-735 {
  width: 735px !important;
}

.file-template .w-736,
.k-pdf-export .w-736 {
  width: 736px !important;
}

.file-template .w-737,
.k-pdf-export .w-737 {
  width: 737px !important;
}

.file-template .w-738,
.k-pdf-export .w-738 {
  width: 738px !important;
}

.file-template .w-739,
.k-pdf-export .w-739 {
  width: 739px !important;
}

.file-template .w-740,
.k-pdf-export .w-740 {
  width: 740px !important;
}

.file-template .w-741,
.k-pdf-export .w-741 {
  width: 741px !important;
}

.file-template .w-742,
.k-pdf-export .w-742 {
  width: 742px !important;
}

.file-template .w-743,
.k-pdf-export .w-743 {
  width: 743px !important;
}

.file-template .w-744,
.k-pdf-export .w-744 {
  width: 744px !important;
}

.file-template .w-745,
.k-pdf-export .w-745 {
  width: 745px !important;
}

.file-template .w-746,
.k-pdf-export .w-746 {
  width: 746px !important;
}

.file-template .w-747,
.k-pdf-export .w-747 {
  width: 747px !important;
}

.file-template .w-748,
.k-pdf-export .w-748 {
  width: 748px !important;
}

.file-template .w-749,
.k-pdf-export .w-749 {
  width: 749px !important;
}

.file-template .w-750,
.k-pdf-export .w-750 {
  width: 750px !important;
}

.file-template span.vericaltext {
  writing-mode: vertical-lr;
  text-orientation: upright;
}

.k-pdf-export span.vericaltext {
  writing-mode: vertical-lr;
  text-orientation: upright;
}

.file-template span.eleHeader {
  background: #dde1e6;
  color: #263238 !important;
  padding: 5px;
  margin-bottom: 5px;
  margin-top: 10px;
  border-bottom: 1px solid #ccc;
  font-weight: 500;
  font-size: 14px !important;
  display: block;
}

.k-pdf-export span.eleHeader {
  background: #dde1e6;
  color: #263238 !important;
  padding: 8px;
  margin-bottom: 5px;
  margin-top: 15px;
  border-bottom: 1px solid #ccc;
  font-weight: 700;
  font-size: 14px !important;
  display: block;
}

.k-pdf-export table > tbody > tr > td:not(:first-child) > span.eleHeader {
  margin-left: -8px;
  padding-right: 5px !important;
}

.k-pdf-export table > tbody > tr > td:first-child > span.eleHeader {
  margin-left: -8px;
  margin-right: 8px;
}

.doc-pdf .dmr {
  margin-right: 50px;
  padding: 10px 0 30px;
}

.doc-pdf .header {
  font-size: 14px;
  padding: 0;
}

.doc-pdf .content {
  font-size: 11px;
  padding: 0;
}

.file-template .header-text {
  font-size: 18px !important;
  margin-bottom: 2px;
  display: block;
}

.k-pdf-export .header-text {
  font-size: 14px !important;
  display: block;
}

.file-template .content-text-view {
  font-size: 14px;
  overflow-wrap: anywhere;
}

.file-template .content-text-pdf {
  font-size: 12px;
  line-height: 0.5;
}

.file-template select {
  width: 95%;
}

.k-pdf-export select {
  width: 90%;
}

.file-template br {
  clear: left;
}

.file-template .signature {
  border-bottom: 1px solid #000;
  height: 40px;
  margin-right: 5px;
}

.k-pdf-export .signature {
  border-bottom: 1px solid #000;
  height: 40px;
  padding: 5px 10px;
}

.file-template .small,
.file-template small {
  padding: 0px 0px 6px;
  font-size: 12px !important;
  display: block;
  margin-right: 5px;
  margin-left: 3px;
}

.file-pdf-document span {
  font-size: 14px !important;
}

.k-pdf-export span {
  font-size: 14px !important;
}

.k-pdf-export .small {
  font-size: 12px !important;
  display: block;
  margin-right: 5px;
  margin-left: 3px;
  padding-right: 5px !important;
}

.file-template tr {
  display: flex;
  margin-top: 5px;
}

.file-template table {
  width: 100%;
}

.file-template td {
  font-size: 14px;
}

.k-pdf-export td {
  font-size: 14px;
}

.file-template p {
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.k-pdf-export p {
  font-size: 14px !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-right: 25px !important;
  width: auto !important;
}

.file-template h1,
.file-template h2,
.file-template h3,
.file-template h4,
.file-template h5,
.file-template h6 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

.k-pdf-export h1,
.k-pdf-export h2,
.k-pdf-export h3,
.k-pdf-export h4,
.k-pdf-export h5,
.k-pdf-export h6 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
  font-family: "Manrope" !important;
}

.file-template h1 {
  font-size: calc(1.1rem + 0.3vw);
}

.k-pdf-export h1 {
  font-size: calc(1.1rem + 0.3vw);
}

.file-template h2 {
  font-size: calc(1rem + 0.3vw);
}

.k-pdf-export h2 {
  font-size: calc(1rem + 0.3vw);
}

.file-template h3 {
  font-size: calc(0.9rem + 0.3vw);
}

.k-pdf-export h3 {
  font-size: calc(0.9rem + 0.3vw);
}

.file-template h4 {
  font-size: calc(0.8rem + 0.3vw);
}

.k-pdf-export h4 {
  font-size: calc(0.8rem + 0.3vw);
}

.file-template h5 {
  font-size: calc(0.7rem + 0.3vw);
}

.k-pdf-export h5 {
  font-size: calc(0.7rem + 0.3vw);
}

.file-template .form-group label h6 {
  font-size: 1rem !important;
}

.k-pdf-export .form-group label h6 {
  font-size: 1rem !important;
}

.k-pdf-export .preview-section {
  margin: 0 20px !important;
  font-size: 20px;
  padding-right: 30px;
}

.k-pdf-export h6 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 18px !important;
  font-weight: 400;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

.radio-form-grp {
  border-radius: 25px;
  border: 2px solid #ea4d36;
  padding: 10px 15px;
}

.k-pdf-export input[type="radio"],
.file-template input[type="radio"],
.k-pdf-export input[type="checkbox"],
.file-template input[type="checkbox"] {
  accent-color: #263238 !important;
}

.radio-sub-form {
  height: 146px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.radio-sub-form .k-checkbox-wrap {
  display: flex !important;
}

.radio-sub-form .k-checkbox:checked:focus,
.radio-sub-form .k-checkbox.k-checked.k-focus {
  box-shadow: unset !important;
}

.radio-sub-form::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.radio-sub-form::-webkit-scrollbar {
  width: 7px;
  max-height: 10px;
  height: 10px;
  padding: 20px 0px;
}

.radio-sub-form::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
  background-color: #7c7c7c;
}

.radio-square .k-radio-list .k-radio-item .k-radio {
  border-radius: 20%;
}

.radio-label-head {
  font-size: 18px !important;
}

.session-summary {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
}

.ai-notes-text {
  width: 100% !important;
}

.page-break {
  page-break-before: always;
}

@media screen and (max-width: 991px) {
  .file-template tr {
    flex-wrap: wrap;
  }
}

@media print {
  .new-page {
    page-break-before: always;
  }
}

@media print {
  .pagebreak {
    page-break-before: always;
  }
}

/* FIX FOR DOCUMENT LIBRARY */
/* document-file-template.css */

body,
html {
  overflow-x: hidden;
  font-family: "Manrope" !important;
  background-color: #f9f9f952 !important;
  width: 100%;
}

:root {
  --blue-primary: #ea4d36;
  --grey-secondary: #ababab !important;
  --white: #fff;
  --borderColor: #ddd;
  --activeColor: #021f54;
  --blueTheme: #021f54;
  --black: #000;
  --border2: #929292;
  --bs-white-rgb: var(--white);
  --label_heading: #231e2d;
  --login_label: #3c4257;
  --bg_icons: #021f54;
  --themeColor: #ea4d36;
  --themeColor_border: #ea4d36;
  --dashboardCol_border: #dfe5e2;
  --status_dahboard: #f1983621;
  --dahboard_number: #e84d35;
  --color_text: #5c5d61;
  --footer_sidebar: #f6f6f6;
  --grey_text: #545a69;
  --grey_icons: #ababab;
  --grid_header: #424242;
  --grid_border: rgba(0, 0, 0, 0.08);
  --profile_bg: #fdf2e1;
  --search_bg: #aaaaaa;
  --search_shadow: #0000001a;
  --border-bottom-line: #00000014;
  --dropdwon_shadow: #00000029;
  --before_color: #707070;
  --title_color: #171717;
  --scroll_color: #8a8a8a;
  --top_border_color: #e6e6e6;
  --red: #d51923;
  --pdf-color: #fdf2e1;
  --pdf-grid: #fdf2e1;
  --custom_tooltip: #ccc;
  --border-Bottom: #eee;
  --plus_bg: #ebebeb;
  --header_grid: #dee2e6;
  --completed: #2f7804;
  --lebel_2: #5f5f5f;
  --green: #47855b;
  --column-first: #777;
  --light_grey: #f9f9f9;
  --hover-bg: #55526714;
}

body,
html,
h1,
h2,
h3,
h4,
h6,
a,
p {
  font-family: "Manrope" !important;
}

div .blue-theme,
div .blue-primary {
  background-color: var(--blueTheme);
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  border: 1px solid var(--blueTheme) !important;
}

div .blue-theme:hover,
div .blue-primary:hover {
  background: #021f54 !important;
  color: var(--white);
}
div .blue-primary:hover {
  background-color: transparent !important;
  color: #021f54 !important;
}

.join-popup {
  position: absolute;
  top: 10px;
  padding: 1px 10px !important;
  font-size: 13px;
}

div .blue-primary-outline {
  border: 1px solid var(--blueTheme);
  border-radius: 4px;
  color: var(--blueTheme);
  background-color: var(--white);
}

.btn-danger-delete {
  background: var(--borderColor) !important;
  border-radius: 8px !important;
  color: var(--black) !important;
}

div .blue-primary-outline:hover {
  background: var(--blueTheme) !important;
  border: 1px solid var(--blueTheme);
  color: var(--white);
}

div .grey-secondary {
  background: var(--grey-secondary);
  border-radius: 4px;
  color: var(--white);
  border-color: var(--grey-secondary);
}

div .grey-secondary:hover {
  background: #918c8c;
  color: var(--white);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.5;
}

.bg-transparnt {
  background-color: transparent;
}

.text-theme {
  color: #07479a;
}

.text-orangeComment {
  color: #ea4d36;
  font-size: 12px;
}

.text-grey {
  color: var(--grey_text);
}

.toogle-hide:after {
  display: none !important;
}

.eye-close {
  position: absolute;
  top: 43px;
  right: 27px;
}

.cursor-default {
  cursor: default !important;
}

.cursor-auto {
  cursor: auto;
}

.text-black-common {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.6px;
  color: var(--black);
  opacity: 1;
  font-weight: 400;
}

.common-heading {
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 1.6px;
  color: var(--black);
  opacity: 1;
  font-weight: 500;
}

.list-profile li a:hover {
  color: var(--grey_text);
}

input:focus,
textarea:focus,
select:focus {
  box-shadow: unset !important;
  border-color: var(--blue-primary) !important;
}

textarea.k-input-inner {
  margin: 0;
  min-height: 150px;
}

.top-header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-header .search-icon {
  position: absolute;
  left: 13px;
  z-index: 1;
  transform: rotate(88deg);
  color: var(--search_bg);
  top: 18px;
}

.user-top {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100px;
}

.notification-drop::after,
.more-btn-drop a:after,
.staff-drop-nav a:after {
  display: none;
}

.nav-right ul li.icon-size {
  font-size: 23px;
}

.drop-user-toogle .dropdown-toggle:after {
  display: none;
}

.drop-user-toogle .dropdown-menu {
  top: 55px !important;
  left: 40px !important;
  box-shadow: 0 0 0 1px rgb(136 152 170 / 10%),
    0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%);
  border: 0px;
  min-width: 210px;
  padding: 0px;
  z-index: 999999;
}

.drop-user-toogle .dropdown-menu:before {
  border: 14px solid transparent;
  border-bottom-color: var(--white);
  content: "";
  position: absolute;
  right: 12px;
  top: -28px;
  width: 20px;
}

/**iner-header**/
header.inner-header .navbar-light .navbar-nav .nav-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1px;
  color: var(--secondary-color);
  opacity: 1;
  padding: 7px 10px;
  border-radius: 4px;
  display: inline-block;
}

header.inner-header .navbar-light .navbar-nav .nav-item .nav-link:hover {
  background: var(--hover-bg);
  border-radius: 4px;
  color: var(--black);
}

header.inner-header .navbar-light .navbar-nav li,
.more-btn-drop a.dropdown-toggle {
  margin-right: 4px;
  margin-left: 4px;
}

header.inner-header .navbar-light .navbar-nav li a.nav-link.active {
  background: #ffebe7 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  opacity: 1;
  color: #ea4d36 !important;
}

header.inner-header:after {
  content: "";
  border-bottom: 1px solid var(--before_color);
  opacity: 0.1;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  box-shadow: 0px 15px 6px var(--dropdown_shadow);
}

.more-btn-drop .dropdown-menu-t {
  transform: translate(-42%, 0px);
  border: 0px;
}

.more-btn-drop .drop-downlist {
  max-width: 500px;
  width: 500px;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0 2px 28px rgba(0, 0, 0, 0.3);
  opacity: 1;
  margin-top: 13px;
  border: 0px;
  padding: 20px 14px;
}

.more-btn-drop h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 1.6px;
  color: var(--secondary-color);
  opacity: 1;
  line-height: 16px;
}

.more-btn-drop p {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1.4px;
  color: #aeaeae;
  opacity: 1;
}

.more-btn-drop .drop-downlist:before {
  content: "";
  position: absolute;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  top: -20px;
  border-bottom: 10px solid var(--white);
  border-left: 11px solid transparent;
  display: block;
  left: 0;
  right: 0;
  width: 18px;
  margin: 0 auto;
  top: -7px;
}

.more-btn-drop .dropdown-item {
  border-radius: 20px;
  color: #37393d;
  font-size: 14px;
  padding: 0.25rem 1rem;
}

.more-btn-drop .dropdown-item:focus,
.more-btn-drop .dropdown-item:hover {
  background-color: #021f5412;
  color: var(--black);
}

.more-btn-drop .dropdown-item.active {
  color: var(--white) !important;
  background-color: #021f54;
  border-radius: 4px;
}

.top-circle-show {
  position: absolute;
  background: var(--activeColor);
  color: var(--white);
  display: inline-block;
  border-radius: 100%;
  padding: 4px 6px;
  font-size: 7px;
  left: 10px;
  top: 4px;
}

/**======top header background========***/
.top-header {
  padding: 0 17px;
  box-shadow: unset;
}

.top-header-staff .searchStaff {
  padding: 0 !important;
  box-shadow: unset;
  position: absolute;
  left: 13px;
  z-index: 1;
  transform: rotate(88deg);
  color: var(--search_bg);
  top: 18px;
}

.top-header span.k-appbar-separator {
  display: none !important;
}

/**======logo========***/

.notenetic-text img {
  width: 180px;
}

/***======Header search bar=========***/

/***=====header right sidebar=======****/
.icons-setting span.k-icon.k-i-gear,
.icons-setting {
  color: var(--grey_text);
  font-size: 28px;
  text-decoration: none;
}

.icons-setting span.k-icon.k-i-bell {
  font-size: 28px;
  color: var(--grey_text);
  margin-left: 10px;
  margin-right: 10px;
}

.icons-setting span.user-number {
  height: 15px;
  width: 14px;
  line-height: 6px;
  position: absolute;
  background: var(--activeColor);
  color: var(--white);
  display: inline-block;
  border-radius: 100%;
  padding: 4px 6px;
  font-size: 7px;
  right: 7px;
  top: 7px;
}

/****=====top header End========***/
label {
  display: inline-block;
}

/*** Start inner header Menu  dashboard======***/
.menu-header-items ul {
  padding-top: 10px;
  padding-bottom: 10px;
}

.menu-header-items .k-menu-link {
  margin: 0px 2px;
}

.menu-header-items {
  margin-bottom: 30px;
}

.menu-header-items span.k-link.k-menu-link {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.6px;
  color: var(--black);
  opacity: 1;
  line-height: 23px;
  padding: 0px;
}

.menu-header-items span.k-link.k-menu-link span.k-menu-link-text {
  padding: 1px 12px;
}

/****=====items active menu=====*****/
.menu-header-items .k-menu-item.active span.k-link.k-menu-link {
  background: var(--activeColor);
  color: var(--white);
  border-radius: 20px;
}

.menu-header-items
  li.k-item.k-menu-item.active
  span.k-link.k-menu-link:hover
  .k-menu-link-text {
  background: var(--activeColor) !important;
  color: var(--white);
  border-radius: 20px;
}

/****=====items hover menu=====*****/
.menu-header-items
  .k-menu-item
  span.k-link.k-menu-link:hover
  span.k-menu-link-text {
  background: #ebeef1;
  color: var(--black);
  border-radius: 20px;
}

.menu-header-items .k-menu:not(.k-context-menu) > .k-item:focus,
.menu-header-items .k-menu:not(.k-context-menu) > .k-item.k-focus,
.menu-header-items .k-drawer-item:focus {
  box-shadow: unset !important;
}

/****=====End inner menu=====****/

/****=====Start left  sidebar =====***/
.side-bar-left ul.k-drawer-items li:first-child .staff-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px !important;
  padding-left: 0px;
}

.side-bar-left ul.k-drawer-items li:first-child:before {
  border-left: 0px;
}

.side-bar-left ul.k-drawer-items li:before {
  border-left: 1px solid var(--before_color);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 103px;
  opacity: 0.1;
}

.side-bar-left .k-drawer-wrapper {
  overflow: inherit;
}

.side-bar-left ul.k-drawer-items li {
  position: relative;
  border-right: 0px;
  margin-bottom: 7px !important;
  padding: 6px 10px 6px;
  transition: all 0.3s;
}

.side-bar-left ul.k-drawer-items li:last-child {
  padding-bottom: 0px;
  background-color: transparent;
}

.side-bar-left ul.k-drawer-items li span.k-item-text.staff-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.6px;
  opacity: 1;
  transition: all 0.3s ease-in;
  font-weight: 400;
}

.side-bar-left ul.k-drawer-items li {
  font-size: 14px;
}

.side-bar-left .staff-text img {
  max-width: 18px;
  margin-right: 5px;
  height: fit-content;
}

.side-bar-left .k-widget.k-drawer.k-drawer-start {
  border: 0px;
}

.side-bar-left ul.k-drawer-items li:nth-child(4) span,
.side-bar-left ul.k-drawer-items li:nth-child(5) span {
  padding-left: 18px;
}

div .side-bar-left ul.k-drawer-items li.k-selected span {
  font-weight: 700 !important;
}

.side-bar-left .k-menu:not(.k-context-menu) > .k-item:focus,
.side-bar-left .k-menu:not(.k-context-menu) > .k-item.k-focus,
.side-bar-left .k-drawer-item:focus {
  box-shadow: unset !important;
}

.side-bar-left
  ul.k-drawer-items
  li.k-selected:first-child
  span.k-item-text.staff-text {
  padding-left: 12px;
}

/****=====End  sidebar  Staff =====***/
/**===Staff Page tabs design=====**/
.tabs-nurse-dt .k-tabstrip-top > .k-tabstrip-items-wrapper > .k-tabstrip-items {
  width: 100%;
  border-bottom: 1px solid var(--border-Bottom);
}

.tabs-nurse-dt
  .k-tabstrip.k-floatwrap
  .k-tabstrip-items
  li.k-item.k-first.k-active
  .k-link {
  color: var(--activeColor);
}

.tabs-nurse-dt .k-tabstrip.k-floatwrap .k-tabstrip-items li.k-item .k-link {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  color: var(--black);
  opacity: 1;
  padding: 0;
  padding-bottom: 12px !important;
}

.tabs-nurse-dt
  .k-tabstrip-top
  > .k-tabstrip-items-wrapper
  > .k-tabstrip-items
  li:nth-child(2) {
  padding-right: 90px;
  padding-left: 90px;
}

.tabs-nurse-dt li.k-item.k-active span {
  border-bottom: 1px solid var(--activeColor) !important;
}

/***====Tabs1 data show =====***/
.tabs-nurse-dt .k-content.k-active {
  border: 0px;
}

.inner-tabs-dt .k-card {
  background: #fdfeff 0% 0% no-repeat padding-box;
  border: 1px solid var(--black);
  border-radius: 4px;
  opacity: 1;
  padding: 27px 25px;
  position: relative;
  overflow: unset;
}

.inner-tabs-dt .k-card .k-card-actions {
  display: block;
  padding: 0px;
}

.inner-tabs-dt .k-tabs-cards .k-hbox {
  padding: 0px;
  border: 0px;
}

.inner-tabs-dt .k-tabs-cards p {
  font-weight: normal normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 2.4px;
  color: #666666;
  opacity: 1;
}

.inner-tabs-dt .k-tabs-cards h6 {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 1.6px;
  color: var(--activeColor);
  opacity: 1;
  text-decoration: none;
  text-transform: uppercase;
}

.border-bottom-line {
  border-bottom: 1px solid var(--border-bottom-line);
  padding: 0 10px;
}

.tabs-nurse-dt .k-tabstrip.k-floatwrap .k-tabstrip-items-wrapper.k-hstack,
.tabs-nurse-dt .k-content,
.tabs-nurse-dt li.k-item.k-active {
  border: 0px;
}

.tabs-nurse-dt .k-content {
  margin-top: 30px;
}

/**===End=====**/
/***=====login-page=====***/
.inner-login-notenetic {
  display: flex;
  flex-direction: column;
  background: var(--white);
  box-shadow: 0px 15px 35px 0px #003c4214, 0 5px 15px 0px rgb(0 0 0 / 12%);
  padding: 56px 68px;
  border-radius: 4px;
}

.login-form {
  height: 100vh;
  display: flex;
  align-items: center;
}

.login-wrapper_img img {
  width: 260px;
}

.inner-login-notenetic h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.02px;
  text-align: left;
  margin-bottom: 25px;
}

.inner-login-notenetic .k-floating-label-container input {
  min-height: 49px;
  border-radius: 4px;
  padding-left: 17px !important;
  padding-right: 17px !important;
  font-size: 14px;
  border: 1px solid var(--border2) !important;
}

.inner-login-notenetic label {
  font-size: 16px !important;
  font-weight: 500;
  color: var(--login_label);
  margin-bottom: 0px;
}

.inner-login-notenetic button {
  background: var(--activeColor);
  color: var(--white);
  border: 0px;
  font-size: 16px;
  min-height: 44px;
  border-radius: 4px;
}

.inner-login-notenetic span.k-floating-label-container {
  width: 100%;
}

.inner-login-notenetic a.forget-anchar {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: var(--activeColor);
}

.inner-login-notenetic .list-check {
  padding-top: 30px;
  padding-bottom: 30px;
}

.inner-login-notenetic input[type="checkbox"],
.inner-login-notenetic input[type="radio"] {
  min-height: auto;
  margin-top: 0px;
  margin-right: 6px;
}

.inner-login-notenetic .k-floating-label-container.k-state-empty > .k-label,
.inner-login-notenetic .k-floating-label-container.k-empty > .k-label {
  transform: scale(1);
  top: 0px;
  left: 0px;
}

p.dont_texxt {
  font-size: 16px !important;
  font-weight: 500;
  color: var(--login_label);
}

.login-header {
  background-color: #011f53;
}

.login-header p {
  margin-bottom: 0px;
}

/****====End login page====***/
/****=====View staff page form====****/
.client-accept span.k-floating-label-container.k-empty,
.client-accept .k-floating-label-container {
  width: 100%;
}

div .k-input,
.client-accept .phone-number {
  border: 1px solid var(--border2);
  border-radius: 4px;
  opacity: 1;
  min-height: 41.6px;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  color: var(--title_Color);
  box-shadow: unset !important;
}

div .k-input-solid.k-input,
div .client-accept .phone-number,
div span.k-dateinput.k-input,
div .pac-target-input {
  border: 1px solid #c1c7cd;
  border-radius: 5px;
}

label,
.label-size,
.k-floating-label-container > .k-label,
.k-floating-label-container > .k-label {
  letter-spacing: 0px;
  color: var(--label_heading);
  opacity: 1;
  position: relative;
  margin-bottom: 10px;
  font-weight: 600 !important;
  font-size: 14px;
}

.client-accept h5 {
  letter-spacing: 0px;
  color: var(--title_Color);
  opacity: 1;
  position: relative;
  margin-bottom: 0px;
  font-weight: 400 !important;
  font-size: 14px;
}

span.k-dateinput.k-input {
  padding-left: 30px;
  margin: 0;
}

.k-input-solid:focus,
.k-input-solid.k-focus,
.client-accept .phone-number:focus,
.phone-number:focus-visible {
  border: 1px solid var(--activeColor) !important;
  box-shadow: unset;
  outline: unset;
}

div .k-i-arrow-s::before {
  content: "\e015";
}

div span.k-floating-label-container.k-empty.k-focus .k-input:focus,
div .k-input-solid:focus-within {
  border: 1px solid var(--blueTheme) !important;
  box-shadow: unset !important;
}

.phone-number {
  margin-top: 6px !important;
}

.client-accept span.k-input.k-datepicker.k-input-md.k-rounded-md.k-input-solid {
  box-shadow: unset;
}

.widget-box {
  position: relative;
}

/****staff list page***/
.selecter-dt select {
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  min-width: 100px;
  padding: 9px;
  color: var(--border2);
  font-size: 14px;
  height: 45px;
}

.selecter-dt {
  position: relative;
  top: 4px;
}

.inner-tabs-dt .k-card ~ .k-checkbox:checked {
  border: 1px solid var(--white) !important;
}

/****=calender=====***/
button.k-input-button {
  position: absolute;
  top: 12px;
  left: auto;
  z-index: 2;
  width: 3px;
  height: auto;
  border-radius: 4px;
  background-color: transparent;
  padding: 0;
  margin: 0px;
  border: 0px;
  right: 0;
  width: 100%;
}

.k-input-button > .k-button-icon.k-svg-i-calendar {
  color: var(--activeColor);
  left: 10px;
  top: 2px;
  position: absolute;
}

.k-input-button > .k-button-icon.k-svg-i-caret-alt-down {
  right: 20px;
  position: absolute;
  top: -2px;
}

.grid-table
  button.k-input-button.k-button.k-icon-button.k-button-md.k-button-solid.k-button-solid-base {
  top: 4px;
}

.k-clear-value {
  position: absolute;
  right: -1px;
  top: 9px;
  z-index: 9;
}

/****====Demographics accordition tabs=====***/
.accordition-list .k-expander {
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 13px;
}

.accordition-list .k-expander {
  border: 0px;
}

.accordition-list .k-expander-header {
  color: var(--grid_header);
  outline: none;
  background: #f2f2f2;
  border-radius: 4px;
  padding: 13px 15px;
}

.accordition-list .k-expander-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  letter-spacing: 0px;
  color: var(--title_Color);
  opacity: 1;
  text-transform: capitalize;
}

.accordition-list span.k-icon.k-i-arrow-chevron-up {
  color: var(--black);
}

.client-accept.accordition-list .k-expander-content {
  padding-top: 20px;
  padding-bottom: 0px;
}

/***=======switch toggle ======*****/
h4.address-title {
  font-size: 16px;
  font-weight: 600;
}

h4.comments-title {
  font-size: 18px;
  font-weight: 600;
}

span.switch-title-text {
  font-weight: 400;
  font-size: 13px;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--title_Color);
  opacity: 1;
}

.switch-toogle-show-dt .k-switch-md .k-switch-track {
  width: 43px;
  height: 24px;
}

span.k-switch.k-switch-md.k-rounded-full.k-switch-off
  span.k-switch-thumb.k-rounded-full {
  left: -3px;
}

/**====End***/
/***====Client page table=====***/
.grid-table .k-grid-header-wrap thead {
  background: #f5b35938 !important;
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
}

.k-grid tr.k-alt {
  background-color: rgb(246 246 246) !important;
}

.css-twdgtn-MuiBadge-badge {
  background-color: var(--blue-primary) !important;
}

.grid-table .k-grid {
  border-color: var(--grid_border);
  color: var(--grid_header);
  background-color: var(--white);
}

.grid-table .k-grid-header-wrap,
.grid-table div.k-grid-header {
  padding-right: 0px;
}

.grid-table .k-cell-inner > .k-link {
  padding: 10px;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: inherit;
  flex: 1 1;
  overflow: hidden;
}

.grid-table .k-grid-header .k-header {
  border-right: 0px !important;
  border: 0px !important;
}

.k-grid-header-sticky {
  background: #fdedd8 !important;
  color: var(--black);
}

.bg-custom-color {
  background: var(--white) !important;
  z-index: 9;
}

/****====Top serach filter=====**/
.top-bar-show-list {
  margin-bottom: 30px;
}

.top-bar-show-list::-webkit-scrollbar {
  display: none !important;
}

.content-search-filter .dropdown-toggle:after {
  display: none;
}

.content-search-filter input.icon-searchinput {
  background: var(--white) 0% 0% no-repeat padding-box;
  border-radius: 26px;
  min-width: 340px;
  height: 45px;
  padding-left: 40px !important;
  font-size: 13px;
  border: 1px solid #dadada !important;
}

.content-search-filter input.filtersearch {
  background: var(--white) 0% 0% no-repeat padding-box;
  border-radius: 26px;
  min-width: 250px;
  height: 45px;
  padding-left: 19px !important;
  padding-right: 40px !important;
  font-size: 13px;
}

.content-search-filter input:focus {
  box-shadow: unset !important;
}

.content-search-filter {
  position: relative;
}

.content-search-filter .search-icon {
  position: absolute;
  left: 16px;
  top: 26px;
  z-index: 1;
  width: 15px;
  margin-top: -9px;
}

div .filter-search {
  position: absolute;
  right: 18px;
  top: 15px;
  z-index: 1;
  width: 17px;
}

.content-search-filter.dropdown .dropdown-toggle:before {
  display: none;
}

.dropdown-filter-menu {
  width: 95%;
  transform: translate3d(13px, 53px, 0px) !important;
}

.dropdown-filter-menu input {
  width: 100%;
}

/****====End====***/
/***===Calender  page popup event===*****/
.nn-form span.k-floating-label-container.k-empty {
  width: 100%;
}

.k-input-solid:focus,
.nn-form span.k-combobox.k-input.k-input-md.k-rounded-md.k-input-solid {
  box-shadow: unset !important;
}

.nn-form span.k-input.k-textarea.k-input-md.k-input-solid.k-rounded-md {
  height: auto;
}

.k-input:focus,
textarea:focus {
  border-color: var(--activeColor) !important;
}

/***====common button add client======****/

.f-24 {
  font-size: 22px;
}

.f-12 {
  font-size: 12px !important;
  line-height: 14px;
}

.f-9 {
  font-size: 9px !important;
}

.f-14 {
  font-size: 14px;
  line-height: 17px;
}

.f-16 {
  font-size: 14px;
}

.f-17 {
  font-size: 17px;
}

.f-18 {
  font-size: 18px;
  line-height: 21px;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.pb_20,
.ptb_20 {
  padding-bottom: 20px;
}

.pt_20,
.ptb_20 {
  padding-top: 20px;
}

.pt_30 {
  padding-top: 30px;
}

.ptb_40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pb_10 {
  padding-bottom: 10px;
}

.pt_30,
.ptb_30 {
  padding-top: 30px;
}

.pb_30,
.ptb_30 {
  padding-bottom: 30px;
}

/***kendo common css====***/
.k-expander.k-expanded .k-focus {
  border: 0px;
}

.k-expander.k-state-focus,
.k-expander.k-focus {
  box-shadow: unset !important;
}

.k-input-solid {
  border-color: unset;
  border: 0px;
}

.k-tabstrip-items-wrapper .k-item:focus,
.k-tabstrip-items-wrapper .k-item.k-state-focused,
.k-tabstrip-items-wrapper .k-item.k-focus {
  box-shadow: unset !important;
  border: px !important;
}

.k-floating-label-container.k-state-empty > .k-label,
.k-floating-label-container.k-empty > .k-label,
.k-floating-label-container:focus-within > .k-label,
.k-floating-label-container.k-empty > .k-floating-label {
  top: 0px !important;
  left: 0px !important;
  transform: scale(1) !important;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: #021f54 !important;
  color: var(--white);
  background-color: #021f54 !important;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
  box-shadow: 0 0 0 2px var(--blue-primary) !important;
}

.message-selectAll .k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
  box-shadow: 0 0 0 0 !important;
}

.message-selectAll .k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: var(--blueTheme) !important;
  color: var(--white);
  background-color: var(--blueTheme) !important;
}

.k-floating-label-container {
  width: 100%;
}

.k-animation-container {
  z-index: 100002 !important;
}

.staffSetting-section .k-animation-container {
  z-index: 1 !important;
}

div .k-input-solid {
  border-color: transparent;
}

.k-grid tbody tr:not(.k-detail-row):hover,
.k-grid tbody tr:not(.k-detail-row).k-state-hover,
.k-grid tbody tr:not(.k-detail-row).k-hover {
  background-color: rgb(246 246 246) !important;
  cursor: pointer;
}

/***=====Client profile page====****/
/* delet only  after change bg color */

.profile-box-show .top-profileedit {
  background: #dde1e6 !important;
  border-radius: 30px 30px 0px 0px;
  opacity: 1;
  height: 100px;
  position: relative;
  text-align: center;
}

.profile-box-show .inner-uploadimg {
  position: relative;
  margin: 0 auto;
  text-align: center;
  top: -60px;
  min-width: 140px;
  left: 0;
  right: 0;
  max-width: 140px;
  min-height: 100px;
  height: 100px;
}
.client-profile .profile-box-show {
 margin-top: 50px;
}
.client-profile .profile-box-show .inner-section-edit{
  min-height: 380px;
}
.profile-box-show .inner-uploadimg img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-box-show .inner-uploadimg .icon-common {
  position: absolute;
  right: 17px;
  width: 26px;
  height: 26px;
  object-fit: cover;
  bottom: -26px;
  background: var(--activeColor);
  border-radius: 100px;
  line-height: 21px;
  color: var(--white);
  cursor: pointer;
}

.profile-box-show .inner-uploadimg .icon-common .k-icon {
  font-size: 12px;
}

div .camera-photo {
  position: absolute;
  bottom: -17px;
  right: 1px;
  background: var(--blueTheme);
  border-radius: 100px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-box-show .inner-uploadimg .icon-common .k-widget.k-upload {
  position: absolute;
  width: 26px;
  height: 29px;
  opacity: 0;
  cursor: pointer;
}

.profile-box-show .inner-uploadimg .k-upload-button input {
  position: absolute;
  width: 24px !important;
  right: 79px;
  bottom: 9px;
}

.profile-box-show .inner-section-edit {
  z-index: 2;
  padding-bottom: 20px;
  background: #dde1e64a;
  border-radius: 0 0 30px 30px;
}

.profile-box-show .inner-section-edit:before {
  background: var(--pdf-grid);
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.15;
  z-index: -1;
  border-radius: 0 0 30px 30px;
}

.profile-box-show ul.show-skills li {
  border-radius: 18px;
  opacity: 1;
  margin-bottom: 13px;
  font-weight: 600;
  letter-spacing: 1.6px;
  margin-right: 14px;
}

.profile-box-show ul.show-skills {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}

/***===Details info===****/
.details-info p {
  font-size: 13px !important;
  line-height: 15px;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
}

.details-info.firts-details-border:before {
  border-right: 1px solid var(--before_color);
  opacity: 0.2;
  right: 0px;
  height: 100%;
}

/**====Wigets==***/
.widget-box .k-expander {
  box-shadow: 0px 0px 6px var(--dropdown_shadow);
  border-radius: 4px;
  opacity: 1;
}

.widget-box .k-expander .k-expander-header {
  background: #dde1e6;
  padding: 11px 28px;
}

.widget-box .k-expander .k-expander-header .k-expander-title {
  color: var(--black);
  font-size: 16px;
}

.widget-box span.k-icon {
  font-size: 24px;
  line-height: 3px;
}

/**===Modal client profile===**/
.dialog-modal .inch-feet div:first-child {
  margin-right: 15px;
}

.dialog-modal .client-accept {
  overflow: scroll;
}

.client-accept::-webkit-scrollbar {
  display: none;
}

.small-dailog .client-accept {
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.dialog-modal .edit-client-popup {
  padding: 10px 10px;
}

.dialog-modal .client-accept::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background-color: var(--pdf-color);
}

.dialog-modal .client-accept::-webkit-scrollbar {
  width: 12px;
  background-color: var(--pdf-color);
}

.dialog-modal .client-accept::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
  background-color: var(--white);
}

.dialog-modal .k-widget.k-window.k-dialog {
  max-width: 800px;
  width: 100%;
}

body.model-open {
  overflow-y: hidden;
}

.dialog-modal .client-accept button.k-input-button {
  position: absolute;
  top: 17px;
}

/***===End===***/
/***====Staff Profile page====***/
.profile-edit-show {
  background: #dde1e666 0% 0% no-repeat padding-box;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 0px 6px 0 rgb(0 0 0 / 12%) !important;
  opacity: 1;
  padding: 20px 40px;
}

.profile-edit-show .profile-image img,
.widt-show {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-edit-show .profile-image .icon-common {
  position: absolute;
  bottom: 0px;
  right: 8px;
  background: var(--activeColor);
  width: 20px;
  height: 20px;
  line-height: 2px;
  border-radius: 100px;
  padding: 10px;
}

.profile-box-show {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 0px 6px 0 rgb(0 0 0 / 12%) !important;
  border-radius: 30px;
}

.profile-edit-show .profile-image .k-widget.k-upload .k-dropzone {
  position: absolute;
  width: 23px;
  height: 23px;
  opacity: 0;
}

.profile-edit-show
  .profile-image
  .k-button.k-button-solid.k-button-solid-base.k-upload-button {
  position: absolute;
  width: 24px;
  right: -3px;
  bottom: 3px;
}

.profile-edit-show .profile-image span.k-icon.k-i-photo-camera {
  color: var(--white);
  position: absolute;
  bottom: 5px;
  right: 4px;
  font-size: 11px;
}

.profile-edit-show .content-inner {
  min-width: 410px;
  padding-left: 30px;
}

.profile-edit-show .content-inner .details-info p {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: var(--black);
  opacity: 1;
}

/***===error page===**/
img.notfound-img {
  max-width: 400px;
  width: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.dt-background button {
  background: #0e2e64;
  border-radius: 4px;
  opacity: 1;
  padding: 14px 44px;
  color: var(--white);
  border: 0px;
  font-size: 16px;
  line-height: 21px;
  margin-top: 20px !important;
  text-align: center;
  display: block;
  margin: 0 auto;
}

.dt-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(0, 70px);
}

/***====Footer ====***/
footer.footer-main {
  background: #011f53;
  padding: 29px 0px;
  color: #fff;
}

footer.footer-main .text-grey {
  color: #cdd7ea !important;
  line-height: 30px;
}

.footer-main .notenetic-text img {
  width: 250px;
}

footer.footer-main .social-icons {
  text-align: center;
  max-width: 110px;
  width: 110px;
}

.inner-content-bottom {
  padding-bottom: 100px;
  position: relative;
  margin: 0px 0px;
}

.content-footer.pl-2 {
  padding-left: 12px;
}

/* comment after all theme covert in blue start*/

/***====Client Profile header=====**/
.client-profileheader .inner-uploadimg {
  top: 0px;
  height: auto;
}

.client-profileheader.profile-box-show {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 10px #21272a0d;
  border-radius: 10px;
  padding: 11px;
}

.client-profileheader.profile-box-show ul.show-skills li:nth-child(odd),
.client-profileheader.profile-box-show ul.show-skills li:nth-child(even) {
  margin-right: 10px;
  margin-left: 10px;
  float: left;
}

.client-profileheader.profile-box-show .inner-uploadimg img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.Staff-heading-title {
  cursor: pointer;
}

.Staff-heading-title:hover {
  text-decoration: underline;
  text-decoration-color: var(--activeColor);
}

/***-==Client lIst filter=====***/

/* comment after all theme covert in blue start*/
.current-popup {
  padding: 15px;
}

.filter-popup .k-popup {
  border-radius: 18px;
  position: relative;
}

.current-popup .form-input-width input {
  height: auto;
  padding: 0px;
}

.current-popup label {
  font-size: 12px;
}

.current-popup .label-width {
  width: 35%;
}

.current-popup .form-input-width {
  width: 64%;
}

.dropdown-filter .filter-search {
  right: 36px;
}

.current-popup button.search-btnfilter {
  background: var(--bg_icons) !important;
  border: 0px;
  color: var(--white);
  border-radius: 4px;
  padding: 4px 14px;
}

/***client-dashboard=====**/
.client-list-sibling li {
  display: flex;
  padding: 4px 14px;
}

.client-list-sibling li {
  display: flex;
  padding: 4px 14px;
  border-bottom: 1px solid var(--header-grid);
  align-items: center;
  justify-content: space-between;
}

.client-list-sibling li:last-child {
  border-bottom: 0px;
}

li span.k-icon {
  font-size: 16px !important;
  cursor: pointer;
  color: #0e2e64;
}

/****=====Staff signature ========****/
.signature-upload canvas.sigCanvas {
  width: 100%;
  height: 200px;
}

.sigCanvas {
  box-shadow: 1px 1px 10px 1px #00000038;
}

.sign-show-img img {
  width: 100%;
  max-height: 260px;
}

.pin input.pin {
  border: 1px solid var(--border2);
  border-radius: 4px;
  padding: 4px 8px;
  height: 43px;
  width: 100%;
}

.sign-show-img {
  border: 1px solid var(--border-Bottom);
  margin-top: 15px;
  background: var(--borderColor);
}

/****===loader css===****/
.k-loader.k-loader-lg.k-loader-primary.k-loader-spinner-4 {
  z-index: 999999;
  color: var(--activeColor);
}

.container.notentic-container {
  width: 100%;
  max-width: 100% !important;
}

/***===message=====***/
.side-bar-left.message-menubar .k-drawer-wrapper {
  width: 100% !important;
}

.side-bar-left.message-menubar
  ul.k-drawer-items
  li
  span.k-item-text.staff-text {
  padding-left: 0px;
}

.side-bar-left.message-menubar ul.k-drawer-items li:nth-child(2) {
  border-radius: 4px;
  padding: 13px;
  color: var(--black);
  margin-top: 23px;
}

.side-bar-left.message-menubar ul.k-drawer-items li.k-selected:nth-child(2) {
  background: #e0e4f1 !important;
}

.side-bar-left.message-menubar ul.k-drawer-items li:nth-child(2) span {
  font-weight: 600;
}

.side-bar-left.message-menubar ul.k-drawer-items li:first-child {
  display: none;
}

.bg-grey-dt {
  background: #ebeef1;
  width: 36px;
  height: 36px;
  border-radius: 100px;
  text-align: center;
  line-height: 37px;
  color: var(--before_color) !important;
  text-decoration: none !important;
  font-weight: 800;
}

.top-column-first {
  padding: 10px;
  margin-bottom: 10px;
}

.lable-details-msg {
  display: grid;
  width: 100%;
  grid-template-columns: 20px 180px 4fr 100px;
  align-items: self-start;
}

.document-label-details {
  display: grid;
  width: 100%;
  grid-template-columns: 20px auto 100px;
  align-items: center;
}

.top-column-first:nth-child(odd) {
  border-bottom: 1px solid #f2f4f8;
}

.column-check-message {
  padding-bottom: 30px;
  transition: all 0.5s linear;
}

.check_delte_icon {
  transition: all 0.5s ease;
  padding-top: 10px;
}

.column-check-message img.user-image {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 5px;
}

.top-column-first .text-content-message {
  max-width: 860px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top-column-first .text-content-message p {
  cursor: pointer;
}

.top-column-first .text-content-message p .message-subject {
  font-weight: 600;
}

.attachemnt {
  width: calc(100% - 280px);
  padding-top: 6px;
}

.upload-attachemnt li a {
  color: var(--black);
  text-decoration: none;
  border: 1px solid rgb(214, 202, 202);
  border-radius: 100px;
  padding: 6px 16px 6px 16px;
  font-size: 12px;
  display: inline-block;
  background-color: var(--white);
  overflow: hidden;
}

.month-number p {
  white-space: nowrap;
  color: #0e2e64;
  font-size: 12px;
}

.text-content-message p {
  line-height: 23px;
}

.attachemnt ul {
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0px;
}

.attachemnt ul li a {
  color: var(--black);
  text-decoration: none;
}

.labels-add li {
  border-radius: 4px;
  padding: 8px;
  transition: 0.3s ease-in-out;
}

.labels-add li:hover {
  background: #ffebe7;
  cursor: pointer;
  font-weight: 700 !important;
  color: #000;
}

.labels-add li p {
  font-size: 14px;
}

.labels-add li:hover .dropdown {
  visibility: visible;
  cursor: pointer;
}

.labels-add .dropdown {
  visibility: hidden;
}

.side-bar-left.message-menubar ul.k-drawer-items li.k-selected:nth-child(2) {
  border-left: 0px;
}

.side-bar-left.message-menubar
  ul.k-drawer-items
  li.k-selected:nth-child(2)
  span {
  color: var(--black) !important;
}

.side-bar-left.message-menubar ul.k-drawer-items li:nth-child(2):before {
  border-left: 0px;
}

.filter-drop-down .dropdown-filter-menu {
  width: 95%;
  transform: translate3d(13px, 0px, 0px) !important;
  z-index: 1;
}

/**===sidebar staff menu==***/
.navbar-nav li.nav-items .items-list {
  color: var(--black);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.6px;
  line-height: 16px;
}

.navbar-nav li.nav-items a {
  text-decoration: none;
  color: #697077;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 1px;
  opacity: 1;
  font-weight: 600;
}

.navbar-nav ul.drop-down-list {
  padding-left: 3px;
  margin-top: 8px;
}

.navbar-nav ul.drop-down-list li.drop-list.active {
  background-color: #ffebe7;
  color: #ea4d36;
}

.navbar-nav ul.drop-down-list li.drop-list {
  padding: 6px 10px;
  border-radius: 5px;
  margin-bottom: 4px;
}

.navbar-nav ul.drop-down-list li.drop-list.active a {
  color: #ea4d36 !important;
  font-weight: 700;
}

.navbar-nav li.drop-list:hover a,
.navbar-nav ul.drop-down-list li.drop-list:hover {
  background-color: #ffebe7;
  color: #ea4d36;
  cursor: pointer;
}

ul.drop-down-list {
  overflow-x: auto;
  overflow-y: scroll;
  height: calc(100vh - 184px);
  margin-bottom: 20px;
}

/***modal message composer****/
.modal-composer {
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
}

.content-composer {
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: var(--white);
  box-shadow: 0px 0px 6px var(--dropdown_shadow);
  border-radius: 13px;
  z-index: 2;
}

.minimize-content {
  max-width: 600px;
  margin: 0 auto;
  width: 600px;
}

.maximize-content-composer {
  max-width: 96%;
  width: 96%;
  right: 0;
  left: 0;
  transform: translate(10px, 10px);
  top: 105px;
  bottom: 40px;
  overflow: hidden;
  height: 85%;
  margin: auto;
  z-index: 999;
}

.maximize-content-composer .k-editor-content {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 200px !important;
}

.content-composer .top-header-composer {
  background: #f2f4f8;
  padding: 6px 15px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #0e2e64;
}

.content-composer .top-header-composer h5 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.inner-composer {
  padding: 15px 20px;
  max-height: calc(100vh - 126px);
  overflow-y: auto;
  height: 100%;
}

.inner-composer {
  scrollbar-color: var(--scroll_Color) var(--scroll_Color);
  margin-right: 4px;
}

.inner-composer::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: var(--white);
  -webkit-border-radius: 1ex;
}

.inner-composer::-webkit-scrollbar-thumb {
  background: var(--scroll_Color);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.6);
}

.inner-composer::-webkit-scrollbar-corner {
  background: var(--scroll_Color);
}

.inner-composer .column-first {
  display: flex;
  color: var(--column-first);
  width: 100%;
  border-bottom: 1px solid var(--border-Bottom);
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
}

.rp_pg {
  width: 57px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.composer-scroll {
  height: calc(100% - 320px);
  overflow-y: scroll;
}

.inner-composer .column-first .mail-composer {
  width: 100%;
}

.inner-composer .mail-composer .k-multiselect:focus,
.inner-composer .mail-composer .k-multiselect:focus-within {
  border: 0px !important;
  border-bottom: 1px solid var(--white) !important;
}

.inner-composer .mail-composer .k-multiselect,
.inner-composer .mail-composer input {
  border: 0px;
  border-bottom: 1px solid var(--white) !important;
  border-radius: 0px;
  height: auto;
  font-size: 14px;
  padding: 0px;
  margin-top: 0px;
  box-shadow: unset !important;
  min-height: auto;
}

.composer-scroll textarea.composer-text {
  width: 100%;
  height: 100%;
  min-height: 240px;
  border: 0px;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 14px;
  resize: none;
}

.content-mail {
  border: 1px solid var(--borderColor);
  float: left;
  border-radius: 100px;
  padding: 2px 6px;
  font-size: 13px;
  margin-bottom: 5px;
  margin-left: 6px;
}

.content-mail span .k-icon {
  color: var(--column-first);
  font-size: 12px;
  z-index: 1;
}

.composer-scroll textarea.composer-text:focus {
  border: 0px;
  box-shadow: unset;
  outline: 0px;
}

.inner-composer .column-first .mail-composer input:focus {
  border: 0px;
  box-shadow: unset;
}

.inner-composer span.k-icon.k-i-attachment {
  transform: rotate(45deg);
  color: var(--column-first);
}

.choose-input {
  position: absolute;
  left: 0px;
  width: 20px;
  opacity: 0;
  cursor: pointer;
}

.footer-composer i {
  cursor: pointer;
}

.footer-composer .k-upload-files.k-reset {
  height: 100px;
  overflow-y: scroll;
  scrollbar-color: var(--white);
}

.footer-composer .k-upload-files.k-reset::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: var(--white);
  -webkit-border-radius: 1ex;
}

.footer-composer .k-upload-files.k-reset::-webkit-scrollbar-thumb {
  background: var(--white);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.1);
}

.footer-composer .k-upload-files.k-reset::-webkit-scrollbar-corner {
  background: var(--white);
}

/****Steps****/
.steps .step:after {
  content: "";
  position: absolute;
  background: var(--_top_border_color);
  width: 0.125rem;
  height: 100%;
  top: 5px;
  left: 0px;
}

.steps > .step {
  position: relative;
  font-size: 0.875rem;
  padding-bottom: 30px;
}

.steps > .step.is-complete:before {
  background: var(--_top_border_color);
}

.steps > .step.is-active:before {
  background: var(--completed);
}

.steps > .step:before {
  content: attr(data-step);
  margin: 0 auto;
  background: var(--white);
  color: var(--_top_border_color);
  width: 15px;
  height: 15px;
  text-align: center;
  margin-bottom: -4.2rem;
  line-height: 1.9rem;
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  font-weight: 700;
  font-size: 1rem;
  left: -7px;
  display: inline-block;
  top: 5px;
}

.steps > .step:last-child:after {
  display: none;
}

.Satff-dark {
  position: relative;
}

.Satff-dark:before {
  content: "";
  background: #dddd;
  position: absolute;
  left: -14px;
  top: 0;
  height: 100%;
  width: 1px;
}

/***message detail page***/
.arrow-rotate .k-icon {
  transform: rotate(-90deg);
  font-size: 39px;
}

img.img-author-user {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 13px;
}

.dropleft .dropdown-toggle::before {
  display: none;
}

p.one-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-menu.attachment-dt {
  transform: translate3d(-226px, 34px, 0px) !important;
}

/*********calendar**/
.august-calender
  span.k-input.k-datepicker.k-input-md.k-rounded-md.k-input-solid {
  margin-top: 0px;
  padding-left: 0px;
  background: #f1f1f1;
  height: 30px;
  min-height: 30px;
  border: 1px solid #d8d4d4;
  border-radius: 4px;
  box-shadow: unset !important;
}

.content-search-filter .filter-calendar input {
  background: var(--white) 0% 0% no-repeat padding-box;
  border-radius: 26px;
  min-width: 250px;
  height: 45px;
  padding-left: 19px !important;
  padding-right: 40px !important;
  font-size: 13px;
}

.content-search-filter span.k-input-inner {
  border: 1px solid var(--border2);
  height: 43px;
  border-radius: 4px;
}

.content-search-filter
  span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
  background: transparent;
  border-radius: 4px;
}

/***client dashboard***/
.show-height-common {
  overflow-y: scroll;
  max-height: 250px;
  min-height: 250px;
  scrollbar-width: none;
  padding-right: 10px;
}

.white-scroll {
  scrollbar-color: var(--white) var(--white);
}

.white-scroll::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: var(--white);
  -webkit-border-radius: 1ex;
}

.white-scroll::-webkit-scrollbar-thumb {
  background: var(--white);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.1);
}

.white-scroll::-webkit-scrollbar-corner {
  background: var(--white);
}

/***add site client popup***/

.small-dailog .k-window-content.k-dialog-content {
  padding: 0px;
  overflow-x: hidden;
}

.small-dailog .edit-client-popup {
  padding: 15px;
}

.site-add-client .k-window-content.k-dialog-content .k-multiselect {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.popup-modal.slibling-data li.k-radio-item {
  margin-bottom: 12px;
}

.slibling-data span.k-floating-label-container {
  margin-bottom: 0px;
}

/****treatment plan***/
.other-treatment-goal {
  border: 0px !important;
}

.other-treatment-goal li.k-panelbar-header {
  border-bottom: 1px solid #a8a8a8 !important;
  border-top: 0px !important;
}

.other-treatment-goal li.k-panelbar-header span.k-panelbar-item-text {
  color: var(--black);
  font-weight: 600;
  font-size: 18px;
}

.other-treatment-goal
  li.k-panelbar-header
  ul
  li.objective-dt
  .k-panelbar-item-text {
  color: var(--blue-primary);
  font-size: 16px;
}

.other-treatment-goal
  li.k-panelbar-header
  ul
  .description-p
  span.k-panelbar-item-text {
  color: var(--black);
  font-weight: 400;
  font-size: 14px;
}

.description-p span.k-link {
  padding-left: 0px !important;
}

.add-invent-btn {
  display: block !important;
  position: relative;
  right: 30px;
  margin-left: auto;
}

.intervention-dt {
  border-bottom: 1px solid #a8a8a8;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.intervention-dt span.k-link {
  padding-left: 0px !important;
}

.intervention-dt span.k-link.k-selected {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.other-treatment-goal li.intervention-dt .k-panelbar-item-text {
  font-size: 14px !important;
}

.accordation-operation span.k-link.k-selected {
  background: transparent !important;
}

.accordation-operation span.k-link.k-focus {
  border: 0px !important;
  box-shadow: unset !important;
}

.k-panelbar > .k-item > .k-link .k-icon,
.k-panelbar > .k-item > .k-link .k-panelbar-item-icon,
.k-panelbar > .k-panelbar-header > .k-link .k-icon,
.k-panelbar > .k-panelbar-header > .k-link .k-panelbar-item-icon {
  color: var(--grid_header) !important;
}

.objective-dt {
  border: 1px solid #a8a8a829;
  margin: 10px 10px 16px;
  padding: 0px 0px;
  background: var(--light_grey);
}

span.k-panelbar-toggle.k-icon.k-i-arrow-chevron-down.k-panelbar-expand,
span.k-panelbar-toggle.k-icon.k-i-arrow-chevron-up.k-panelbar-collapse {
  color: var(--black) !important;
}

.bg-light-grey {
  background: var(--light_grey);
}

.objective-dt .k-content {
  background: var(--light_grey);
}

.treatment_upload .k-expander-title {
  color: #21272a;
  font-weight: 600;
  font-size: 14px;
}

.treatment_upload .change-expend .k-expander-title {
  color: var(--blue-primary);
}

.treatment_upload .k-expander.k-expanded {
  margin-bottom: 15px;
}

/**document ****/
.editor-message {
  width: 100%;
}

.border-bottom-hori {
  border-bottom: 1px solid var(--before_color);
  margin-bottom: 10px;
  margin-top: 10px;
  opacity: 0.2;
}

.preview-section {
  padding: 20px;
  border-radius: 4px;
  padding-left: 0px !important;
}

.user-show-preview {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
  display: block;
  background: var(--borderColor);
}

.c-link {
  color: #07479a;
  cursor: pointer;
}

.edit-form-show {
  border: 1px solid var(--borderColor);
  padding: 30px;
  border-radius: 4px;
}

/***table grid***/
.table-grid-dt {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  overflow-x: scroll;
}

.red-label {
  color: var(--red);
}

/***common-class**/
.table_view_dt table {
  border: 1px solid var(--grid_border);
}

.table_view_dt thead,
.blueThemeTable th {
  background: #dde1e6 !important;
  color: var(--black);
  font-size: 14px;
  font-weight: 400 !important;
}

.table_view_dt tbody tr:nth-child(even) {
  background: rgb(246 246 246);
}

.table_view_dt thead th,
.table_view_dt tbody td {
  padding: 10px 20px;
  white-space: pre;
}

.table_view_dt tbody td {
  font-family: inherit;
  font-size: 10px;
}

.doc-history .tableContent {
  color: var(--black) !important;
  font-weight: 500;
  font-size: 15px !important;
  margin-top: 4px !important;
  font-family: "Manrope", sans-serif !important;
}

.doc-history .printableContent {
  color: var(--black) !important;
  font-weight: 500;
  font-size: 14px !important;
  margin-top: 4px !important;
  font-family: "Manrope", sans-serif !important;
}

.table_view_dt .table > :not(:first-child) {
  border-top: 0px;
}

.table_view_dt tbody td,
.table_view_dt thead th {
  border-right: 1px solid #dedbdb;
  border-bottom: 0px;
  font-size: 10px;
}

.table_view_dt tbody td:last-child(),
.table_view_dt thead th:last-child() {
  border-right: 0px;
}

.firts-details-border .table_view_dt tbody td,
.firts-details-border .table_view_dt thead {
  font-size: 12px;
  text-align: center;
}

div .k-multiselect {
  min-height: 41.6px;
  height: auto;
}

span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
  background: transparent;
  height: 40px;
  border: none;
  border-radius: 4px;
  box-shadow: rgb(188 190 191) 0px 0px 0px 1px;
}

.switch-on .k-switch-track {
  margin-left: auto;
}

.k-switch-md {
  margin-right: 5px;
}

.k-switch-md .k-switch-thumb {
  width: 16px !important;
  height: 16px !important;
  filter: drop-shadow(0px 2px 4px rgba(0, 35, 11, 0.2));
}

.switch-on
  span.k-switch.k-switch-md.k-rounded-full.k-switch-on
  span.k-switch-thumb.k-rounded-full {
  right: -19px;
}

/*****modal page***/
.modal-preview .preview-dt {
  position: relative;
  right: 0;
}

.popupmodal-design .modal-content {
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 6%);
  border-radius: 7px;
  border: 0px;
}

.popupmodal-design .header-top {
  background: transparent;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #efefef;
}

.popupmodal-design button.close {
  position: absolute;
  top: 8px;
  right: 9px;
  z-index: 2;
  opacity: 1;
}

.popupmodal-design .inner-form-modal {
  padding: 10px 19px;
}

.dialog-modal .k-dialog-buttongroup {
  max-width: 200px;
  border-top: 0px;
}

.message-not-found {
  background: var(--hover-bg);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  padding: 20px;
  height: 40px;
}

.small-dailog-modal .k-widget.k-window.k-dialog {
  max-width: 600px;
  width: 100%;
}

.line-height-pencil {
  line-height: 15px;
}

/***role**/
.modules-section .k-expander-header {
  background: #0000000a !important;
  color: var(--black);
}

.modules-section .k-expander-header .k-expander-title {
  color: var(--black);
  font-weight: 600;
}

.modules-section .k-expander-content span {
  vertical-align: middle;
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}

.modules-section .k-expander {
  margin-bottom: 30px;
}

.modules-section span label {
  padding-left: 6px;
}

body {
  height: 100%;
}

/**task****/
.textarea-description {
  width: 100%;
  min-height: 150px;
  border: 1px solid var(--borderColor);
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
}

.discussion-detail .user-people {
  display: grid;
  grid-template-columns: 40px auto;
  gap: 10px;
}

.important {
  border-left: 3px solid var(--red);
}

.completed-important {
  border-right: 2px solid var(--completed);
  border-bottom: 2px solid var(--completed);
  border-top: 2px solid var(--completed);
  border-left: 3px solid var(--red);
}

.completed {
  border: 2px solid var(--completed);
}

.top-column-first.completed-column {
  background: #eefee7;
}

.top-column-first.not-completed-column {
  background: #fee9eb;
}

.top-column-first.important-column {
  border-left: 3px solid var(--red);
}

.grid-upper-head {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-gap: 12px;
}

.filter-width {
  max-width: 400px;
  width: 100%;
}

.k-checkbox-md {
  width: 16px !important;
  height: 16px;
}

.drop-menu-img {
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.more-btn-drop:hover .dropdown-menu-t {
  display: block;
}

.more-btn-drop .dropdown-menu-t {
  display: none;
  position: absolute;
  z-index: 99999999;
}

.dropdown-item.heading {
  width: 100% !important;
  background-color: #f4f4f4;
  color: rgba(92, 93, 97, 1);
  font-size: 12px;
  border-radius: 4px !important;
  cursor: default;
}

.dropdown-item.heading:hover {
  border-radius: 4px !important;
  background-color: #f4f4f4;
  color: rgba(92, 93, 97, 1);
}

.k-chip-content,
.k-chip {
  cursor: default !important;
}

/*****media ******/

@media (min-width: 1500px) {
  .notenetic-container {
    max-width: 1024px;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  div .media-colum {
    flex: 0 0 auto;
    width: 33%;
  }
}

@media (max-width: 1200px) {
  /**===client profile===**/
  .details-info.firts-details-border:before {
    border-bottom: 1px solid var(--before_color);
    opacity: 0.2;
    content: "";
    position: absolute;
    right: 0px;
    width: 100%;
    height: 1px;
    border-right: 0px;
    bottom: -18px;
  }

  .span-blocks
    span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
    display: block;
    width: 100%;
  }
}

/****=====media Ipad View ====***/

@media (max-width: 1024px) {
  /***===top-header===**/
  .notenetic-text {
    font-size: 21px;
    line-height: 31px;
  }

  .content- input {
    max-width: 260px !important;
    width: 260px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  /**==inner-heaer-dashbaord-menu==***/
  .menu-header-items span.k-link.k-menu-link span.k-menu-link-text {
    padding: 2px 8px;
  }

  .menu-header-items span.k-link.k-menu-link {
    font-size: 13px;
    line-height: 16px;
  }

  .dt-background {
    transform: translate(-96px, 86px);
  }

  .inner-composer {
    padding: 15px 20px;
    height: 500px;
    overflow-y: auto;
  }

  .tablet-view {
    position: absolute;
    right: 22px;
  }

  /* .tabletprofile-view {
    padding-top: 43px;
  } */
}

/***====Mobile view Media====***/

@media (max-width: 767px) {
  /***===top-header===**/
  .top-header .k-appbar-section.container.notentic-container {
    margin-top: -24px;
  }

  .top-header .k-appbar-light {
    height: 116px;
    position: relative;
    padding-top: 1px;
  }

  /**filter**/
  .filter.d-flex {
    flex-wrap: wrap;
  }

  .content-search-filter {
    width: 100%;
  }

  .content-search-filter input.icon-searchinput {
    min-width: 100% !important;
    width: 100% !important;
    margin-bottom: 10px;
  }

  .lable-details-msg {
    grid-template-columns: auto;
    align-items: center;
    position: relative;
  }

  .lable-details-msg span {
    position: absolute;
    top: 3px;
    left: 4px;
  }

  .composer-scroll textarea.composer-text {
    width: 100%;
    max-height: 400px;
    border: 0px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 14px;
    resize: none;
    height: 100%;
    min-height: 500px;
  }

  .content-composer {
    position: absolute;
    right: 10px;
    bottom: 0px;
    background: var(--white);
    box-shadow: 0px 0px 6px var(--dropdown_shadow);
    border-radius: 4px;
  }

  .minimize-content {
    height: 7%;
    top: 3%;
  }

  div .search_input_bar {
    margin: 0px !important;
  }

  .top-header .search-icon {
    top: 9px;
  }

  .padding_zero {
    padding: 0 !important;
  }

  .side-bar-left ul.k-drawer-items li {
    font-size: 12px;
  }

  #headingOne button.btn,
  #headingClient button.btn {
    font-size: 15px !important;
  }

  .marginResponsive {
    margin-left: 35px;
  }
}

.k-dialog-titlebar {
  border-color: inherit;
  color: var(--black) !important;
  background-color: var(--wrap_thread) !important;
}

@media (max-width: 1500px) and (min-width: 1200px) {
  .colum-medin-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  header.inner-header .navbar-light .navbar-nav li,
  .more-btn-drop a.dropdown-toggle {
    margin-right: 0px;
    margin-left: 0px;
  }

  header.inner-header .navbar-light .navbar-nav .nav-link {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .more-btn-drop .drop-downlist {
    max-width: 380px;
    width: 380px;
    transform: translate(-43%, 53px) !important;
  }

  .navbar-light .navbar-toggler {
    border: 0px;
    margin: 5px 0px;
  }
}

@media (max-width: 992px) {
  header.inner-header .navbar-light .navbar-nav li,
  .more-btn-drop a.dropdown-toggle {
    margin-bottom: 12px;
  }

  header.inner-header .navbar-toggler:not(.collapsed)::after {
    content: "\f0c9";
    transform: rotate(-180deg);
    font-family: "fontawesome";
  }

  header.inner-header .navbar-toggler.collapsed::after {
    content: "\f0c9";
    font-family: "fontawesome";
  }

  header.inner-header .navbar-toggler::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "\f00d";
    font-family: "fontawesome";
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
    font-size: 28px;
    padding: 0;
    position: relative;
  }

  button:focus:not(:focus-visible) {
    outline: 0px;
    box-shadow: unset;
    border: 0px;
  }

  .menu-header-items ul {
    padding-top: 20px;
  }

  .custome-nav {
    background-color: #fff;
    margin-left: -15px;
  }
}

@media (max-width: 767px) {
  .top-header-menu .logo-link h3 {
    font-size: 18px;
  }

  .menu-header-items {
    top: 42px !important;
  }

  header.inner-header button.navbar-toggler,
  header.inner-header .navbar-toggler:focus {
    box-shadow: unset;
    border: 0px;
    padding: 0;
  }

  .more-btn-drop .drop-downlist {
    max-width: 280px;
    width: 280px;
    transform: translate(-43%, 53px) !important;
    height: 230px;
    overflow-y: scroll;
    padding: 10px 10px 0 14px;
  }

  header.inner-header .navbar-light .navbar-nav .nav-link {
    display: inline-block;
  }

  .menu-header-items {
    margin-bottom: 20px;
  }

  header.inner-header nav.navbar {
    top: 6px;
  }

  .nav-shadow {
    height: 40px;
  }

  .month-number.text-end {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .attachemnt {
    width: 100%;
    margin-left: auto;
  }

  .custome-nav {
    height: 100vh;
  }
}

@media (max-width: 600px) {
  .site-add-client .k-window-content.k-dialog-content {
    max-width: 90%;
    width: 90%;
    padding: 30px;
  }

  .content-composer.minimize-content {
    width: 100%;
    max-width: 85%;
    left: 4px;
    right: 4px;
    top: 80px;
    height: 50%;
  }

  .maximize-content-composer {
    margin: 0;
    top: 80px;
  }
}

@media (max-width: 991px) {
  .grid-upper-head {
    grid-template-columns: auto;
  }
}

@media (max-width: 560px) {
  header.inner-header nav.navbar {
    padding: 0;
  }

  .topbar-items
    span.k-autocomplete.k-input.search_input_bar.k-input-md.k-rounded-md.k-input-solid {
    width: 90% !important;
  }
}

.grid-view {
  display: grid;
  grid-template-columns: 40px auto;
  gap: 10px;
}

.active-menu-item {
  background: #ffebe7 !important;
  border-radius: 3px;
  font-weight: 700 !important;
  color: #000;
}

.filter_popupo {
  padding: 15px;
  position: absolute;
  z-index: 3;
  background: var(--white);
  border-radius: 13px;
  box-shadow: 0px 0px 3px #dfd9d9;
  left: 9px;
}

.filter_popupo .k-dateinput:before {
  color: var(--blue-primary);
  opacity: 1;
  display: block;
  font-family: "Font Awesome 6 Free";
  content: "\f073";
  width: 20px;
  height: 20px;
  border-width: thin;
  position: absolute;
  left: 6px;
  z-index: 2;
  top: 14px;
}

.small-dailog .edit-client-popup ul {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.preview-btn-bottom {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f8fafa;
  padding: 10px 0;
  z-index: 1;
}

.input-common {
  background: var(--white) 0% 0% no-repeat padding-box;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 26px;
  min-width: 250px;
  height: 45px;
  font-size: 13px;
  padding-left: 17px;
}

.select-list ul {
  position: absolute;
  width: 230px;
  background: var(--white);
  top: 46px;
  left: 12px;
  box-shadow: 0px 0px 3px var(--activeColor);
  padding: 10px;
  border-radius: 4px;
}

.steps-line {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: var(--activeColor);
  margin-bottom: 45px;
}

.steps-line > .steps-lines-column {
  position: relative;
  display: table-cell;
  text-align: center;
  font-size: 0.875rem;
  color: var(--activeColor);
}

.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.02) !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.steps-line > .steps-lines-column:before {
  content: attr(data-step);
  display: block;
  margin: 0 auto;
  background: var(--white);
  border: 2px dotted var(--activeColor);
  color: var(--activeColor);
  width: 2rem;
  height: 2rem;
  text-align: center;
  line-height: 24px;
  border-radius: 100%;
  position: relative;
  z-index: 1;
  font-weight: 700;
  font-size: 1rem;
}

.steps-line > .steps-lines-column:after {
  content: "";
  position: absolute;
  display: block;
  border-bottom: 2px dotted var(--activeColor);
  width: 100%;
  height: 0.125rem;
  top: 1rem;
  left: 50%;
}

.steps-line > .steps-lines-column:last-child:after {
  display: none;
}

.steps-line > .steps-lines-column.is-complete-active:before {
  color: var(--white);
  background: var(--activeColor);
  border: 2px solid var(--activeColor);
}

.steps-line > .steps-lines-column.is-active-full:before {
  color: var(--activeColor);
  border: 2px dotted var(--activeColor);
  background: var(--white);
}

.setps-control {
  margin-top: 40px;
}

.field-common {
  box-shadow: rgb(188 190 191) 0px 0px 0px 1px !important;
  border-radius: 5px;
  border: unset;
  min-height: 40px;
}

.treament-text {
  border: 1px solid #70707021;
  border-radius: 4px;
  background: var(--white);
  padding: 10px 14px;
  display: grid;
  align-items: center;
}

.scroll-treatment {
  height: calc(100% - 80px);
  overflow-y: scroll;
}

.scroll-height-none {
  height: auto !important;
}

div .search_input_bar {
  background: var(--white) 0% 0% no-repeat padding-box;
  border-radius: 15px;
  min-width: 230px;
  height: 25px;
  min-height: 32px;
  padding-left: 31px;
}

.search_input_bar span.k-icon.k-i-x {
  top: -3px;
}

div .search_input_bar input {
  padding: 0px 28px 0px 1px !important;
}

.search_input_bar span.k-clear-value {
  right: 5px;
  top: 5px;
}

.side-bar-left ul.k-drawer-items li:nth-child(4) span {
  padding-left: 7px;
}

.side-bar-left ul.k-drawer-items li:before {
  display: none;
}

.side-bar-left ul.k-drawer-items li.k-selected {
  border-left: 0px;
}

.side-bar-left .k-drawer-wrapper {
  width: auto !important;
}

.side-bar-left .menu-drawer-layer ul.k-drawer-items li {
  position: relative;
  border-right: 0px;
  margin-bottom: 7px !important;
  padding: 6px 10px 6px;
  transition: all 0.3s;
  border-radius: 4px;
}

.side-bar-left .menu-drawer-layer ul.k-drawer-items li:last-child:hover {
  background-color: var(--hover-bg);
}

.dropdown-menu-t .dropdown-item {
  display: inline-block;
  width: 100%;
  border-radius: 1px;
}

ul .common_prof_list {
  list-style: none;
}

.signImage {
  max-width: 300px;
  height: 120px;
  width: 100%;
  object-fit: contain;
}

.error-message {
  margin: 0 auto;
  display: block;
  text-align: center;
  color: #f00;
  font-style: italic;
}

.icon-time .k-i-clock {
  color: var(--blue-primary);
}

.common-prof-dt li {
  color: var(--grey_text);
  font-size: 14px;
}

div .edit-size-pencil {
  font-size: 11px;
}

.view-box p {
  font-size: 13px;
  line-height: 16px;
}

/*******staff setting page******/
.setting-tabs-staff ul .k-item.k-active {
  color: #e7e7e7 !important;
  font-weight: 600 !important;
  background-color: #0d2d60 !important;
}

.setting-tabs-staff ul .k-item {
  color: var(--black);
  font-weight: 600;
  transition: all 250ms ease;
  position: relative;
  padding: 6px 10px;
  font-size: 14px;
  z-index: 1;
  border: 1px solid var(--borderColor);
}

.update-small {
  max-width: 130px;
}

.general-tabs .k-animation-container.k-animation-container-relative {
  display: inherit;
}

/*******a4 size page****/
.a4-size-page {
  border-left: 2px dotted #989595;
  padding: 13px;
  border-right: 2px dotted #989595;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 0px 6px 0 rgb(0 0 0 / 12%) !important;
}

.k-icon.k-i-edit {
  cursor: pointer;
}

.plus-click i {
  background: var(--grid_header);
  border-radius: 4px;
  font-size: 10px;
  padding: 3px;
  color: var(--white);
  cursor: pointer;
}

.small-dailog .small-edit-client::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background-color: var(--pdf-color);
}

.small-dailog .small-edit-client::-webkit-scrollbar {
  width: 12px;
  background-color: var(--pdf-color);
}

.small-dailog .small-edit-client::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
  background-color: var(--white);
}

div .k-expander-title {
  text-transform: revert;
  font-size: 14px;
}

.logopdf-logo {
  text-align: center;
}

.upload-logo-clinic img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 100%;
  cursor: pointer;
}

.current-popup span.k-input-inner {
  border: 0px;
  height: auto;
}

.icontimer span.k-button-icon.k-icon.k-i-clock {
  color: var(--blue-primary);
}

.tabs-kendoselect .k-animation-container {
  width: 100%;
}

/***new css **/
span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid,
.edit-kendo-dropdown
  span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
  background: transparent;
  height: 40px;
}

.k-widget.k-grid
  span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
  height: auto;
}

.k-widget.k-grid {
  right: 5px;
  top: 4px;
}

span.k-colorpicker span.k-button-icon.k-icon.k-svg-i-caret-alt-down {
  opacity: 0;
}

.service-main
  span.k-dropdownlist.k-picker
  button.k-input-button.k-button.k-icon-button.k-button-md.k-button-solid.k-button-solid-base {
  left: 380px;
}

.k-dropdownlist .k-input-button > .k-button-icon.k-svg-i-caret-alt-down {
  right: 12px;
  top: 5px;
}

.ddl-arrow-icon .k-button-icon.k-svg-i-caret-alt-down {
  padding-bottom: 12px;
}

.ddl-arrow-icon-2 .k-button-icon.k-svg-i-caret-alt-down {
  padding-bottom: 19px;
}

.z-index-10 {
  z-index: 10 !important;
}

.a4-size-page
  .k-dropdownlist
  .k-input-button
  > .k-button-icon.k-svg-i-caret-alt-down {
  right: 12px;
  top: 0px;
}

.clinic-logodetail {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  background: var(--blue-primary);
  top: 0;
  bottom: 0;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  color: var(--white);
  border-radius: 100%;
  display: inline-block;
  line-height: 26px;
  transform: translate(0px, 50px);
  cursor: pointer;
}

.input-control-up {
  height: 20px !important;
  width: 70px !important;
  box-shadow: rgb(188 190 191) 0px 0px 0px 1px !important;
  border: 0px;
  border-radius: 4px;
}

.input-control-up:focus {
  border: 1px solid var(--activeColor) !important;
  box-shadow: unset !important;
}

/***pdf *****/
.grid-template-pdf {
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: var(--pdf-grid);
  padding: 20px 30px;
}

.new-grid-temp {
  padding: 20px 30px !important;
}

.new-grid-template-pdf {
  background-color: #d3d3d3;
}

.grid-template-pdf {
  display: grid;
  grid-template-columns: 90px auto;
  gap: 10px;
}

.grid-template-pdf-head {
  grid-template-columns: 250px 400px;
}

.user-pdf-img img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 100%;
  border: 1px solid var(--black);
}

.tags-list {
  display: flex;
  flex-wrap: nowrap;
}

.tags-list li,
.list-icons-pdf li {
  font-size: 10px;
  margin: 5px;
}

.list-icons-pdf {
  display: flex;
  flex-wrap: wrap;
}

.new-list-icons-pdf {
  display: block;
}

.new-list-icons-pdf li {
  font-size: 10px;
  margin: 5px;
  margin-left: 2px !important;
}

.details-pdf-dt {
  padding: 3px 20px;
  width: 780px !important;
}

.detailPDF {
  width: 100% !important;
  padding: 3px 20px;
}

.treament-pdf-line {
  padding: 30px 30px 30px 30px;
}

.details-grid li {
  display: grid;
  grid-template-columns: 100px auto;
  gap: 10px;
}

.details-grid li p {
  word-break: break-word;
  margin-bottom: 13px;
}

.dotts-list li {
  position: relative;
  margin-right: 20px !important;
}

.dotts-list li:before {
  content: "";
  background: #424242;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  position: absolute;
  left: -20px;
  top: 7px;
}

.rightline-border:before {
  content: "";
  border-right: 1px solid var(--borderColor);
  position: absolute;
  right: 0px;
  height: 100%;
  top: 0px;
}

.blue-style {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--pdf-color);
  display: inline-block;
  position: relative;
}

.blue-style:before {
  content: "";
  background: linear-gradient(
    90deg,
    6 0%,
    var(--pdf-color) rgba(99, 91, 246, 0) 94.96%
  );
  border-radius: 1px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 2px;
}

.chip-list-skill #chip {
  padding: 0px !important;
}

.chip-list-skill #chip .k-chip-label {
  padding: 4px !important;
}

span.k-colorpicker.k-picker.k-icon-picker.k-picker-md button {
  top: 0px !important;
  left: 0px !important;
  width: 100%;
}

.lable-style {
  font-weight: 500 !important;
}

.preview-section p {
  font-size: 14px;
}

.k-button-icon.k-icon.k-i-clock {
  color: var(--activeColor);
}

.preview-section .k-checkbox-label {
  padding-left: 6px !important;
}

.k-radio-label {
  padding-left: 6px !important;
}

/* view documents */
.view-teplate-side {
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 16%);
}

.view-teplate-side ul li {
  background-color: #fff;
}

.outer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.inner {
  height: 150px;
  width: 100%;
  margin: 10px;
  border-radius: 4px;
  background-color: var(--white);
  box-shadow: 0px 0px 2px grey;
  text-align: center;
}

.template-table-cus table {
  border-collapse: collapse;
  width: 100%;
}

.template-table-cus td,
.template-table-cus th {
  border: 1px solid var(--border-bottom-line);
  text-align: left;
  padding: 6px;
  font-size: 14px;
}

.template-table-cus tr:nth-child(even) {
  background-color: #ecebfc;
}

.list-styled li {
  list-style-type: none;
  border: 1px solid #dcdfe1;
  padding: 3px 10px;
  margin: 8px;
  border-radius: 4px;
  text-transform: capitalize;
  cursor: pointer;
}

.sign-field {
  border: 1px solid #ced4da;
  border-radius: 3px;
  height: 104px;
}

.a4-size-page-view {
  padding: 15px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 0px 6px 0 rgb(0 0 0 / 12%) !important;
  background-color: #ffff;
}

.list-styled li.active,
.list-styled li:hover {
  color: var(--blue-primary);
}

.header-site-cus {
  display: flex;
  margin-left: auto;
  justify-content: center;
  position: relative;
  margin: 0 10px;
}

.list-styled li a {
  font-size: 14px;
}

.view-teplate-side h4 {
  margin-left: 10px;
}

.arrow-rotate {
  margin-left: 12px;
}

/* dropdown css feb */
.header-site-cus .k-input-button > .k-button-icon.k-svg-i-caret-alt-down {
  right: 4px;
  top: -6px;
}

.clock .k-input-button > .k-button-icon.k-svg-i-caret-alt-down {
  right: 20px;
  position: absolute;
  top: 7px;
}

.pagination-row-cus
  .k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
  margin: 0 10px 0 0px;
}

.k-time-cancel {
  background-color: var(--grey_icons) !important;
}

.k-time-accept {
  background-color: var(--blue-primary) !important;
}

.cross-icon-color {
  color: var(--custom_tooltip);
}

.search-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
  gap: 0px;
}

.document-pdf-img img {
  width: 240px;
  height: 140px;
  border: 1px solid var(--borderColor);
}

.document-details-grid li {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 10px;
}

.document-details-grid li p {
  word-break: break-word;
  margin-bottom: 13px;
}

.placelist li {
  list-style-type: none;
  color: #484343;
  font-weight: 500;
}

.treatment-pdf {
  border: 1px solid #cfd2fc;
  padding: 5px 20px;
}

.document-sec h6 {
  font-weight: 600;
}

.document-sec span {
  font-weight: 500 !important;
}

.document-user-cover {
  background: var(--pdf-color);
  height: 50px;
  padding: 15px 0;
  margin-bottom: 65px;
}

.document-user-cover .user-pdf {
  width: 100px;
  height: 50px;
}

.treatment-pdf p {
  font-weight: 500;
}

.history-title {
  font-size: 22px;
  font-weight: 5ssss00;
}

.document-details-grid p {
  font-size: 10px !important;
  font-weight: 600;
}

.document-user-cover p {
  font-size: 17px !important;
}

.signature-col {
  display: flex;
  margin-left: auto;
  justify-content: right;
}

.diagnosis-title {
  font-size: 10px !important;
}

.pencil-tool-align {
  display: inline-flex;
}

.diagnosis-pdf tr {
  font-size: 10px;
  font-weight: 600;
}

.diagnosis-pdf {
  height: fit-content;
}

.system-template-cus {
  margin-left: auto;
  margin-right: auto;
}

.system-template-cus label {
  font-size: 10px;
}

.system-template-cus input {
  font-size: 10px;
  width: 10px;
  height: 10px;
}

.system-template-cus b {
  font-size: 10px !important;
}

.system-template-cus h4 {
  font-size: 10px !important;
}

.main-pdf {
  padding: 20px;
  margin: 0px auto;
  font-size: 10px !important;
}

.fw-boldest {
  font-weight: 600 !important;
}

.frequency p {
  font-size: 11px;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
  margin-right: 9px;
}

.frequency input {
  width: 50px !important;
  margin-top: 0px !important;
}

.frequency .frequency-drop {
  width: 100px !important;
  margin-top: 0px !important;
}

.top-header {
  position: fixed;
  width: 100%;
  z-index: 999;
  background: var(--white);
}

.menu-header-items {
  position: fixed;
  top: 52px !important;
  width: 100%;
  z-index: 9;
  background: var(--white);
  box-shadow: 0 0 4px rgba(223, 229, 226, 0.1);
  border-top: 1px solid rgba(223, 229, 226, 0.1);
  border-bottom: 1px solid rgba(223, 229, 226, 0.1);
}

.top-header-menu {
  box-shadow: 0 0 4px rgba(223, 229, 226, 0.1);
}

.inner-content-bottom {
  padding-top: 130px;
}

@media (max-width: 991px) {
  .more-btn-drop .dropdown-menu-t {
    transform: translate(56%, -77px);
  }

  td,
  th {
    width: 200px;
  }
}

@media (max-width: 767px) {
  .default-head-btn {
    display: none !important;
  }

  .md-justify-content-start {
    justify-content: start !important;
  }

  .px-1.switch-on.mx-auto {
    margin-left: 0 !important;
  }
}

@media (max-width: 567px) {
  div .search_input_bar {
    min-width: 145px;
  }
}

@media (max-width: 460px) {
  .searchicons .search_input_bar {
    min-width: 80px;
  }
}

.major-filter {
  display: flex;
  flex-wrap: wrap;
}

.major-filter .apply-search-btn button {
  background: var(--blue-primary);
  border: 0;
  color: var(--white);
  padding: 5px 30px;
  border-radius: 4px;
  font-size: 16px;
  width: auto;
}

.major-filter .dropdown-menu.show {
  background-color: var(--white);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  padding: 18px;
  border-radius: 8px;
  width: 420px;
  transform: translate3d(10px, 31px, 0px) !important;
}

.k-radio {
  border-color: #939393 !important;
  background-color: var(--white);
}

.major-filter .border-dashed-cus {
  border: 1px dashed var(--grey_icons);
  border-radius: 30px;
  width: auto;
  margin-right: 15px;
  font-size: 13px;
  color: var(--grey_icons);
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 32px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.border-dashed-cus2 {
  border: 1px dashed var(--grey_icons);
  border-radius: 30px;
  width: auto;
  margin-right: 15px;
  font-size: 13px;
  color: var(--grey_icons);
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 32px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn-size-cus {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: var(--grey_icons) !important;
}

.major-filter p {
  font-size: 14px;
  color: var(--grey_icons);
  font-weight: 600;
}

.cross-icon {
  background: var(--grey_icons);
  border-radius: 50px;
  color: var(--white);
  padding: 4px;
  text-align: center;
  width: 18px;
  height: 18px;
  font-size: 10px;
  margin: 5px;
}

.major-filter button:focus {
  box-shadow: none;
}

.border-spann:before {
  content: "";
  border-left: 1px solid #8a8282;
  margin: 0px 9px;
}

/* delete after theme chnage - border-spann  */
.border-spann {
  color: var(--bg_icons);
}

.major-filter .email-filter input:focus {
  box-shadow: none !important;
  border: none !important;
}

.major-filter input:focus {
  border: 1px solid var(--activeColor) !important;
}

.major-filter button:hover {
  color: var(--grey_icons);
}

.clear-add-filter p {
  font-size: 14px;
  font-weight: 500;
  color: var(--grey_icons);
  transition: all 0.3s ease-in;
}
.clear-add-filter p:hover {
  color: #000;
}

.major-filter span.k-input-inner {
  border: none !important;
}

#sidefiter {
  min-height: 100%;
  position: fixed;
  transition: width 0.14s ease-out;
  height: 100vh;
  box-shadow: 0px 10px 10px var(--footer_sidebar);
  background: var(--white);
  z-index: 999;
  padding: 15px;
  top: 97px;
  left: 0;
  overflow-y: scroll;
}

#sidefiter h5 {
  font-size: 14px;
}

.add-filter-cus,
.fiter-icon {
  color: #021f53 !important;
}
.add-filter-cus:hover {
  transition: all 0.3s ease-in;
  text-decoration: underline;
}
/* sidebar filter */
.opensidebar {
  width: 400px !important;
  background: var(--red);
  display: block;
}

.closedsidebar {
  display: none;
  width: 0px;
}

.hr-spacing {
  margin: 2px 0 !important;
}

.documnet-inner-content {
  margin-left: 388px;
  transition: margin-left 0.5s;
}

.documnet-id-filter-btn a {
  background: var(--blue-primary);
  border: 0;
  color: var(--white) !important;
  padding: 8px 18px;
  border-radius: 4px;
  font-size: 16px;
  width: auto;
}

.temp-heading {
  font-size: 10px !important;
}

@media (max-width: 800px) {
  .major-filter {
    width: 100%;
    flex-wrap: wrap;
  }

  .major-filter .dropdown {
    margin-bottom: 0;
    margin-right: 0;
  }

  .major-filter {
    margin-top: 10px;
  }
}

/* dashboard css */
.dash-main-heading h1 {
  letter-spacing: 0.1px;
  color: #171725;
  opacity: 1;
  font-weight: bold;
  font-size: 24px;
}

.month-year-btn {
  display: flex;
  justify-content: end;
}

.welcome-text h3 {
  color: var(--black);
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.top-border-dash {
  border: 1px solid #f2f2f6;
  border-radius: 4px;
  padding: 7px 16px;
}

.graph-chart {
  border-radius: 4px;
}

.doctor-text {
  border: 1px solid #021f54;
  padding: 11px 0;
  transition: all 0.3s ease-in;
}
.doctor-text:hover {
  transform: scale(1.02);
  background-color: #021f54de;
}

.clear-add-filter p {
  cursor: pointer;
}

.doctor-text p {
  color: var(--white) !important;
  letter-spacing: 0.11px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

.doctor-text span {
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
}

.dropdown-service {
  background: var(--white);
  position: absolute;
  top: 38px;
  z-index: 99 !important;
  padding: 18px;
  border-radius: 8px;
  width: 420px;
  padding: 10px;
  margin: auto;
  left: 0;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.dropdown-service.clientServieDropdown {
  width: 250px;
}

.dropdown-service {
  display: block !important;
}

.sign-border-profile {
  width: 316px !important;
}

.widget-box-cus-profile .k-expander.k-expanded {
  min-height: 280px !important;
}

.auth-drop-error {
  position: relative;
}

.auth-drop-error .k-form-error.k-text-start {
  position: absolute;
}

.box-dashbopard-filter {
  padding: 10px;
}

.dash-filter-grid {
  border: 1px solid var(--plus_bg);
  border-radius: 5px;
  overflow-x: hidden;
  background-color: var(--white);
  box-shadow: 1px 0 23px rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  min-height: 300px;
  max-height: 300px;
}

.dash-filter-grid-bill {
  border: 1px solid var(--plus_bg);
  border-radius: 5px;
  overflow-x: hidden;
  background-color: var(--white);
  box-shadow: 1px 0 23px rgba(0, 0, 0, 0.1);
}

.popup-content {
  padding: 30px;
  color: #787878;
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.status-report {
  margin-left: 0px !important;
  padding-left: 1px !important;
}

.box-dashbopard-filter
  .k-input-button.k-button.k-icon-button.k-button-md.k-button-solid.k-button-solid-base {
  top: 5px !important;
}

.dropdown-service
  button.k-button.k-button-md.k-button-rectangle.k-button-solid.k-button-solid-base.k-icon-button.k-input-button {
  top: 5px !important;
}

.email-filter .k-label {
  font-size: 14px !important;
  color: var(--grey_icons) !important;
  font-weight: 600 !important;
}

.email-filter span.k-label {
  padding-left: 10px;
}

.error-page h3 {
  font-size: 23px;
}

.error-page {
  height: 80vh;
}

.error-page::-webkit-scrollbar {
  display: none;
}

.k-dateinput .k-input .k-required .k-focus {
  transform: translate(200px, 0) !important;
}

.task-modal-main .k-widget.k-window.k-dialog {
  max-width: 700px;
  width: 100%;
  height: 100%;
  max-height: 650px;
}

.scroll-discussion {
  height: 169px;
  overflow-y: scroll;
}

.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: var(--black);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: var(--borderColor);
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media (max-width: 800px) and (min-width: 1450px) {
  .task-modal-main .k-widget.k-window.k-dialog {
    max-height: 551px !important;
  }

  .attachemnt {
    width: auto;
  }
}

.check-delete-cus {
  margin-left: 40px;
}

.add_services-cus {
  position: relative;
  z-index: 1;
}

.topbar-items
  span.k-autocomplete.k-input.search_input_bar.k-input-md.k-rounded-md.k-input-solid {
  width: calc(100% - 100px) !important;
  border-radius: 4px !important;
}

button.k-input-button.k-button.k-icon-button.k-button-md.k-button-solid.k-button-solid-base:hover {
  background-color: transparent !important;
}

.right-align-docs {
  text-align: end;
}

.right-align-docs .k-button-md {
  padding: 4px 4px !important;
}

@media (max-width: 340px) {
  .major-filter .border-dashed-cus {
    padding: 0px 7px;
  }
}

@media (max-width: 960px) {
  .dropdown-service {
    width: 250px;
    margin-left: 0%;
  }

  .major-filter .dropdown-menu.show {
    width: auto;
  }

  .right-align-docs {
    text-align: start;
  }
}

.dash-filter-grid .k-listview-header {
  font-size: 18px !important;
  color: #494747 !important;
  font-weight: 500;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 9;
}

.text-set {
  width: 300px !important;
  word-wrap: break-word;
}

span.text-overlap-cus {
  word-break: break-word;
  display: flex;
  align-items: center;
}

.caseload-custom .k-listview-content,
.clientsites-cus .k-listview-content {
  overflow-y: scroll;
  height: 300px;
}

.clients-modal-dash .k-dialog-buttongroup {
  padding: 0px 0px !important;
}

.upload-sign-file .k-expander-header {
  border: 1px solid var(--header-grid);
}

.treatment_upload .k-expander.k-expanded {
  border: 1px solid var(--header-grid);
}

.main-goal .k-expander-header {
  background: #dde1e6 !important;
  position: relative;
}

.main-inter .k-expander-header {
  background: #dde1e6 !important;
}

.green_bg {
  border-color: #1bb11b !important;
  color: #1bb11b !important;
}

.color_change_docs.color_change_docs .green_bg:hover {
  background-color: #1bb11b !important;
  color: var(--white) !important;
}

.red_bg {
  border-color: var(--red) !important;
  color: var(--red) !important;
}

.color_change_docs.color_change_docs .red_bg:hover {
  background-color: var(--red) !important;
  color: var(--white) !important;
}

.clients-modal-dash .k-dialog-buttongroup {
  max-width: 100%;
}

.color_change_docs {
  border: 1px dashed #dcdfe1;
  padding: 10px;
  margin: 8px !important;
  border-radius: 4px;
  height: 100%;
}

.dynamic_text_approve {
  font-weight: 600 !important;
}

.elig_text {
  font-size: 13px;
  color: var(--grid_header);
}

.border-elig {
  border: 1px solid var(--plus_bg);
  border-radius: 3px;
  padding: 13px 20px;
}

.elig_value_text h4 {
  font-size: 14px;
  margin-bottom: 10px;
}

.elig_value h5 {
  font-size: 14px;
  margin-bottom: 10px;
}

.total_phq {
  display: flex;
  justify-content: end;
  align-items: center;
}

.submit_phq {
  background: #e9e7ff;
  padding: 10px 25px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  margin-left: 10px;
}

.select_table_phq select {
  padding: 4px 20px !important;
  border: 1px solid #c4bcbc !important;
  border-radius: 4px !important;
  font-weight: 400;
  font-size: 13px;
}

.select-table-drops {
  text-align: start !important;
}

.cus-select-table-drops {
  text-align: start !important;
}

.cats_label {
  display: inline-flex;
  align-items: center;
  margin-right: 23px;
  gap: 5px;
}

.input_box_cats {
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 0px;
}

.plans_text {
  color: var(--title_Color) !important;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.day_event {
  display: flex;
  justify-content: end;
}

.dropdown-service .k-calendar-header.k-hstack {
  z-index: 99999;
  position: relative;
}

.dropdown-service {
  position: absolute;
  z-index: 99;
}

.data-no {
  margin-right: 23px;
}

.flex-text {
  display: flex;
  column-gap: 35px;
}

.new-flex-text {
  display: flex;
  column-gap: 22px;
  justify-content: start;
  align-items: center;
}

.strongly_labels {
  gap: 45px;
}

.custom-flex-text {
  column-gap: 18px;
  justify-content: start !important;
}

.new-select-table-drops {
  text-align: start !important;
}

.strongly-disagree label {
  max-width: 120px;
}

.strongly-agree {
  margin-left: 0px;
}

.strongly-agree label {
  max-width: 120px;
}

.not-sure {
  text-align: center;
  padding-top: 0px;
}

.not-sure label {
  max-width: 120px;
}

.profiles-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.new-color-change {
  background-color: #edeeee !important;
}

.tables_question .k-widget.k-window.k-dialog {
  width: 800px;
  height: 600px;
}

.dropdown-service .k-calendar-header.k-hstack {
  z-index: 2;
  position: relative;
}

.dropdown-service {
  position: absolute;
  z-index: 1;
}

.filter-left-align {
  margin-left: -340px;
}

.dynamic_approve_btn {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}

.img_tp {
  width: 19px;
  height: 20px;
  object-fit: contain;
  margin-right: 10px;
}

.rebill_input {
  margin-top: 0px !important;
}

.assign_border {
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  opacity: 1;
  min-height: 30px;
  width: 100%;
  color: var(--title_Color);
  align-items: center;
  display: flex;
  padding-left: 5px;
}

.more-btn-drop .drop-downlist-billing {
  max-width: auto;
  width: auto;
  height: auto;
  background: var(--white);
  box-shadow: 0 2px 28px rgba(0, 0, 0, 0.3);
  opacity: 1;
  margin-top: 13px;
  border: 0px;
  padding: 10px;
}

.commented_para {
  font-size: 10px !important;
  margin-left: 3px;
}

.comment_heading {
  font-size: 14px !important;
  margin-top: 8px;
}

/* 16-may-23 */
.auth_drop_service {
  z-index: 99;
}

.fixed_bottom {
  padding-top: 20px;
}

.k-loading-color {
  color: #008000 !important;
}

.k-loading-color .k-loading-image .k-loading-mask {
  color: var(--red);
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}

.video-cell {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content-wrapper,
.content-wrapper img {
  width: 750px;
  height: 250px;
}

.example {
  display: flex;
  justify-content: center;
}

.toolbar-tool {
  display: flex;
  gap: 8px;
  align-items: center;
}

.k-signature,
.notes {
  border-color: var(--grid_border);
  border-width: 0 1px;
  border-radius: 0;
}

.k-upload .k-dropzone {
  padding: 0;
}

.signature-wrapper {
  height: 270px;
}

.signature-wrapper .k-signature {
  width: 100%;
  height: 100%;
}

.signature-wrapper img {
  user-select: none;
}

.placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.notes {
  font-style: italic;
  border-width: 1px;
  border-bottom-width: 0;
  border-style: solid;
  padding: 1em;
}

/* lg_modal for table & medium width*/

.lg_modal.modal-box .k-window-content {
  display: none;
}

.lg_modal .k-window-actions {
  position: relative;
}

.lg_modal .k-window.k-dialog {
  position: relative;
  overflow-y: scroll;
}

.lg_modal .k-window-actions::-webkit-scrollbar,
.lg_modal .k-window-content.k-dialog-content::-webkit-scrollbar {
  display: none;
  height: 10px;
}
.lg_modal .k-window-content,
.k-prompt-container {
  padding-block: 0 !important;
  padding-inline: 0 !important;
}

/* lg_modal end*/

/* xs-modal like delet modal */
.xs-modal .k-dialog-wrapper.k-dialog {
  height: 110px;
  position: relative;
  background: var(--white);
}

.xs-modal .k-window-actions {
  height: auto;
  padding: 10px;
  background-color: #fff;
  margin: 0 0 !important;
  margin-inline-end: calc(12px - 16px) !important;
}

.xs-modal .k-dialog-content p {
  margin: 8px !important;
}

.k-rating-container .k-rating-item,
.k-svg-icon.k-svg-i-star-outline svg {
  width: 32px !important;
  height: 32px !important;
}

.xs-modal .k-window-content {
  background-color: var(--white) !important;
  z-index: -1;
  height: 100%;
  min-height: 50px;
  max-height: max-content;
}

.width-given.xs-modal .k-window-content {
  width: 350px;
  height: 125px;
}

/* md-modal start */
.med-modal .k-dialog-wrapper .k-dialog {
  position: relative;
  height: 450px !important;
}

.k-window-content:last-child {
  padding-bottom: 0 !important;
}

.med-modal .k-window-content {
  display: block !important;
}

/* xl-modal for form-field */
.xl-modal .k-window.k-dialog {
  display: block;
  max-height: 90%;
  height: auto;
  width: 70%;
  overflow-y: auto;
  scrollbar-width: thin;
}

.scrollwidth::-webkit-scrollbar {
  width: auto;
}

.xl-modal.scrollwidth .k-window.k-dialog {
  scrollbar-width: auto;
}

.xl-modal .popup-modal {
  margin-top: 5px;
}

.xl-modal .preview-btn-bottom {
  background-color: var(--white);
  z-index: 11;
}

.k-window.k-dialog .preview-btn-bottom {
  background-color: var(--white);
  z-index: 11;
}

.medium-modal .k-dialog-wrapper.k-dialog {
  position: relative;
  height: 450px !important;
  width: 26.5% !important;
  overflow-x: hidden;
}

.medium-modal .edit-client-popup {
  overflow-y: scroll;
  overflow-x: hidden;
}

.medium-modal .edit-client-popup::-webkit-scrollbar {
  display: none;
  height: 10px;
  overflow-x: hidden;
}

.medium-modal .edit-client-popup.scrollVisible::-webkit-scrollbar {
  display: block;
}

.medium-modal .edit-client-popup::-webkit-scrollbar {
  width: 5px;
  background-color: #aaa;
  height: 10px;
  overflow-x: hidden;
}

.medium-modal .k-window-content.k-dialog-content {
  height: 300px;
}

.k-input-button > .k-button-icon.k-svg-i-calendar svg {
  width: 15px !important;
  min-width: 15px !important;
}

.k-dialog-titlebar {
  position: sticky;
  top: 0;
  z-index: 11;
  background: #fff !important;
}

.k-floating-label-container .k-input-button > .k-button-icon {
  min-width: 17px !important;
}

.k-rating-container .k-rating-item * {
  pointer-events: visible !important;
}

.update-modal .Service-accept {
  height: 300px;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0 5px;
  width: 400px;
}

.fa.fa-pencil {
  margin-left: 8px;
}

.k-input-button > .k-svg-i-clock {
  position: absolute !important;
  left: 13px !important;
  top: 3px !important;
}

.date-modal .k-input-button > .k-button-icon.k-svg-i-calendar {
  left: 10px;
  top: -7px;
  position: absolute;
}

.date-modal .k-input-button > .k-button-icon.k-svg-i-caret-alt-down {
  top: -2px;
  position: absolute;
}

.date-modal .k-input-button > .k-svg-i-clock {
  left: 10px;
  top: -8px !important;
  position: absolute;
}

.zip-dash {
  width: 60% !important;
}

.zip-dash-100 {
  width: 100% !important;
}

.demo {
  background-color: var(--red) !important;
}

.k-input-button {
  background-color: transparent;
}

.border {
  border: 1px solid var(--border-bottom-line);
  border-radius: 5px;
}

.height-given {
  height: calc(100vh - 300px);
  position: relative;
  overflow: scroll;
}

.MuiTextField-root,
.css-1xnbq41-MuiAutocomplete-root,
.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  width: 100% !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: auto !important;
  padding: 8.5px !important;
}

.css-d1wk2y-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  top: -6px !important;
}

.k-input-md .k-input-button,
.k-input-md .k-spinner-increase,
.k-input-md .k-spinner-decrease,
.k-picker-md .k-input-button,
.k-picker-md .k-spinner-increase,
.k-picker-md .k-spinner-decrease {
  padding: 4px 4px;
  background: transparent !important;
}

.k-pager-md .k-pager-info,
.k-pager-sm .k-pager-info,
.k-pager-sm .k-pager-sizes,
.k-pager-sm .k-pager-numbers {
  display: block !important;
}

.task-modal-main .k-actions-stretched {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--white);
  z-index: 99;
}

.task-modal-mainn .k-actions-stretched {
  bottom: 0;
  width: 100%;
  z-index: 99;
}

.task-modal-main textarea.k-input-inner {
  margin: 0;
  min-height: 80px;
}

.background-color {
  background-color: var(--white);
  width: 100%;
  display: block;
}

.billing-bottom .elig_value_text h4 {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 10px;
  margin-left: 10px;
}

.billing-bottom .elig_value h5 {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 10px;
}

button.btn.btn-size-cus:active {
  border: none !important;
  box-shadow: none;
  outline: none;
}

button.btn.btn-size-cus {
  display: inline-flex;
  align-items: center;
}

.order-given .k-listbox-actions {
  order: 2;
  width: 30px;
}

.order-given .k-listbox-actions button {
  margin: 4px 4px;
}

.order-given .k-listbox-actions button:nth-child(odd) {
  background: transparent;
  border: 1px solid var(--green) !important;
  color: var(--green) !important;
  cursor: pointer;
  opacity: 1;
}
.order-given .k-listbox-actions button:nth-child(odd) svg {
  fill: var(--green) !important;
}

.order-given .k-listbox-actions button:nth-child(even) {
  background: transparent;
  border: 1px solid var(--red) !important;
  color: var(--red) !important;
  cursor: pointer;
  opacity: 1;
}
.order-given .k-listbox-actions button:nth-child(even) svg {
  color: var(--red) !important;
}
.edit-form-show
  .k-dropdownlist
  .k-input-button
  > .k-button-icon.k-svg-i-caret-alt-down,
.template
  .k-dropdownlist
  .k-input-button
  > .k-button-icon.k-svg-i-caret-alt-down {
  top: 0px;
}

.serve-modal .k-input-button > .k-button-icon.k-svg-i-caret-alt-down {
  top: -7px;
}

.width-input {
  width: 100% !important;
}

.text-right {
  text-align: right !important;
}

.medium-modal .preview-btn-bottom .grey-secondary {
  margin-left: 10px;
}

.blue-primary-outline.update-small {
  gap: 10px;
  display: flex;
  align-items: center;
}

.switch-on .switch-title-text.a4-size-page,
.head-column-row {
  margin-left: 10px;
}

.switch-title-text.text-deign {
  margin: 0 10px;
}

.date-timeshow .k-i-calendar-date {
  margin-right: 5px;
}

.view-teplate-side ul {
  padding-left: 0 !important;
}

.edit-client-popup .eye-close {
  top: 62px;
}

.text-left {
  text-align: left;
}

.clients-modal-dash .k-window-actions {
  padding: 0 !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

.add-dialog
  .k-dropdownlist
  .k-input-button
  > .k-button-icon.k-svg-i-caret-alt-down {
  top: 0;
}

.k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  padding: 4px 35px 4px 8px !important;
}

.k-input-md .k-input-inner {
  height: 41px;
}
.drop-icon-i
  .k-dropdownlist
  .k-input-button
  > .k-button-icon.k-svg-i-caret-alt-down {
  top: 0;
}

.bill_main
  .k-dropdownlist
  .k-input-button
  > .k-button-icon.k-svg-i-caret-alt-down {
  top: -2px;
}

.template-header {
  position: fixed;
  top: 0;
  width: 75.8vh;
  height: 40px;
  margin-bottom: 40px;
}

.template-footer {
  margin-top: 40px;
  position: fixed;
  bottom: 0;
  width: 75.8vh;
  height: 40px;
}

.template-contentwidth {
  margin: 28px 0 !important;
  padding-bottom: 60px !important;
  margin-bottom: 30px !important;
}

.form-group .given-width,
.form-group .given-width b,
.form-group b .form-group .file-pdf-document label b {
  font-size: 1.25rem !important;
}

.example-wrapper .col-md-8.col-lg-4.mx-auto {
  width: 500px !important;
}

.form-template-cus span,
.form-template-cus label,
.lable-style b {
  font-size: 14px !important;
}

.widget-width,
.a4-size-page-view {
  width: 800px !important;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 0px 6px 0 rgb(0 0 0 / 12%);
}

.a4-size-page {
  width: 56% !important;
}

.k-scheduler-edit-dialog .k-window.k-dialog {
  width: 60% !important;
}

.input-height .css-1uvydh2 {
  height: 7px !important;
}

.input-height .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  height: 40px !important;
}

.k-scheduler-edit-dialog .k-dialog-content {
  min-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
}

.staffDoc-refresh-button {
  top: 50%;
  right: 0px;
  z-index: 1;
}

.staffDoc-refresh-button-single-selection {
  top: 50%;
  right: 30px;
  z-index: 1;
}

.k-scheduler-edit-dialog .k-actions-start {
  position: sticky;
  z-index: 9999;
  background-color: var(--white);
  height: 70px;
  margin-left: 19px;
  margin-top: 5px;
  bottom: 0px;
}

.cursor-default ul {
  padding-left: 10px !important;
}

ul.details-info li.col-md-4 {
  padding: 0 !important;
}

.k-scheduler-edit-dialog .k-actions button.k-button {
  color: var(--white) !important;
  display: inline-flex !important;
}

.top-bar-show-list .k-input-button > .k-button-icon.k-svg-i-caret-alt-down {
  top: 6px;
}

.top-bar-staff-list .k-input-button > .k-button-icon.k-svg-i-caret-alt-down {
  top: 6px;
}

.start-0 {
  left: 0;
  text-align: left;
}

.border-radis {
  border-radius: 15px;
}

.shadowbox {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 0px 6px 0 rgb(0 0 0 / 12%) !important;
  background-color: #fff;
}

.k-event-delete .k-dialog-wrapper .k-window.k-dialog {
  height: 40% !important;
}

.login-wrapper {
  width: 500px;
  margin: auto;
}

/* navbar */
.nav-shadow {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 28px rgba(0, 0, 0, 0.06);
}

.input-radio li {
  padding: 4px 22px !important;
}

.width100 {
  width: 100% !important;
}

/* claim page */
.claim-header {
  background-color: var(--pdf-grid);
  display: flex;
  gap: 20px;
  padding: 11px 28px;
  border-radius: 4px;
  align-items: center;
}

.claim-heading {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.claim-header button {
  border: none;
  background-color: var(--white);
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
}

.green {
  color: var(--green);
}

.bgGrey {
  background-color: #f3f3f3;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  padding: 6px 12px;
}

.form-complete {
  position: relative;
}

.form-complete::before {
  position: absolute;
  content: "";
  width: 62%;
  height: 5px;
  background-color: var(--border-bottom-line);
  top: 23px;
  margin: auto;
}

.form-complete p {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  padding: 0px 0px;
  text-align: center;
  z-index: 1;
  margin: 0 !important;
  position: relative;
  z-index: 1;
  margin: auto !important;
  border: 5px solid var(--border-bottom-line);
  font-size: 28px;
  color: var(--green);
  background-color: var(--white);
}

.form-complete span {
  color: var(--border-bottom-line);
}

.form-complete span.active {
  color: var(--black);
}

.form-complete p.active {
  border-color: #47855b;
}

.member-header {
  color: #506b8d;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
}

.member-wrapper h6 {
  color: var(--lebel_2);
  font-weight: 600;
  font-size: 15px;
}

.member-wrapper p {
  color: var(--black);
  font-weight: 600;
  font-size: 15px;
}

.z-navbar {
  position: relative;
  z-index: 999;
}

.forgot-pop {
  width: 40%;
}

.table-heading-auth .k-grid th > .k-checkbox:first-child {
  margin: 0 8px !important;
}

.dashStaff {
  background-color: #0c2552;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  padding: 6px 0;
  border: 1px solid #fff;
}

.doctor-text {
  padding: 9px 16px;
  background-color: #021f54;
  border-radius: 4px;
  margin: 3px 0;
}

.fa-chevron-right {
  color: #fff;
  font-size: 16px;
  padding-bottom: 20px;
}
.nextPageIcon .fa-chevron-right {
  font-size: 13px;
}
.nextPageIcon:hover .fa-chevron-right {
  color: #021f54 !important;
}

.textcolor {
  background-color: #eff3f1;
  color: #07479a !important;
  padding: 5px 0;
  border-radius: 2px;
  padding: 3px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}
.hover_underline {
  transition: all 0.3s ease-in;
}
.textcolor:hover,
.k-menu-link-text:hover,
.hover_underline:hover {
  text-decoration: underline;
}

.noCursor .textcolor:hover {
  text-decoration: none;
}
.noCursor .textcolor {
  cursor: default;
}

.dash-filter-grid h5 {
  font-size: 16px;
  color: var(--label_heading);
  padding: 10px 20px;
  border-bottom: 1px solid var(--dashboardCol_border);
  margin: 0 !important;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.borderGeyDasboard h5 {
  border: 0;
}

.borderGeyDasboard {
  border-bottom: 1px solid var(--dashboardCol_border);
}

.dash-filter-grid-bill h5 {
  font-size: 16px;
  color: var(--label_heading);
  padding: 10px 20px;
  margin: 0 !important;
}

.dash-listing-cus .k-listview-content {
  height: 200px;
  overflow-y: auto;
}

.gridheight .k-listview-content {
  height: 261px;
  overflow-y: auto;
  min-height: 261px;
  font-size: 13px;
}

.gridheight .k-listview-bill-content {
  overflow-y: auto;
  min-height: 239px;
  max-height: max-content;
  overflow-y: hidden;
  font-size: 13px;
}

.gridheightLess .k-listview-content {
  overflow-y: auto;
  min-height: auto;
  font-size: 13px;
}

.k-listview-content::-webkit-scrollbar {
  width: 5px;
}

.cliniclogo {
  width: 100px;
  height: 100px;
}

.color-text {
  color: var(--color_text);
}

.gridheight .k-listview-content .border-bottom:last-child {
  border-bottom: none !important;
}

.k-table-md {
  font-size: 13px !important;
}

.fa-exclamation-triangle:before,
.fa-triangle-exclamation:before,
.fa-warning:before {
  background: var(--white) !important;
  font-size: 13px !important;
}

.dropIcon
  .k-dropdownlist
  .k-input-button
  > .k-button-icon.k-svg-i-caret-alt-down {
  top: 0;
}

.drop_icns.dropIcon
  .k-dropdownlist
  .k-input-button
  > .k-button-icon.k-svg-i-caret-alt-down {
  top: -6px;
}

.dropDates .k-svg-i-calendar svg {
  top: 10px;
  position: absolute;
}

.billingIcons .k-svg-i-calendar svg {
  top: 15px;
  position: absolute;
}

.caseload-custom.gridheight .k-listview-content {
  height: 250px;
  overflow-y: auto;
  min-height: 250px;
  font-size: 13px;
}

.caseload-custom.gridheight.casload .k-listview-content {
  height: 324px;
  overflow-y: auto;
  min-height: 250px;
  font-size: 13px;
}

.gridHeight {
  height: 250px;
  min-height: 250px;
}

.iconDrop svg {
  top: 10px;
  position: absolute;
}

.iconDrop {
  z-index: 99999 !important;
}

.benefits-start h4,
.payer-start h4 {
  color: #506b8d;
  font-size: 20px;
  font-weight: 700;
}

.client-accept h5,
.label-size {
  color: var(--lebel_2);
  font-weight: 600 !important;
  font-size: 15px;
}

.client-accept p,
.label-size p {
  color: var(--black);
  font-weight: 500;
  font-size: 15px;
  margin-top: 4px !important;
}

.gy-3 {
  row-gap: 15px;
}

.k-rounded-lg {
  border-radius: 4px !important;
}

.k-form-error,
.k-form-hint {
  font-style: normal !important;
}

.eyeIcons .edit-client-popup .eye-close {
  top: 40px;
}

.groupModals .k-window-content,
.k-prompt-container {
  max-height: 500px;
}

.orderBox .k-listbox {
  height: 300px !important;
}

.k-actions button.k-button {
  color: var(--white) !important;
  font-weight: 500;
}

.blue-greenVideo {
  background-color: rgb(40, 167, 69);
  color: var(--white);
  border: 1px solid rgb(40, 167, 69);
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
}

.blue-greenVideo:hover {
  background-color: rgb(40, 167, 69);
  color: var(--white);
}

.clip-padding {
  padding: 10px 15px;
}

.textAreas {
  margin: 0 -15px;
}

.k-pdf-export .preview-section {
  margin: 20px;
  font-size: 12px;
}

.k-pdf-export h6 {
  font-size: 12px;
}

.k-pdf-export .preview-section {
  margin: 0 20px !important;
  font-size: 12px;
  padding-right: 30px;
}

.k-pdf-export .signature {
  border-bottom: 1px solid var(--black);
  height: 40px;
  padding: 5px 10px;
}

.btn-secondary.sealBtn {
  background-color: var(--grey_icons) !important;
}

.textWRapper {
  white-space: unset !important;
  text-overflow: unset !important;
}

.inner-dt {
  background: var(--white);
  box-shadow: 8px 0 4px rgba(223, 229, 226, 0.1);
  border-top: 1px solid rgba(223, 229, 226, 0.1);
  border-right: 1px solid rgba(223, 229, 226, 0.1);
  border-radius: 10px;
  min-height: 1040px;
  height: 100%;
}

ul.drop-down-list::-webkit-scrollbar {
  display: none;
}

.labelColor {
  color: var(--lebel_2) !important;
  font-weight: 700 !important;
  font-size: 15px;
}

.valueColor {
  color: var(--black) !important;
  font-weight: 500;
  font-size: 15px !important;
  margin-top: 4px !important;
}

.font-12 {
  font-size: 13px !important;
}

.labelFont {
  font-weight: 500 !important;
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-15 {
  font-size: 15px !important;
}

.treat-header {
  font-weight: 800 !important;
  color: #000;
}

.treat-content {
  font-weight: 400 !important;
}

.common-prof-dt li {
  color: var(--black) !important;
  font-weight: 500;
  font-size: 15px;
  margin-top: 4px !important;
}

.k-menu-link {
  margin-left: 10px;
}

.k-menu-link:hover {
  color: var(--bg_icons) !important;
}

.table-heading-auth .k-grid .k-checkbox {
  box-shadow: 0 1px 4px var(--grid_border);
}

.listBullet li::marker,
.table-heading-auth .k-grid ul li::marker,
li::marker {
  color: #000000 !important;
  height: 11px;
  width: 11px;
}

.table-heading-auth .plus-click i {
  background-color: var(--black);
}

.grey-secondary {
  background: var(--grey_icons);
  border: 1px solid var(--grey_icons);
  font-weight: 500;
}

.grid-table ul {
  margin-bottom: 0 !important;
  padding: 0;
}

.boxHeight .k-listview.k-d-flex {
  height: 261px;
  overflow: hidden;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
  outline: none;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: var(--dashboardCol_border);
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline:hover {
  border-color: var(--activeColor) !important;
}

.gy-2 {
  row-gap: 10px;
}

.brushArrow .k-input-button > .k-button-icon.k-svg-i-caret-alt-down {
  right: 10px;
  top: 5px;
}

.k-checkbox,
input[type="checkbox"] {
  box-shadow: 0 1px 4px var(--grid_border);
  border: 1px solid var(--dashboardCol_border);
  border-color: var(--dashboardCol_border) !important;
  outline: none;
  border-radius: 4px;
}

.k-form-hint {
  font-size: 14px !important;
  color: var(--color_text);
  font-weight: 400;
}

.text-end {
  text-align: right;
}

.text-start {
  text-align: left;
}

.k-switch-md .k-switch-track {
  width: 40px !important;
}

.alertBackgroundPadding {
  padding: 0px 5px 0px 5px !important;
}

.k-switch-off .k-switch-track {
  background-color: #9fa1a8 !important;
  border-color: #9fa1a8 !important;
}

.k-switch-md {
  width: 50px !important;
}

input,
div .k-input,
.client-accept .phone-number {
  color: var(--label_heading);
  opacity: 1;
  margin-bottom: 10px;
  font-weight: 500 !important;
  font-size: 16px;
}

.k-checkbox + .k-label,
.k-checkbox-wrap + .k-label,
.k-checkbox + .k-checkbox-label,
.k-checkbox-wrap + .k-checkbox-label {
  margin-inline-start: 8px;
}

.k-checkbox,
input[type="checkbox"] {
  margin-inline-end: 8px;
}

.filterOut {
  background: #efeeff;
  padding: 5px;
  margin: 0 5px;
  border-radius: 4px;
}

input[type="date"],
input[type="time"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 30px;
  position: relative;
  padding-left: 40px !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 416H288V288h128v128zm64-352v384c0 17.6-14.4 32-32 32H64c-17.6 0-32-14.4-32-32V64c0-17.6 14.4-32 32-32h64V0h64v32h128V0h64v32h64c17.6 0 32 14.4 32 32zm-32 128H64v255.9l.1.1 383.9-.1V192zm0-127.9c-.1-.1-.1-.1 0 0l-64-.1v32h-64V64H192v32h-64V64H64.1l-.1.1V160h384V64.1z"></path></svg>');
  background-repeat: no-repeat;
  padding-right: 0;
  margin-left: -25px;
  filter: invert(28%) sepia(94%) saturate(1000%) hue-rotate(223deg)
    brightness(104%) contrast(99%);
  position: absolute;
  left: 35px;
}

.file-template input[type="text"],
.file-template input[type="date"],
.file-template input[type="time"],
.file-template select {
  height: 40px !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 128h-32v160h160v-32H256V128zm0-96C132.3 32 32 132.3 32 256s100.3 224 224 224 224-100.3 224-224S379.7 32 256 32zm0 416c-105.9 0-192-86.1-192-192S150.1 64 256 64s192 86.1 192 192-86.1 192-192 192z"></path></svg>');
  background-repeat: no-repeat;
  padding-right: 5px;
  margin-left: -25px;
  position: absolute;
  left: 35px;
  filter: invert(28%) sepia(94%) saturate(1000%) hue-rotate(223deg)
    brightness(104%) contrast(99%);
}

input:focus-visible,
textarea:focus-visible {
  border-color: var(--activeColor);
  outline: none;
  box-shadow: none;
}

input[type="radio"] {
  accent-color: var(--blueTheme) !important;
}

/* call confr. */

.video-call {
  width: 100%;
  max-height: 100% !important;
  height: 100vh;
  overflow: hidden;
  background-color: #313131;
}

.video-call_two {
  width: 50%;
  max-height: 100% !important;
}

.video-phone-btn {
  display: flex;
  margin: auto;
  justify-content: end;
  align-items: center;
  background-color: #303030;
  padding: 10px;
}

.video-phone-btn i {
  color: var(--white);
}

.end-call-mainbtn i {
  transform: rotate(135deg);
  margin-right: 5px;
}

.video-cus,
.phone-cus {
  background: var(--blue-primary);
  border-radius: 4px;
  text-align: center;
  padding: 2px 10px;
}

.end-call-mainbtn {
  background: #d13838;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 700;
  padding: 5px 7px;
  border: none;
  display: flex;
  align-items: center;
  gap: 3px;
  color: var(--white);
}

.local-video {
  height: 100%;
}

.end-call-mainbtn i {
  color: var(--white);
}

.copy-mainbtn {
  background: var(--blue-primary);
  border-radius: 4px;
  width: auto;
  border: none;
  cursor: pointer;
  color: var(--white);
  padding: 3px 10px;
  font-size: 14px;
  font-weight: 600 !important;
}

.copy-mainbtn i {
  color: rgb(95 87 103);
}

/**one grid**/
.grid-column-many {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
}

.overflow-hidden {
  overflow: hidden !important;
}

.grid-column-one {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.participant {
  width: 50%;
  margin: 2px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  position: relative;
}

.participant video {
  border-radius: 4px !important;
  object-fit: cover;
  width: 100%;
  overflow: hidden;
}

.participant video::-webkit-scrollbar {
  display: none;
}

.participant h3 {
  font-size: 14px !important;
  position: absolute !important;
  top: 10px !important;
  left: 10px !important;
  z-index: 999999;
  color: var(--white);
  background-color: rgba(103, 99, 99, 0.255);
  padding: 3px 5px;
  border-radius: 4px;
}

#sidefiter .k-input-values {
  max-height: 140px;
  overflow-y: scroll;
}

.naming-list ul {
  padding-left: 15px;
}

.lg_modal .k-window.k-dialog .k-window-actions {
  height: auto !important;
}

.column-room-one {
  height: 100%;
  overflow-y: hidden;
}

.width_90 .k-listview-content::-webkit-scrollbar {
  display: none;
}

.k-reset-assign-up .k-list-item {
  cursor: default !important;
}

.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
  color: #ffffff;
  background-color: var(--blueTheme) !important;
}

.video-phone-btn div {
  cursor: pointer;
}

@media (max-width: 767px) {
  .dash-filter-grid .bottomline h5 {
    font-size: 13px;
  }
  .menu-header-items {
    top: 40px !important;
  }

  .a4-size-page-view {
    padding: 0;
  }
}

@media (max-width: 480px) {
  .dropdown-service {
    width: 150px;
  }

  .service-dropdown {
    width: 250px !important;
    margin-left: -116px;
  }

  .document-dropdown {
    width: 250px !important;
  }
}

.k-grid table,
.k-grid-header-table {
  width: 100% !important;
}

.accesImg {
  width: 100px;
}

.accessText p {
  font-size: 15px;
  max-width: 410px;
  font-weight: 500;
  line-height: 28px;
}

.pdf-content {
  width: 210mm;
  height: 297mm;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: var(--pdf-color);
}

.batchCountCard {
  max-height: 125px;
  min-height: 125px;
  border: 2px solid var(--themeColor);
  box-shadow: none !important;
  border-radius: 10px !important;
}

.themeColor {
  color: var(--themeColor);
}

.hrBorder {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 20px;
}

.AddMore {
  background-color: #339549;
  border: none;
  border-color: var(--blue-primary);
  font-size: 10px;
  color: var(--white);
  border-radius: 5px;
  padding: 3px 5px;
  font-weight: 700;
}

.cross-red {
  font-size: 20px;
  color: var(--red);
  cursor: pointer;
}

.fontWight500 {
  font-weight: 500;
}

.fontWight700 {
  font-weight: 700;
}

.fontWight800 {
  font-weight: 800;
}

.fontSize14 {
  font-size: 14px;
}

.fontSize18 {
  font-size: 18px;
}

.cardContent {
  padding: 10px !important;
}

.marginTopBottom {
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-tooltip {
  background-color: var(--white);
  border: 1px solid var(--custom_tooltip);
  padding: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.custom-tooltip_round {
  background-color: var(--white);
  border: 1px solid var(--custom_tooltip);
  border-radius: 26px;
  min-width: 100px;

  padding-left: 12px;
  padding-right: 12px;
  align-content: "center";
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.circle {
  width: 6px;
  height: 6px;
  background: rgb(136, 132, 216);
  border-radius: 50px;
}

.custom-tooltip p {
  margin: 0;
  padding: 0;
}

.grid-table.table-heading-auth.WhiteUnset {
  width: 100%;
}

.btn-primary {
  border-color: var(--blue-primary);
}

.css-hw1914-MuiResponsiveChart-container {
  width: 600px !important;
  height: 100%;
  padding-top: 30px;
}

.css-78d1bb
  .MuiDataGrid-columnHeader--alignRight
  .MuiDataGrid-columnHeaderTitleContainer {
  flex-direction: unset !important;
}

.css-78d1bb .MuiDataGrid-cell--textRight {
  -webkit-box-pack: end;
  justify-content: start !important;
}

.headingTreat {
  font-size: 24px;
  font-weight: 700 !important;
}

.new-heading-treat {
  font-size: 13px !important;
}

.recharts-rectangle.recharts-tooltip-cursor,
.recharts-tooltip-wrapper {
  background-color: transparent !important;
}

.css-atbvjh {
  --ChartsLegend-rootOffsetX: -19% !important;
}

.css-1ocrsw4-MuiResponsiveChart-container > svg {
  width: 100%;
  height: 70% !important;
}

.importClient {
  width: 40%;
  justify-content: space-between;
}

.k-picker-solid {
  overflow-x: hidden;
}

.address-title.text-grey span.f-20 {
  color: var(--grey_tect) !important;
}

.span-blocks
  span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
  display: block;
  width: 100%;
}

.btnScroller {
  position: absolute;
  right: 20px;
  background: v#021f54;
  color: var(--white);
  border-radius: 50%;
  height: 40px;
  text-align: center;
  width: 40px;
  top: 150px;
  border-color: transparent;
  font-size: 25px;
}

.btn-up.btnScroller {
  top: unset;
  bottom: 180px;
  z-index: 9;
}

.underlineRed {
  text-decoration: underline var(--red);
}

.borderTop + .borderTop {
  border-top: 1px dotted #c7c7c7;
  padding-top: 5px;
  padding-left: 0px;
  padding-top: 20px;
}

.clsTreatment_upload {
  max-height: 80%;
  min-height: 80%;
  overflow: auto;
}

.client-sign-box
  span.k-colorpicker
  span.k-button-icon.k-icon.k-svg-i-caret-alt-down {
  opacity: 1;
}

.client-sign-box .k-input-button > .k-button-icon.k-svg-i-caret-alt-down {
  right: 10px;
  position: absolute;
  top: 4px;
}

.dropInputField .k-chip-list.k-chip-list-md {
  max-width: 375px !important;
}

.small-button {
  width: 30px;
  height: 30px;
  font-size: 14px;
}

.content-search-filter input.icon-searchinput.inputPadding_left {
  padding-left: 20px !important;
}

.content-search-filter.input_client_list input.filtersearch {
  background: var(--white) 0% 0% no-repeat padding-box;
  border-radius: 26px;
  min-width: 320px;
  height: 30px;
  padding-left: 19px !important;
  padding-right: 0 !important;
  font-size: 13px;
  margin: 0;
}

.content-search-filter.input_client_list div .k-input-solid.k-input {
  box-shadow: none !important;
  border: 1px dashed var(--grey_icons) !important;
}

.input_client_list {
  width: 100%;
}

.k-stepper .k-step-current .k-step-indicator,
.k-switch-on .k-switch-track,
.k-radio:checked,
.k-radio.k-checked {
  border-color: var(--blue-primary) !important;
  color: white;
  background-color: var(--blue-primary) !important;
}

.css-15yd5re-MuiSvgIcon-root,
.css-1ujj1ck {
  color: #a5a9a6 !important;
}

.text-darkgrey svg {
  color: #a5a9a6 !important;
}

.pieGoal textarea.k-input-inner {
  margin: 0;
  min-height: unset !important;
  height: 40px;
  padding-top: 8px !important;
  border: 0px;
}

.pieGoal .k-label {
  margin: 0 !important;
}

.pieGoal .k-textarea {
  margin: 6px 0 !important;
}

.btn-transaprent {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 0;
}

.intervationButton .btn-transaprent {
  right: 0;
}

.boxSHADOWADD + .boxSHADOWADD {
  margin-top: 20px;
  border-top: 1px dotted #c6c6c6;
}

.switchAbsolute {
  position: absolute;
  left: 55px;
  height: 18px;
  z-index: 9;
}

.switchAbsolute .k-switch-md .k-switch-track {
  height: 23px !important;
  width: 40px !important;
}

.btn-transparent {
  background-color: transparent;
  border: none;
  font-size: 15px;
}

.btn-transparent .fa-chevron-right {
  color: #000;
  font-size: unset;
  padding-bottom: unset;
}

.orange-text-theme {
  color: var(--blue-primary);
}

.k-actions-button,
.k-scheduler-edit-dialog .k-actions button.k-button,
.k-actions button.k-button {
  padding: 4px 8px !important;
  border-radius: 5px !important;
  color: var(--white) !important;
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
}

.main-objective .k-expander-header {
  background-color: #fff5e64d !important;
}

.main-inter .k-expander-header {
  background-color: #fffbf4 !important;
}

.searchBoxShadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 0 6px 0 rgba(0, 0, 0, 0.12) !important;
  padding: 5px 0;
  border-radius: 20px;
  background-color: var(--white);
  margin: 10px 5px 5px 5px;
}

.showInactiveBatch {
  width: auto;
  margin-right: 15px;
  font-size: 13px;
  color: var(--grey_icons);
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 32px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.child_goal {
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 6%), 0 0px 6px 0 rgb(0 0 0 / 5%) !important;
  margin: 10px 0 15px 0 !important;
  padding: 15px 20px;
}

.pdf_child_goal {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 6%), 0 0px 6px 0 rgb(0 0 0 / 5%) !important;
  margin: 10px 0 15px 0 !important;
  padding: 5px 10px;
  font-size: 14px !important;
  border-bottom: 1px dotted var(--light_grey);
}

.pdf_child_goal:last-child {
  border-bottom: 0;
}

.labelPdf {
  font-size: 13px !important;
}

.pdf_child_goal .valueColor {
  font-size: 14px !important;
}

.edit_transparent {
  background-color: transparent;
  border: none;
}

.padding_Add {
  width: 100%;
}

.padding_Add + .padding_Add {
  width: 10%;
  margin: 0 auto;
}

.f-12size {
  font-size: 12px !important;
}

.serviceLIST .k-listview,
.serviceLIST .k-listview-content {
  background-color: transparent !important;
}

.bg_white {
  background-color: #fff;
}

.boxShadowNone {
  box-shadow: none !important;
}

.dash-filter-grid::-webkit-scrollbar {
  display: none !important;
}

@media screen and (max-width: 1138px) {
  .marginTop2 {
    row-gap: 4px;
  }
}

.billingInsurenace .k-input-button > .k-button-icon.k-svg-i-caret-alt-down {
  right: 18px;
  position: absolute;
  top: 0px;
}

.billingInsurenace .k-clear-value {
  top: 2px;
}

.billingInsurenace .k-combobox {
  min-height: 30px !important;
  font-size: 12px !important;
  margin: 0 !important;
}

.billingInsurenace .k-combobox input {
  min-height: 30px !important;
  height: 30px !important;
  font-size: 12px !important;
  margin: 0 !important;
}

.billingInsurenace .k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  padding-right: 32px !important;
  font-size: 14px;
}

.dropDates .k-svg-i-calendar svg {
  top: 8px;
}

.scrollhide::-webkit-scrollbar {
  display: none !important;
}

.overlapText {
  overflow-wrap: anywhere;
}

.column-gap {
  gap: 10px;
}

.width_scroll {
  width: 100%;
  overflow-x: scroll;
}

.width_scroll::-webkit-scrollbar {
  display: none !important;
}

.scrollInner .k-animation-container {
  width: 100%;
}

.dropdown-service .k-animation-container-shown {
  height: 200px;
  overflow-y: scroll;
}

#content-composer {
  box-shadow: 0 2px 10px rgba(46, 46, 46, 0.2);
}

.headerBottomText {
  position: fixed;
  top: 97px;
  color: var(--white);
  padding: 5px 0;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  background-color: #011f53;
}

/* custom-css */
.custom-grid .k-table .k-table-tbody .k-table-row td,
.custom-grid .k-table .k-table-thead .k-table-row th .k-column-title {
  overflow: unset !important;
  text-overflow: unset !important;
  white-space: pre-line !important;
}

.custom-grid .k-table .k-table-thead .k-table-row .k-table-th {
  vertical-align: top;
}

.custom-grid
  .k-table
  .k-table-tbody
  .k-table-row
  td
  .k-chip
  .k-chip-content
  .k-chip-label {
  overflow: unset;
}

.setps-control
  .a4-size-page
  .file-pdf-document
  .file-template
  .custom-add-only {
  display: unset !important;
  margin-bottom: 5px;
}

.k-animation-container
  .k-child-animation-container
  .k-menu-group-sm
  .k-menu-link {
  padding: 6px 10px !important;
  margin-left: unset !important;
}

.k-animation-container
  .k-child-animation-container
  .k-menu-group-lg
  .k-menu-link {
  padding: 6px 10px !important;
  margin-left: unset !important;
}

.sign-border-space .default-head-btn {
  right: 18px !important;
  top: 53px;
  display: block !important;
}

.cus-sign-space .k-expander .k-expander-content-wrapper .k-expander-content {
  padding: 50px 16px 0px 16px !important;
}

.custom-edit-client
  .k-overlay
  ~ .k-dialog
  .k-dialog-titlebar
  ~ .k-dialog-content {
  overflow: unset !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  height: 85%;
  display: block !important;
}

.custom-edit-client
  .k-overlay
  ~ .k-dialog
  .k-dialog-titlebar
  ~ .k-dialog-content::-webkit-scrollbar {
  display: block;
}

.custom-edit-client
  .k-overlay
  ~ .k-dialog
  .k-dialog-titlebar
  ~ .k-dialog-content::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important;
}

.custom-edit-client
  .k-overlay
  ~ .k-dialog
  .k-dialog-titlebar
  ~ .k-dialog-content::-webkit-scrollbar-thumb {
  background-color: #c1c1c1 !important;
}

.custom-edit-client .k-overlay ~ .k-dialog {
  overflow-y: unset !important;
}

.custom-client-scroll {
  overflow: unset !important;
}

ul.custom-drop-down-list {
  overflow-x: unset;
  overflow-y: unset;
}

.client-card-scroll {
  overflow-y: auto;
}

div.k-grid-header,
div.k-grid-footer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding-right: 0 !important;
}

.k-grid-content {
  width: 100%;
  min-height: 0;
  overflow-x: auto;
  overflow-y: auto !important;
  position: relative;
  flex: 1 1;
}

.intervationButton .k-input-solid {
  margin-bottom: 10px !important;
}

.custom-frequency-input .k-input-solid {
  margin-top: 6px !important;
  margin-bottom: 10px !important;
}

.custom-frequency-input .k-dropdownlist {
  margin-top: 6px !important;
  width: 100% !important;
  margin-bottom: 10px !important;
}

.custom-service-input .k-label {
  margin-top: 2px !important;
}

.css-kgo1mb-MuiChartsSurface-root .MuiPieArcLabel-root:first-child {
  transform: translate3d(0px, 54.7545px, 0px) !important;
}

.date-hover-color-default .k-header .k-item .k-link:hover {
  color: #424242 !important;
}

.date-hover-color-blue .k-header .k-item .k-link:hover {
  color: #07479a !important;
}

.date-hover-color-yellow .k-header .k-item .k-link:hover {
  color: #f3dc2e !important;
}

.date-hover-color-orange .k-header .k-item .k-link:hover {
  color: var(--bg_icons) !important;
}

.date-hover-color-red .k-header .k-item .k-link:hover {
  color: #f22d2d !important;
}

.latest-date-default .k-header .k-item .k-link {
  color: #424242 !important;
}

.latest-date-yellow .k-header .k-item .k-link {
  color: red1 !important;
  background-color: blue !important;
}

.late-date-yellow {
  color: #f3dc2e !important;
}

.latest-date-orange .k-header .k-item .k-link {
  color: var(--bg_icons) !important;
  background-color: #fff7e6 !important;
}

.latest-date-red .k-header .k-item .k-link {
  color: #f22d2d !important;
  background-color: #fff1f0 !important;
}

.css-fwx2rw .MuiPieArcLabel-root:first-child {
  transform: translate3d(0px, 54.7545px, 0px) !important;
}

.custom-service-input .k-input-inner {
  height: 40px !important;
  border: 0px !important;
}

.files-space {
  padding: 0 0 0 0px !important;
  max-width: 180px;
  overflow-wrap: anywhere;
}

.client-doc-files-space {
  padding: 0 0 0 0px !important;
  overflow-wrap: anywhere;
}

.file-attachment {
  padding: 0 0 0 0px !important;
  overflow-wrap: anywhere;
}

.password-hide-show-icon {
  position: absolute;
  right: 10px;
  top: 48%;
}

.days-count-space .k-numerictextbox .k-input-inner {
  padding: 4px 8px 4px 8px !important;
}

.custom-day-switch .k-input input {
  padding: 4px 8px 4px 8px !important;
}

.cus-date-output {
  background-color: #256dc921;
  color: #06479b;
}

.custom-radio-btns .k-radio-list {
  flex-flow: wrap;
}

.custom-batch-id .k-link {
  cursor: default !important;
}

.custom-claim-id .k-link {
  cursor: default !important;
}

.custom-claim-id tr td {
  cursor: default !important;
}

.custom-claim-id tr .anchar-pointer {
  cursor: pointer !important;
}

.authIdPointer {
  cursor: pointer;
}

.st-radio-container label.st-radio-label {
  min-width: 150px;
  margin-top: 4px;
}

.input-radio li {
  padding: 4px 18px !important;
  min-width: 150px;
}

.custom-title-head {
  width: 200px !important;
}

.chip-complete.k-chip-solid-base {
  margin: 0px -5px;
}

.chip-td:nth-child(5) {
  text-overflow: unset;
}

.new-auth-calender .k-input-button > .k-button-icon.k-svg-i-calendar {
  top: -2px !important;
}

.newa4-page {
  border-left: unset !important;
  border-right: unset !important;
}

.cus-doc-head {
  font-weight: 700;
  color: #021f54;
  font-size: 22px;
}

.new-client-form + .k-multiselect .k-input-values .k-input-inner {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #424242 !important;
  margin: 4px 0px !important;
  filter: brightness(0.5);
}

.new-date-form + .k-input .k-dateinput .k-input-inner {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #424242 !important;
  margin: 4px 0px !important;
  filter: brightness(0.5);
}

.red-btn {
  border-radius: 4px;
  font-weight: 700;
  border: 1px solid #ea5564;
  background-color: #ea5564;
  color: #fff;
}

.red-btn:hover {
  color: #fff;
}

.blue-btn {
  border-radius: 4px;
  font-weight: 700;
  border: 1px solid #337ab7;
  background-color: #337ab7;
  color: #fff;
}

.blue-btn:hover {
  color: #fff;
}

.yellow-btn {
  border-radius: 4px;
  font-weight: 700;
  border: 1px solid #f4ab58;
  background-color: #f4ab58;
  color: #fff;
}

.yellow-btn:hover {
  color: #fff;
}

.light-blue-btn {
  border-radius: 4px;
  font-weight: 700;
  border: 1px solid #5bc0df;
  background-color: #5bc0df;
  color: #fff;
}

.light-blue-btn:hover {
  color: #fff;
}

.plan-items {
  width: 130px !important;
  display: inline-block;
  margin-right: 20px;
  color: #697077b2;
  font-size: 14px;
}

.plan-items-head {
  display: inline-block;
  margin-right: 20px;
}

.plan-items-head-new {
  display: inline-block;
  margin-right: 20px;
  color: #697077b2;
}

.multiline {
  white-space: pre-line;
}

.plan-items-head-new.d-block + span.plan-values {
  margin-top: 10px;
}

.tick-mark td {
  cursor: default !important;
}

.plan-values {
  text-align: start;
  font-weight: 500;
  font-size: 14px;
}

.obj-heading {
  padding: 10px 16px;
  background-color: #f5f5f538;
  border-radius: 5px;
}

.custom-main-goal-head .k-expander-header {
  background-color: transparent !important;
  border: unset !important;
  border-color: transparent !important;
}

.custom-main-goal-head .k-expander-header:hover {
  border: unset !important;
  border-color: transparent !important;
}

.custom-main-goal-head .k-expander {
  border: unset !important;
  border-color: transparent !important;
}

.custom-main-goal-head .k-expander:hover {
  border: unset !important;
  border-color: transparent !important;
}

.custom-main-goal-head {
  border-color: transparent !important;
}

.custom-main-goal-head .k-expander-content {
  padding: 16px 0px;
}

.custom-main-goal-head {
  border: 1px solid #dddddd !important;
  margin-bottom: 10px !important;
  border-radius: 6px;
}

.EDI-button {
  text-decoration: underline;
  height: 10px;
}

.x12-data p {
  line-height: 1.2;
}

.pay-rate-text {
  color: #231e2d;
  padding: 0px 15px;
  font-weight: 600;
}

.cus-service-list th .k-link {
  cursor: default !important;
}

.cus-service-list ~ .k-link:hover {
  cursor: default !important;
}

.staff-pay-rates {
  margin: 10px;
}

.staff-pay-rate-radio-button {
  margin-top: 3px;
}

.staff-pay-rates-validation-error {
  margin-bottom: 10px;
}

.side-filter-margin {
  margin-bottom: 120px;
}

.blueThemeTable tbody td {
  padding: 10px 10px;
  white-space: unset !important;
}

.blueThemeTable .table_view_dt thead th {
  border: 0 !important;
}

.clinic-grid-table .k-tabstrip-items-wrapper > ul {
  padding: 0px 0px 0px 0px;
}

.custom-apply-sign {
  position: fixed;
  top: 96px;
  z-index: 10000;
  background: #011f53;
  padding: 10px 40px;
  height: 5-px;
  display: inline-block;
  width: 100%;
  left: 0;
}

.custom-report-table .k-table-thead .k-table-row .k-table-th .k-column-title {
  min-width: unset;
  text-overflow: unset;
  white-space: unset;
  flex-shrink: unset;
  overflow: unset;
}

.custom-report-table .k-table-tbody .k-table-row .chip-td {
  overflow: unset;
}

.custom-report-table .k-table-tbody tr td {
  white-space: unset !important;
}

.timeClockGridHeader {
  margin-top: 15px;
  height: 40px;
  background-color: rgb(240, 239, 239);
  border: 1px solid #e1dede;
}

.k-input-button > .k-button-icon.k-svg-i-calendar {
  top: -2px;
}

.custom-datepicker .k-input-button > .k-button-icon.k-svg-i-calendar {
  top: 7px;
}

.start-timer-icon {
  width: 30px;
  height: 30px;
}

.k-input-button > .k-svg-i-clock {
  top: -2px !important;
}

.custom-zip-divider {
  display: flex;
  flex-direction: column;
}

.custom-zip-divider .k-form-error {
  position: absolute;
  bottom: -11px;
}

.grid-header-cursor .k-link {
  cursor: default !important;
}

.clsWrapText {
  overflow-wrap: break-word !important;
  white-space: inherit !important;
}

.align-clock > div .k-floating-label-container .k-input .k-button .k-svg-icon {
  top: 5px !important;
}

.align-clock .k-floating-label-container .k-input .k-button .k-svg-icon {
  top: 5px !important;
}

.custom-apply-sign {
  z-index: 10;
}

.custom-site-btn {
  width: 100% !important;
  text-align: start;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.custom-site-drop {
  width: 85% !important;
}

.custom-span-width {
  width: 82%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.custom-pdf-icon {
  margin: 6px;
  margin-right: 6px;
}

.custom-pdf-icon::before {
  font-size: 16px;
}

.k-animation-container-shown .k-list-content {
  max-height: 200px !important;
  color: #000;
}

.custom-dt-background {
  transform: translate(0px, 86px);
}

.client-service-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-client-review {
  width: 90%;
}

.custom-client-popup
  .k-dropdownlist
  .k-input-button
  > .k-button-icon.k-svg-i-caret-alt-down {
  top: 0px;
}

.custom-bg-card {
  width: 800px !important;
}

.custom-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.custom-col {
  max-width: 50%;
  width: 50%;
  display: inline-block;
}

.custom-files-cell {
  overflow-x: auto !important;
}

.custom-filename-cell {
  white-space: unset !important;
  text-overflow: unset !important;
  overflow: unset !important;
}

.list-staff-drop
  .k-dropdownlist
  .k-input-button
  > .k-button-icon.k-svg-i-caret-alt-down {
  right: 12px;
  top: 0px;
}

.new-main-goal .k-expander-header {
  order: 2;
  width: 100%;
  padding: 0px 0px;
}

.new-main-goal .new-main-check {
  order: 1;
}

.new-main-goal .k-expander-indicator {
  text-align: end;
  position: absolute;
  top: -22px;
  right: 10px;
}

.new-main-title {
  order: 2;
  width: max-content;
}

/* new style */
.t-word-cus {
  padding: 0px !important;
  white-space: break-spaces !important;
  overflow: hidden !important;
  word-break: break-all !important;
}

.tag_padding span {
  padding: 4.5px 0 !important;
}

.customRowHeight td {
  height: 175px;
}

.grid-container {
  overflow-x: scroll;
}

.attachment-item {
  flex: 0 0 45%;
  box-sizing: border-box;
}

.grid-container > .k-grid-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.custom-draft-btn {
  position: fixed;
  top: 200px;
  right: 40px;
  z-index: 10000000000;
}

.custom-assignset {
  display: flex;
  align-items: center;
  margin-right: 45px;
}

.header-cursor-default {
  cursor: default;
}

.cus-add-img {
  width: 16px;
  height: 16px;
}

.headway-badge {
  position: relative;
  display: inline-block;
}
.headway-badge:hover,
.icon-size .text-darkgrey:hover,
.start-timer-icon:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in;
}

#HW_badge_cont {
  position: absolute !important;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

#HW_badge {
  background-color: #ea4d36 !important;
}

.head-1 {
  font-size: 1.75rem !important;
  font-weight: 500;
  line-height: 1.2;
}

.head-2 {
  font-size: 1.5rem !important;
  font-weight: 500;
  line-height: 1.2;
}

.head-3 {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 1.2;
}

.head-4 {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 1.2;
}

.head-5 {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 1.2;
}

.head-6 {
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 1.2;
}

/* client profile css started */

.clientComment {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: start;
  justify-content: center;
}

.editComment {
  display: inline-flex;
  width: auto;
  margin-top: 10px;
  border: 1px solid #545a69;
  border-radius: 5px;
  justify-content: space-between;
  padding: 2px 10px 3px 10px;
  white-space: normal;
  max-width: 100%;
  text-align: left;
  font-size: 14px;
  align-items: baseline;
}

.editComment .k-icon {
  width: 12px !important;
  height: 12px !important;
  font-size: 13px;
}

.sign-show-img img {
  width: 100%;
  min-height: 250px;
  max-height: 250px;
  object-fit: contain;
  background: #fff;
}

.gridCursor .k-cell-inner > .k-link {
  cursor: default !important;
  width: 100% !important;
}

.orangeBtn {
  background-color: var(--blue-primary) !important;
  color: var(--white) !important;
  border: 1px solid var(--blue-primary) !important;
}

.greyBtn {
  background-color: #ababab !important;
  color: var(--white) !important;
}

.blueTheme div .blue-primary-outline,
.blueTheme .k-button-outline-primary {
  box-shadow: none;
  border-color: #021f54;
  color: #021f54;
  background-color: transparent;
  font-size: 14px;
  padding: 6px 8px !important;
}

.blueTheme .k-button-outline-primary:hover,
.blueTheme div .blue-primary-outline:hover {
  color: #fff !important;
  background-color: #021f54 !important;
}

.blueTheme .k-button-outline-primary:hover img {
  filter: invert(1);
}

.blueTheme .k-button-outline-primary:hover,
.blueTheme .k-button-outline-primary.k-hover {
  border-color: #021f54;
}

.blueTheme .form-control {
  padding: 8px 40px 8px 10px;
  font-size: 14px;
  color: #0e2e64;
  background-color: #f9f9fb;
  border: 1px solid #f9f9fb;
}

.filterRow {
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(33, 39, 49, 0.08);
  margin: 20px 0;
}

.blueTheme img.select_dropdown {
  position: absolute;
  top: 15px;
  right: 10px;
  max-width: 12px;
}

.blueTheme select {
  appearance: none;
}

.filterRow form {
  gap: 10px;
  margin: 0;
}

.blueTheme input:focus,
.blueTheme textarea:focus,
.blueTheme select:focus {
  border-color: transparent !important;
}

.blueTheme .k-input-button > .k-button-icon.k-svg-i-calendar svg {
  display: none;
}

.blueTheme img.leftIcon {
  position: absolute;
  left: 10px;
  top: 9px;
  max-width: 20px;
}

.blueTheme span.k-dateinput.k-input {
  padding-left: 20px;
}

.blueTheme div span.k-dateinput.k-input {
  border: none;
  box-shadow: none !important;
}

.blueTheme div .k-input-solid.k-input {
  border: none;
  box-shadow: none !important;
  margin: 0 !important;
  height: 40px;
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 170px;
  padding-right: 0;
}

.blueTheme.grid-table .k-grid-header-wrap thead {
  background: #dde1e6 !important;
  color: #263238 !important;
}

.eyeIconBlue {
  color: #07479a;
}

.blueTheme .k-grid th,
.blueTheme .k-grid td {
  color: #263238;
}

.blueTheme .k-grid th {
  font-weight: 700;
}

.blueTheme .k-chip-solid-base {
  border-color: transparent !important;
  background-image: none !important;
  padding: 5px;
  border-radius: 5px;
  height: 25px;
  text-align: center;
  width: auto;
}

.blueTheme .filterRow .k-button-outline-primary:hover {
  color: #fff;
  background-color: #f6faff;
  border-color: #f6faff !important;
  transition: all 0.3s ease-in;
}

.blueTheme .filterRow .k-button-outline-primary img {
  transition: all 0.3s ease-in;
  max-width: 19px;
}

.blueTheme .filterRow .k-button-outline-primary:hover img {
  filter: unset;
}

.blueTheme .k-chip-solid-base.edit-btn {
  background-color: #e8f5e9 !important;
}

.blueTheme .k-chip-solid-base.settings-btn {
  background-color: #edebff !important;
}
.k-chip-solid-base {
  width: auto;
}

.clientSign,
.authSign,
.reviewSign,
.pendindSign,
.approvedList {
  background-color: #e0f2f1;
  border-radius: 3px;
  color: #004d40;
  padding: 5px;
  font-size: 12px;
}

h4.address-title.staffHeading {
  color: #697077;
  font-size: 14px;
}

.authSign {
  background-color: #ede7f6;
  color: #4527a0;
}

.reviewSign {
  background-color: #e3f2fd;
  color: #ede7f6;
}

.pendindSign {
  background-color: #fff3e0;
  color: #e65100;
}

.approvedList {
  background-color: #e8f5e9;
  color: #1b5e20;
}

.StaddDocumentation label {
  display: block;
  width: 100%;
  font-size: 14px;
  font-family: 600;
  color: #21272a;
}

.StaddDocumentation .blueTheme .form-control,
.StaddDocumentation textarea,
.StaddDocumentation .blueTheme div .k-input-solid.k-input {
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;
  color: #0e2e64;
  background-color: transparent;
  max-width: 100%;
  border: 1px solid #c1c7cd !important;
  width: 100%;
}

.StaddDocumentation textarea {
  min-height: 80px;
  max-height: 100px;
  width: 100%;
  resize: none;
}

.StaddDocumentation .k-input-button > .k-button-icon.k-svg-i-calendar {
  color: var(--activeColor);
  left: auto !important;
  right: 10px !important;
}

.StaddDocumentation .fileUpload {
  background: #f6faff;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  color: #0e2e64;
  font-size: 14px;
  font-weight: 500;
  margin: 1.25em auto;
  overflow: hidden;
  padding: 0.875em;
  position: relative;
  text-align: center;
  width: 120px;
  cursor: pointer;
}

.StaddDocumentation .fileUpload:hover,
.fileUpload:active,
.fileUpload:focus {
  background: #f6faff;
  cursor: pointer;
}

.StaddDocumentation .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  min-height: 120px;
  max-height: 150px;
  overflow-y: auto;
  scrollbar-width: thin;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px dotted #c1c7cd;
  justify-content: center;
}

.StaddDocumentation .btn {
  color: #0e2e64;
  background-color: #f6faff;
  padding: 8px 20px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
}

.StaddDocumentation .upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.cancelBtn,
.submitButon {
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 500;
  color: #021f54 !important;
  border: 1px solid #021f54 !important;
  transition: all 0.3s ease-in;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 5px;
  margin: 0 0 10px 10px;
}
.btnDanger {
  background-color: red !important;
  font-size: 14px;
  font-weight: 500;
  color: #fff !important;
  border: 1px solid red !important;
  transition: all 0.3s ease-in;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 5px;
  margin: 0 0 0 10px;
}
.submitButon,
.cancelBtn:hover {
  background-color: #021f54 !important;
  color: #fff !important;
}

.submitButon:hover {
  background-color: transparent !important;
  color: #021f54 !important;
}

.uploadEdit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.uploadBox p {
  display: block;
  font-size: 12px;
  color: #000000;
  font-weight: 500;
  margin: 0 !important;
}

.uploadBox span {
  display: block;
  font-size: 10px;
  color: #697077;
  font-weight: 400;
}

.StaddDocumentation .blueTheme img.leftIcon {
  left: auto;
  right: 10px;
}

.StaddDocumentation .blueTheme span.k-dateinput.k-input {
  padding-left: 0;
}

.StaddDocumentation h2 {
  font-size: 24px;
  font-weight: 600;
  color: #21272a;
  margin: 15px 0;
}

p.viewStafftext {
  color: #21272a;
  font-size: 14px;
  padding-left: 10px;
  font-weight: 400;
  margin: 0 !important;
}

.detailBox {
  margin-bottom: 10px;
}

.detailBox h6 {
  font-size: 14px;
  color: #21272a;
  margin-bottom: 10px;
}

.detailBox p {
  font-size: 12px;
  color: #343741;
  padding: 0 12px;
}

.uploadBox {
  padding: 20px 0;
}

.uploadBox .uploadEdit {
  justify-content: flex-start;
}

.uploadBox .uploadEdit .timeBox {
  font-size: 10px;
  color: #697077;
}

.sigBox h6 {
  font-size: 14px;
  color: #21272a;
  font-weight: 500;
}

.sigBox p {
  font-size: 12px;
  color: #a2a9b0;
  margin: 0 !important;
  font-weight: 400;
}

.signImageBox img {
  width: 100%;
}

.signImageBox {
  width: 100%;
  max-width: 300px;
}

.view-teplate-side ul.staffDocumentLIst li {
  text-align: center;
  padding: 8px 10px;
}

.view-teplate-side ul.staffDocumentLIst li a svg {
  max-width: 20px;
  max-height: 20px;
  margin-right: 8px;
}

.deleteList {
  background-color: #ffebee !important;
  color: #c62828;
  border-color: #ffebee !important;
}

.archiveList {
  border-color: #fffde7 !important;
  background-color: #fffde7 !important;
}

.viewStaffDOcument {
  background: #fff;
  padding: 10px;
  box-shadow: 0 0px 4px rgb(30 29 29 / 8%);
}

.blue_theme .file-template span.eleHeader {
  background: #dde1e6 !important;
  padding: 5px;
  margin-bottom: 5px;
  margin-right: 6px;
  margin-top: 10px;
  border-bottom: 1px solid #ccc;
  font-weight: 500;
  font-size: 14px !important;
  display: block;
}

.blue_theme input[type="radio"] {
  accent-color: #021f54 !important;
}

.StaddDocumentation input[type="checkbox"],
.StaddDocumentation .k-radio:checked {
  border-color: #021f54 !important;
  background-color: #021f54 !important;
  accent-color: #021f54 !important;
}

.invterImage img {
  filter: invert(1);
}

.blueTheme .k-button-outline-primary.invterImage:hover img {
  filter: invert(0);
}

.StaddDocumentation .blueTheme.leftCalender img.leftIcon {
  left: 10px;
  right: auto;
}

.StaddDocumentation .blueTheme.leftCalender div .k-input-solid.k-input {
  padding-left: 30px;
}

.blueTheme span.k-link.\!k-cursor-default,
.blueTheme .cursor-default {
  cursor: default !important;
}

.small_modal.xs-modal .k-window-actions {
  max-width: 300px;
  margin-left: 60px;
  margin-right: 60px;
}

.small_modal .k-dialog {
  max-width: 440px;
  margin: auto;
  text-align: center;
}

.StaddDocumentation .blueTheme.leftCalender img.leftIcon.signLeftIcon {
  top: 30px;
}

.errorWrapper {
  position: absolute;
  white-space: nowrap;
  left: 20px;
}

.StaddDocumentation .blueTheme.leftCalender img.leftIcon.topImage {
  top: 40px;
}

.floatLabel span.k-floating-label-container {
  padding-top: 30px !important;
}

.wordWrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
}

.StaddDocumentation div .k-input-solid.k-input.k-textarea {
  box-shadow: none;
}

.StaddDocumentation .file-template textarea {
  width: 99%;
}

.StaddDocumentation .k-input-solid {
  background-color: transparent !important;
}

.noBorder div .k-input-solid.k-input {
  box-shadow: none;
}

.blueTheme .k-multiselect {
  width: 100%;
}

.blueTheme div.staffButton .k-input-solid.k-input {
  width: 100% !important;
  max-width: 100% !important;
  height: auto;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #d7d7d7 !important;
  scrollbar-width: thin;
}

.blueTheme .dropInputField .k-chip-list.k-chip-list-md {
  width: 100%;
  max-width: 100% !important;
  min-width: fit-content;
}

.blueTheme .dropInputField .k-chip-solid-base {
  height: auto;
  width: auto;
}

.blueTheme img.leftIcon.rightDrop {
  left: auto;
  top: 18px;
  right: 10px;
  max-width: 10px;
}

.refreshSync {
  position: absolute;
  right: 20px;
  z-index: 9;
  opacity: 0.4;
  cursor: pointer;
}

.refreshSyncBlue {
  position: absolute;
  right: 20px;
  height: 24px;
  width: 24px;
  z-index: 9;
  cursor: pointer;
}

.blueTheme .staffButton .k-chip-solid-base {
  border-color: transparent !important;
  background-color: #dde1e6 !important;
}

.StaddDocumentation
  .blueTheme.leftCalender.padding_zero
  div
  .k-input-solid.k-input {
  padding-left: 10px;
}

.blueTheme .DropDownImage img.leftIcon {
  right: 10px;
  left: auto;
  top: 17px;
  max-width: 10px;
}

.boxHeight .k-listview.k-d-flex.traningList {
  overflow-y: scroll;
}

.fixedPdf p {
  font-size: 12px !important;
  color: var(--black) !important;
  font-weight: 500;
  margin: 0 !important;
}

.fixedPdf h6.blue-style {
  color: #000 !important;
  font-size: 13px !important;
  margin-top: 5px;
}

.fixedPdf p.valueColor {
  font-size: 12px !important;
}

.blueTheme .profile-box-show .inner-uploadimg {
  min-width: 90px;
  max-width: 90px;
  margin: unset;
  min-height: 100px;
  max-height: 100px;
  width: 100%;
  height: 100%;
}

.blueTheme .client-profileheader.profile-box-show .inner-uploadimg img {
  border-radius: 10px !important;
}

.blueTheme .profiles-tags {
  justify-content: end;
}

.client_details {
  margin: 0 0 0 20px;
}

.flexGap {
  align-items: center;
  gap: 5px 15px;
}

.flexGapBigger {
  gap: 5px 35px;
}

.traningList.caseload-custom.gridheight .k-listview-content {
  height: auto !important;
}

.blueTheme .k-switch-off .k-switch-track {
  border-color: #e5e7eb !important;
  background-color: #e5e7eb !important;
}

.blueTheme span.switch-title-text {
  font-size: 13px;
  color: #697077;
  margin-right: 15px;
}

.blueTheme .client-profileheader.profile-box-show .k-chip-solid-base {
  width: auto;
  margin-bottom: 5px !important;
  font-size: 12px;
}

.obj-heading div .blue-primary-outline {
  background-color: #f6faff;
  border: 0;
  color: #0e2e64;
}

button.btn.btnIcon img {
  max-width: 18px;
  max-height: 18px;
}

.btnIcon {
  border: 1px solid #0e2e64;
  padding: 2px 6px;
}
.btnIcon:hover {
  border: 1px solid #0e2e64 !important;
}

.gapVertical {
  gap: 10px 12px;
}

.btnTrash {
  background: #ffebee;
  padding: 3px 10px;
  border-radius: 5px;
  color: #c62828;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  gap: 3px;
  padding: 6px 10px;
}

.btnTrash img {
  max-width: 15px;
}

.plan-items-head.d-block + span.plan-values {
  font-size: 12px !important;
  margin-top: 10px;
}

.clientSigns p {
  color: #000;
  font-size: 14px;
}

.lightBluebtn,
.treatment_upload .custom-main-goal-head .lightBluebtn:hover {
  background-color: #f6faff;
  color: #0e2e64;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  padding: 7px 10px;
  border-radius: 5px;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  gap: 3px;
  transition: all 0.3s ease-in;
}

.lightBluebtn:hover {
  background-color: #0e2e64;
  color: #ffff;
}

.lightBluebtn img {
  max-width: 18px;
}

.lightBluebtn:hover img {
  filter: invert(1);
}

.table_view_dt.blueThemeTable thead th,
.table_view_dt.blueThemeTable thead td {
  border-right: 0;
  color: #424242;
}

.blueTheme .message-not-found {
  height: auto;
  display: block;
  box-shadow: 4px 4px 10px #21272a0d;
  background: #fff;
  border-radius: 10px;
}

.btnBlueFilled {
  background-color: #021f54;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  padding: 6px 10px;
  border-radius: 5px;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  gap: 3px;
  transition: all 0.3s ease-in;
}

.btnBlueFilled:hover {
  background: transparent;
  border: 1px solid #021f54;
  color: #021f54;
}

.btnBlueFilled:hover img {
  filter: invert(1);
}

.btnBlueFilled img {
  max-width: 18px;
}

.treatment_upload .k-switch-md .k-switch-track {
  height: 26px;
}

.message-not-found h6 {
  font-weight: 600;
  font-size: 16px;
  color: #000;
}

.message-not-found p {
  font-weight: 400;
  font-size: 14px;
}

.treatment_upload .k-switch-on .k-switch-track,
.blue_theme .k-switch-on .k-switch-track {
  border-color: #0e2e64 !important;
  background-color: #0e2e64 !important;
}

.plan-items-head {
  color: #697077b2;
}

.ruth-text {
  color: #21272a;
  font-weight: 600;
  font-size: 14px;
}

.head-column-row {
  box-shadow: 4px 4px 10px #21272a0d;
  border-radius: 10px;
  padding: 10px;
  background: #fff;
}

.message-body {
  color: #697077;
  font-size: 13px;
}

.dropDownCollapse {
  position: absolute;
  right: 0;
  top: 11px;
  max-width: 12px;
  rotate: 0deg;
}

.staff-text:hover,
.side-bar-left ul.k-drawer-items li.k-selected,
.side-bar-left ul.k-drawer-items li:hover {
  background: #ffebe7 !important;
  border-radius: 3px;
  font-weight: 700 !important;
  color: #000;
}

.count_number {
  background: #ea4d36;
  color: #fff !important;
  padding: 7px;
  line-height: 5px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 0;
}

.inner-composer .k-editor {
  border: 0 !important;
  flex-direction: column-reverse !important;
}

/* ledger css */
.input-with-symbol {
  position: relative;
  align-items: center;
}

.input-symbol {
  position: absolute;
  top: 33px;
  left: 10px;
  z-index: 1;
  font-size: 16px;
  color: #000;
}

.input-with-symbol input {
  padding-left: 15px !important;
  text-indent: 10px;
}

.overlapHead {
  max-height: 80vh;
  scrollbar-width: thin;
  overflow-y: auto;
  overflow-x: hidden;
}

.side-bar-left .collapsed .dropDownCollapse {
  rotate: -95deg;
}

.blue_theme .k-dialog-titlebar,
.blue_theme .k-window.k-dialog,
.blue_theme .preview-btn-bottom {
  background: #fff !important;
}

.blue_theme span.k-dateinput.k-input {
  padding-left: 0px;
}

.blue_theme .k-input-button > .k-button-icon.k-svg-i-calendar svg,
.blue_theme .k-svg-i-clock > svg,
.blue_theme
  .k-svg-i-clock
  > svg
  .k-input-button
  > .k-button-icon.k-svg-i-calendar {
  top: -2px;
  right: 0;
  left: unset;
  position: absolute;
  appearance: none;
  visibility: hidden;
  z-index: 2;
}

.blue_theme input:focus-visible,
.blue_theme .k-focus,
.blue_theme div .k-input-solid:focus-within,
.blue_theme div .k-input-solid:focus,
.blue_theme textarea:focus,
.blue_theme input:focus,
.blue_theme textarea:focus,
.blue_theme select:focus {
  border-color: #021f54 !important;
}

img.blueThemeIcons {
  position: absolute;
  top: 38px;
  right: 20px;
}

.blueCheckbox .k-checkbox:checked,
.k-checkbox.k-checked {
  accent-color: #0e2e64 !important;
  border-color: #0e2e64 !important;
  background-color: #0e2e64 !important;
}

.message-page .btnTrash {
  padding: 9px 10px;
}

.clientPannel
  .prev-next.mt-3.mt-md-0.col-lg-2.text-right.col-md-12.d-flex.justify-content-end.align-items-center.p-0 {
  width: 100% !important;
  max-width: 100% !important;
}

.clientPannel .check_delte_icon {
  justify-content: flex-start !important;
}

.staff-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.staff-title-container {
  flex: 0 0 auto;
  margin-right: 2rem;
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

@media (max-width: 768px) {
  .staff-header {
    align-items: flex-start;
  }

  .staff-title-container {
    margin-right: 0;
  }

  .buttons-container {
    width: 100%;
    justify-content: flex-start;
  }
}

.docTitle {
  color: #848484 !important;
  color: var(--lebel_2) !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  padding: 0%;
}

.docContent {
  color: var(--black) !important;
  font-weight: 500;
  font-size: 15px;
  margin-left: 4px !important;
}

.hr-line {
  border: none;
  border-top: 1px solid lightgray;
  margin: 10px 0;
}

.dropdown-disabled .k-input,
.dropdown-disabled .k-button-icon {
  opacity: 0.6 !important;
}

.buttonWidth button {
  min-width: 80px;
}

.blue_theme .xs-modal .k-window-actions {
  height: auto;
  border-radius: 0 0 10px 10px;
}

.site-filter-admin-board {
  position: absolute;
  top: 140px;
  right: 30px;
}

.defauleTitle .k-expander-title {
  color: #000 !important;
}

.dashboardCharSite.site-filter-admin-board {
  position: absolute;
  top: 140px;
  right: auto;
  left: 220px;
}

.clickable-cell,
.anchar-pointer {
  color: #07479a !important;
  cursor: pointer !important;
  width: auto;
}
.clickable-cell:hover {
  color: var(--bg_icons) !important;
}

.non-clickable-cell {
  cursor: default;
}

.dmr {
  page-break-inside: avoid;
}

.dmr {
  margin-top: 20px;
}

.dmr {
  break-inside: avoid-page;
}

.dropMenuDropIcon
  .k-dropdownlist
  .k-input-button
  > .k-button-icon.k-svg-i-caret-alt-down {
  top: 0;
}

.blueThemeTable .k-switch-on .k-switch-track {
  border-color: #021f54 !important;
  color: white;
  background-color: #021f54 !important;
}

.blueThemeTable
  button.k-button.k-button-md.k-button-flat.k-button-flat-primary.k-selected {
  background: #021f54 !important;
  color: #fff !important;
  border-radius: 5px;
}

.blueThemeTable .k-grid-header-wrap thead {
  background-color: #dde1e6 !important;
}

.blue_theme .k-checkbox:checked,
.blue_theme .k-checkbox.k-checked,
.blue_theme .k-radio:checked,
.k-radio.k-checked {
  background-color: #021f54 !important;
  border-color: #021f54 !important;
  box-shadow: none !important;
}

.ms-negative-10 {
  margin-left: -10px;
}

.k-chip-md {
  font-size: 12px !important;
  line-height: normal !important;
}

.submitButon:hover img.mr-2.add-img {
  filter: invert(1);
}

.k-switch-on:focus .k-switch-track,
.k-switch-on.k-focus .k-switch-track {
  outline: 0 !important;
}

.blueTheme .border-spann,
.blue_theme .border-spann {
  color: #021f54;
}

@media screen and (max-width: 575px) {
  .label-dialog .k-window.k-dialog {
    width: 80% !important;
    max-height: none !important;
  }
}

.blueTheme.widget-box .k-expander .k-expander-header {
  background: #dde1e6 !important;
  font-size: 14px;
}
.k-expander .k-expander-header {
  background: #dde1e669 !important;
}

.clientPDFFont .details-grid .valueColor.f-12 {
  font-size: 10px !important;
  margin: 0 !important;
}

.delete-btn {
  display: flex;
}

.blueTheme .k-grid td a {
  color: #07479a !important;
}

:root .clientPDFFont h6 {
  color: #000 !important;
}

.content-search-filter input.icon-searchinput:focus {
  border: 1px solid #dadada !important;
}

.cross-button {
  margin-top: 30px;
  margin-bottom: 29px;
}

.cp-search-icon {
  position: absolute;
  left: 15px;
  top: 13px;
  z-index: 1;
  width: 15px;
}

/* Custom styles */
@media (min-width: 1900px) {
  .background-list-container {
    display: flex;
    justify-content: space-between;
  }

  .education-column,
  .employment-column {
    width: 49%;
  }
}

:root .blueThemeTable span.k-button-text {
  color: grey !important;
}

:root .blueThemeTable .k-pager-numbers button.k-button-flat-primary:hover,
:root .blueThemeTable .k-pager-numbers button.k-button-flat-primary.k-selected {
  background-color: #f8f8f8 !important;
  color: #ffffff !important;
}

.blueThemeTable
  .k-pager-numbers
  button.k-button-flat-primary.k-selected
  span.k-button-text {
  color: #ffffff !important;
}

.assignSupervisordialog .k-window-actions {
  margin: 0px !important;
}

.appointments-table-container {
  width: 100%;
}

.appointments-table {
  min-width: 100%;
}

@media screen and (max-width: 1400px) {
  .appointments-table-container {
    overflow-x: auto;
  }

  .appointments-table {
    min-width: 800px;
  }
}

.responsive-grid-container {
  width: 100%;
  overflow-x: auto;
}

.responsive-grid {
  min-width: 600px;
}

.column-wrap {
  white-space: normal;
  word-wrap: break-word;
}

.sig-dialog.xs-modal .k-window-actions {
  max-width: none !important;
}

.topSpacing {
  padding: 0 0 20px;
}

.topSpacing .k-input-solid.k-input {
  top: 10px;
}

.flexWrapper .view-teplate-side ul.staffDocumentLIst li {
  text-align: left;
}

.staffCross {
  position: absolute;
  right: 0;
  font-size: 12px;
}

.blueTheme .topSpacing .dropdown-service .k-chip-solid-base {
  max-width: 90%;
}

.actionWidth.small_modal.xs-modal .k-window-actions {
  max-width: 100%;
}

.spinner-border {
  width: 16px;
  height: 16px;
  border-width: 2px;
}

.content-search-filter.client_list_resposnive.filterRow {
  gap: 10px;
}

.content-search-filter.client_list_resposnive.filterRow .border-dashed-cus {
  margin-bottom: 0 !important;
  margin-right: 10px !important;
}

.blueTheme .payerInputField .k-chip-list.k-chip-list-md {
  width: auto !important;
  max-height: 350px !important;
}

.blueTheme div.payerHeight .k-input-solid.k-input {
  height: 100% !important;
}

.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected .k-link {
  border-color: rgba(0, 0, 0, 0.08);
  color: #ffffff;
  background-color: #021f54 !important;
}

.calendar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  width: 20px;
  height: 20px;
}

.blue_theme_social_deter .k-button {
  background-color: #ddd;
  color: #000;
}

.blue_theme_social_deter .k-button.k-selected {
  background-color: #021f54 !important;
  color: #fff !important;
  border: 1px solid #021f54;
}

.blue_theme_social_deter .k-button:hover {
  background-color: inherit;
  color: inherit;
  border-color: inherit;
}

.custom-align-start {
  display: flex !important;
  justify-content: flex-start !important;
}

.billingFilterRow input {
  margin-left: 20px !important;
}

.greyTextColor {
  color: #939393 !important;
}

.event-cmt .k-input-solid.k-input,
.event-cmt div .client-accept .phone-number,
.event-cmt div span.k-dateinput.k-input {
  width: 100% !important;
}

.k-button-solid-base.k-selected {
  border-color: #021f54 !important;
  color: white;
  background-color: #021f54 !important;
}

.duplicateBtn {
  background-color: #f6faff !important;
  color: #0e2e64;
}

.k-chip-content .fa {
  color: #0e2e64;
  font-size: 16px;
}

.k-menu-group .k-item:focus > .k-link,
.k-menu-group .k-item.k-focus > .k-link,
.k-menu.k-context-menu .k-item:focus > .k-link,
.k-menu.k-context-menu .k-item.k-focus > .k-link {
  box-shadow: none !important;
}

input[type="checkbox"] {
  accent-color: #021f54 !important;
}

.min_height {
  min-height: 302px;
}

.dashboardInput
  span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
  height: 30px;
  padding-right: 10px;
}

.min_height .bottomline {
  border-bottom: 1px solid var(--dashboardCol_border);
}

.Service-RateList .content-search-filter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Service-RateList .icon-searchinput {
  width: 100%;
}

.Service-RateList .d-flex {
  flex-wrap: wrap;
}

.Service-RateList .switch-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.k-time-header .k-time-now,
.k-time-selector-header .k-time-now {
  color: #07479a !important;
}

.k-menu-group .k-item > .k-link:active,
.k-menu-group .k-item > .k-link.k-active,
.k-menu-group .k-item > .k-link.k-selected,
.k-menu.k-context-menu .k-item > .k-link:active,
.k-menu.k-context-menu .k-item > .k-link.k-active,
.k-menu.k-context-menu .k-item > .k-link.k-selected {
  color: #ffffff;
  background-color: var(--blueTheme) !important;
}

.switch-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.switch-container-mob {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.switch-wrapper-mob {
  display: flex;
  align-items: center;
}

.switch-container div {
  display: flex;
  align-items: center;
}

.clinicFlag .k-picker-md .k-input-inner {
  padding: 5px !important;
}

.authTable.blueThemeTable.blue_theme .alert-info {
  background-color: #d1ecf129;
}

.k-scheduler-edit-dialog .k-actions button.k-button:nth-child(1) {
  background-color: #021f54 !important;
}

.k-scheduler-edit-dialog .k-actions button.k-button:nth-child(1):hover {
  background-color: #fff !important;
  color: #021f54 !important;
  border-color: #021f54 !important;
}

.k-scheduler-edit-dialog .k-actions button.k-button:nth-child(1) {
  transition: all 0.4s ease-in-out !important;
}

.k-scheduler-edit-dialog .k-actions button.k-button:nth-child(2) {
  background-color: #fff !important;
  color: #021f54 !important;
  border-color: #021f54 !important;
}

.k-scheduler-edit-dialog .k-actions button.k-button:nth-child(2):hover {
  background-color: #021f54 !important;
  color: #fff !important;
}

.k-scheduler-edit-dialog .k-actions button.k-button:nth-child(2) {
  transition: all 0.4s ease-in-out !important;
}

.k-actions button.k-button:nth-child(1) {
  background-color: #021f54 !important;
}

.k-actions button.k-button:nth-child(1):hover {
  background-color: #fff !important;
  color: #021f54 !important;
  border-color: #021f54 !important;
}

.k-actions button.k-button:nth-child(1) {
  transition: all 0.4s ease-in-out !important;
}

.k-actions button.k-button:nth-child(2) {
  background-color: #fff !important;
  color: #021f54 !important;
  border-color: #021f54 !important;
}

.k-actions button.k-button:nth-child(2):hover {
  background-color: #021f54 !important;
  color: #fff !important;
}

.k-actions button.k-button:nth-child(2) {
  transition: all 0.4s ease-in-out !important;
}

.wrap-text {
  word-break: break-word;
}

.tab-hover-effect .k-item {
  transition: transform 200ms ease, opacity 200ms ease, box-shadow 200ms ease;
  will-change: transform, opacity, box-shadow;
  backface-visibility: hidden;
  opacity: 0.98;
  position: relative;
}

.tab-hover-effect .k-item:hover {
  transform: scale(1.03);
  opacity: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  margin-bottom: 3px;
  z-index: 10;
}

.fixed-size-button {
  min-width: 130px;
  min-height: 35px;
  max-width: 130px;
  max-height: 35px;
}

.task-hover-icon {
  transition: color 0.3s ease;
}

.task-hover-icon:hover {
  color: #007bff;
  transform: scale(1.1);
  cursor: pointer;
}

.blueTheme .k-button-outline-primary:focus {
  box-shadow: none !important;
}

.blueThemeTable .k-menu-link:hover {
  color: #072d5e !important;
}

.buttonSpace {
  margin-right: 10px !important;
}

.k-scheduler-recurrence-end-rule-editor > div > div > ul > li:nth-child(1) {
  display: none;
}

.blue_theme_danger {
  background: #ffebee !important;
}

:root .blueThemeTable .btnTextFixing span.k-button-text {
  color: #fff !important;
}

:root .blueThemeTable .btnTextFixing:hover span.k-button-text {
  color: #021f54 !important;
}

.staffSetting-section .k-expander-header {
  background: #0000000a !important;
  color: var(--black);
}

.staffSetting-section .k-expander-header .k-expander-title {
  color: var(--black);
  font-weight: 600;
}

.staffSetting-section .k-expander {
  margin-bottom: 30px;
}

.staffSetting-section span label {
  padding-left: 6px;
}

.list-profile li {
  border-bottom: 1px solid #80808029;
  padding: 8px 0;
  margin: 0;
}

.list-profile li:last-child {
  border: 0;
}

.list-profile li a {
  color: #00000094;
  transition: all 0.3s ease-in;
}

.list-profile li a:hover {
  color: #021f54;
}

.scroll_margin::-webkit-scrollbar-thumb {
  margin-top: 20px !important;
  background-clip: content-box;
  padding: 20px !important;
}

.scroll_margin::-webkit-scrollbar-track {
  margin-top: 20px !important;
  background-clip: content-box;
  padding: 20px !important;
}

:root .info_tooltip .k-tooltip .k-callout {
  left: 95% !important;
  right: auto;
  margin: auto;
}

@media print {
  #comment1,
  #comment2 {
    font-size: 16px;
  }

  .z-index-100 {
    z-index: 100 !important;
  }
}

.current-bulletinsHeight {
  height: 435px;
  min-height: 250px;
}

.current-bulletinsHeight .k-listview-content {
  height: 400px;
  overflow-y: auto;
  min-height: 261px;
  font-size: 13px;
}

.mt-100 {
  margin-top: 100px !important;
}

.minHeightTabs .k-tabstrip-content .k-animation-container {
  width: 100%;
}

.meeting-timer {
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff;
  background-color: var(--blue-primary);
  padding: 0px 8px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  letter-spacing: 1px;
  border: 1px solid #cccccc;
  margin-right: 20px;
  width: 100px;
  font-family: "Courier New", Courier, monospace;
  display: inline-block;
}

.calenderBoxImg .k-input-solid.k-input {
  padding-left: 20px !important;
}

.full-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
}

.full-row.important {
  border-left: 3px solid var(--red);
}

.full-row .content {
  flex: 1;
}

.full-row .actions {
  display: flex;
  gap: 8px;
}

.announce-pop {
  height: 95% !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.wordBreak {
  word-wrap: break-word;
  max-width: 85%;
  width: 100%;
}

.searchBoxShadowFilter {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 0 6px 0 rgba(0, 0, 0, 0.12) !important;
  padding: 4px 0;
  border-radius: 20px;
  background-color: var(--white);
  margin: 10px 5px 15px 5px;
}

.filter-export {
  font-size: 14px !important;
  font-weight: 800 !important;
  color: #021f54 !important;
}
.announce-pop h3 {
  font-weight: 700;
}

.blue_theme .timerPickerAlign .k-input.k-timepicker {
  margin: 0 !important;
}

.timerPickerAlign label {
  margin-bottom: 7px;
}

span.k-combobox.k-input,
span.k-maskedtextbox {
  margin-top: 0px !important;
}
input.k-input,
textarea.k-textarea {
  margin: 0 !important;
}

.profile-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is above other elements */
}

.profile-modal-dialog {
  background: white;
  padding: 20px 30px;
  border-radius: 10px;
  width: 350px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}
.profile-btn-primary {
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  margin-bottom: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.profile-btn-primary:hover {
  background-color: #0056b3;
}

.profile-btn-danger {
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.profile-btn-danger:hover {
  background-color: #a71d2a;
}
.profile-modal-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.borderBottomLast:last-child {
  border: 0 !important;
}
.borderBottomLast {
  padding: 5px 0;
  font-size: 14px;
}

.payementMethod {
  max-width: 100%;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  padding: 25px;
  border-radius: 0px;
  box-shadow: 0 0 10px rgb(232 232 232 / 50%);
  margin: 20px 0;
}

.payment-header {
  display: flex;
  justify-content: space-between;
}
.payment-options {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.payment-card {
  flex: 1 1 calc(48% - 20px);
}

.payment-card .card-title {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.use-payment-btn {
  margin-top: 10px;
  padding: 10px;
  border: 2px solid #000;
  background: none;
  cursor: pointer;
}

.payment-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.paymentMode {
  margin-top: 30px;
  max-width: 600px;
}

.paymentMode button {
  margin-top: 30px;
}

.payment-container {
  max-width: 80%;
}

.paymentStatus {
  color: #12b76a;
  border: 1px solid #c3e6cb;
  font-size: 12px;
  background-color: #ecfdf3;
  text-transform: capitalize;
}

.paymentStatus.default {
  color: #0056b3;
  border: 1px solid #0056b3;
  font-size: 12px;
  font-weight: 600;
  background-color: #b7dded;
}
.paymentStatus.set-as-default {
  color: #0056b3;
  border: 1px solid #0056b3;
  font-size: 12px;
  font-weight: 600;
  background-color: transparent;
}
.paymentStatus.paid {
  background-color: #d4edda;
  color: #28a745;
  font-weight: 600 !important;
  border-color: #c3e6cb;
}
.paymentStatus.pending {
  color: #f7e40f;
  border: 1px solid #f7e40f;
  font-size: 12px;
  font-weight: 600;
  background-color: #f5f3e4;
}
.paymentStatus.draft {
  color: black;
  border: 1px solid grey;
  font-size: 12px;
  font-weight: 600;
  background-color: #0000000a;
}
.paymentStatus.cancelled {
  color: red;
  border: 1px solid #ff000057;
  font-size: 12px;
  font-weight: 600;
  background-color: #ff000014;
}

.date-icon-doc-setting {
  margin-top: -19px !important;
  margin-right: -13px !important;
}

.k-split-button .k-button-solid-base {
  background-color: transparent;
  background-image: none;
  color: unset;
}

.k-split-button:hover {
  background-color: #021f54 !important;
  color: #fff !important;
}

.drop-user-toogle .dropdown-menu.dropdown_list {
  transform: unset !important;
  left: -11rem !important;
  padding: 0 !important;
  top: 30px !important;
}
.drop-user-toogle .dropdown-menu.dropdown_list:before,
.payment-card .dropright .dropdown-toggle::after,
li.dropdown.dropright.dropdown_list:after {
  display: none !important;
}

.documentLabel .k-floating-label-container.k-empty > .k-label {
  left: 0px !important;
}
:root .documentLabel .k-input.k-numerictextbox {
  margin-top: 15px !important;
}
.k-input-md .k-input-button {
  padding: 12px 4px !important;
}

.client-doc-date .k-input-md .k-input-button,
.k-input-md .k-spinner-increase,
.k-input-md .k-spinner-decrease,
.k-picker-md .k-input-button,
.k-picker-md .k-spinner-increase,
.k-picker-md .k-spinner-decrease {
  padding: 8px 4px !important;
}
.wrap-content {
  width: 1100px;
  overflow-wrap: break-word;
  padding: 1px;
}

.label_margin label {
  margin-bottom: 0;
}

.socialDemoGraph .css-gpxwrf-MuiResponsiveChart-container {
  width: 332px !important;
  height: 165px !important;
}

.socialDemoGraph .recharts-responsive-container {
  margin: auto !important;
}
.documnet-id-filter .k-chip-label {
  max-width: 270px;
}
.documnet-id-filter .k-combobox .k-clear-value {
  right: 2px !important;
  top: 11px !important;
}

.documnet-id-filter .k-clear-value {
  right: 20px;
  top: 1px;
  opacity: 1;
  width: 10px !important;
  height: 10px !important;
}

.chipMaxWidth .k-chip-content {
  max-width: 96px;
}

.align-clock .k-timepicker {
  margin: 0 !important;
}

.authButton .example-col .k-split-button {
  height: 35px !important;
}

.spsrDocument label {
  line-height: normal !important;
}

.selectMinWidth select {
  min-width: 220px !important;
  background-color: transparent !important;
  color: #000 !important;
  font-weight: 600 !important;
}

.documnet-id-filter .k-combobox .k-clear-value {
  right: 2px !important;
  top: 11px !important;
}

.profile-image img.cameraIcon {
  width: 15px;
  height: 15px;
  object-fit: contain;
  border-radius: 0;
}

.k-svg-i-sort-desc-small > svg,
.k-svg-i-sort-asc-small > svg {
  fill: rgb(0 0 0) !important;
}
.order-given .k-listbox.k-listbox-actions-right {
  flex-direction: unset !important;
}
.order-given.colorOrder .k-listbox.k-listbox-actions-right {
  flex-direction: row-reverse !important;
}

.k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-items-wrapper .k-item.k-active,
.k-tabstrip-items-wrapper .k-item.k-selected {
  border-color: var(--hover-bg) !important;
  background: var(--hover-bg) !important;
  color: var(--black) !important;
}

.service_Auth .content-search-filter .search-icon {
  top: 18px;
}

.k-master-row .k-grid-content-sticky {
  border-color: rgb(209 209 209 / 30%) !important;
}
.table-header th {
  background-color: #c1c7cd;
}
.css-rbklcn-MuiResponsiveChart-container {
  height: 300px !important;
}

.printCheckboxSpace .cats_label > span {
  margin-left: 5px;
  font-size: 14px !important;
}
.printCheckboxSpace {
  padding-left: 15px;
}

.client-name label,
.majorLabel span {
  font-size: 16px !important;
}

.k-floating-label.k-text-error {
  color: #000 !important;
}

.timeMargin .k-timepicker,
.timeMargin .k-multiselect {
  margin-top: 3px !important;
}

.msgeBtn.blue-primary {
  color: #fff !important;
}

.msgeBtn.blue-primary:hover {
  background-color: #021f54 !important;
  color: #fff !important;
}

.k-grid td.overflowVisible {
  overflow: visible !important;
}

.btnTrash.btn:hover {
  color: #c62828 !important;
}
.k-button-flat-primary {
  color: #424242 !important;
}

.listPage-noData .k-grid-norecords-template {
  box-sizing: border-box;
  margin-block: 0;
  margin-inline: auto;
  margin-top: 45px;
  width: 20em;
  height: 4em;
  border: 1px solid;
  border-color: #00000014;
  line-height: 4em;
}

.k-pager {
  -webkit-tap-highlight-color: unset !important;
  background-color: #fff !important;
}
.k-grid .k-grid-pager:focus {
  box-shadow: none !important;
}

.k-grid .k-table-th,
.k-grid td,
.k-grid .k-table-td {
  border-inline-start-width: 1px !important;
  border-right: 1px solid #00000008 !important;
  border-bottom: 1px solid #00000008 !important;
}
.k-grid td:last-child,
.k-grid .k-table-td:last-child {
  border-right: 0 !important;
}
.k-grid tr:last-child td,
.k-grid tr:last-child .k-table-td {
  border-bottom: 0 !important;
}
.labelMargin label {
  margin-bottom: 8px;
}

.k-window-actions {
  height: auto !important;
}

.labelFix label {
  margin-bottom: 0px !important;
}
.k-tabstrip-content.k-focus,
.k-tabstrip-content:focus,
.k-tabstrip > .k-content.k-focus,
.k-tabstrip > .k-content:focus {
  outline-offset: -1px;
  outline-width: 1px;
}

.k-menu-link-text {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #07479a !important;
}

.k-tabstrip-content:focus,
.k-tabstrip-content.k-focus,
.k-tabstrip > .k-content:focus,
.k-tabstrip > .k-content.k-focus {
  outline-width: 1px !important;
  outline-style: none !important;
  outline-offset: -1px !important;
}
.labelFix .k-input.k-datepicker {
  margin-top: 7px !important;
}

.clockGrid .k-grid {
  padding: 0 !important;
}

.widget-box.blueTheme:focus-visible,
.widget-box .k-expander.k-focus {
  box-shadow: none !important;
  outline: 0 !important;
  border-color: #00000014 !important;
}
.selectDropdownLabel .k-floating-label-container > .k-floating-label {
  top: 0 !important;
  left: 0 !important;
}
.selectDropdownLabel.eventLabelFLoat
  .k-floating-label-container
  > .k-floating-label {
  top: -8px !important;
  font-weight: 500;
}

.traningList.caseload-custom.gridheight .k-listview-content {
  height: auto !important;
}

.currentheaderfix {
  position: sticky !important;
  top: 0;
  z-index: 9;
  background: #fff;
}
.css-k3gmpv {
  height: 300px !important;
}

.superBill .f-11 {
  font-size: 11px !important;
  color: #000000;
}

.btnHeightFix button {
  height: 31px;
}

.k-grid-norecords-template {
  border: 0;
}

.heightFixingShow .k-expander.k-expanded {
  max-height: 360px;
}
.heightFixingShow .css-1gtchvp-MuiPaper-root {
  --Paper-shadow: none !important;
}

.striped-table tbody tr:nth-child(even) {
  background-color: #dde1e666 !important;
}

.table > :not(caption) > * > * {
  background-color: unset !important;
  font-size: 13px !important;
}

.k-grid-norecords-template {
  border-color: transparent !important;
}

.edit-Service-popup.complaiceHeightFix {
  max-height: calc(100vh - 235px);
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: none;
}

.inputServiceFix.blueTheme .form-control {
  margin: 0 !important;
  min-height: 4px;
  padding: 5px;
  height: 31px;
}
.inputServiceFix.blueTheme span.k-dateinput.k-input {
  min-height: 24px !important;
}

.dash-filter-grid.topFixHeader .k-listview-header {
  top: 40px;
  position: sticky;
}
.dash-filter-grid.topFixHeader.headerlappingFix .k-listview-header {
  top: 20px;
}
.cursor-pointer,
i.fa.fa-trash,
.k-chip-content.pointerHover {
  cursor: pointer !important;
}

.serviceReportChip .k-chip-content .k-chip-label {
  font-weight: 600 !important;
}

.zIndex9 {
  z-index: 99;
  background-color: #fff !important;
}

.tabs-nurse-dt.clientdropDown .k-animation-container {
  width: auto;
}

.nextBtnHover:hover .fa-chevron-right {
  color: var(--blueTheme);
}

.side-bar-left.reportSideBar ul.k-drawer-items li:last-child {
  padding-bottom: 6px;
}
.lastseen {
  margin-left: 22px;
}

.msg-selectAll {
  margin-bottom: 6px;
  margin-left: 10px !important;
}

.k-switch-md {
  width: 20px !important;
}

.k-switch-md {
  width: 40px !important;
  height: 15px !important;
  font-size: 8px !important;
}

.k-switch-md .k-switch-track {
  width: 45px !important;
  height: 25px !important;
}
.custom-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  min-height: 500px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
}

.custom-card .content {
  flex: 1;
  overflow: auto;
}

.staff-search-toggle .k-switch-md .k-switch-track {
  width: 50px !important;
  height: 25px !important;
}

.staff-search-toggle div .search_input_bar {
  min-width: auto;
}
.k-switch-md.k-switch-off .k-switch-thumb-wrap {
  left: calc(12px + 0px) !important;
}
.k-switch-md.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 8px - 0px) !important;
}

.editDiagnosisHeight {
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: none;
}
.tooltop_Custom {
  transform: translateX(-30px);
}
.lableMarginZero label {
  margin-bottom: 0 !important;
}
.k-chip-text,
.k-chip-label {
  font-weight: 600 !important;
}

.questionairetext p {
  letter-spacing: 0px;
  color: var(--label_heading);
  opacity: 1;
  position: relative;
  margin-bottom: 10px;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.textcolor.default_cursor:hover {
  text-decoration: none;
}
.textcolor.cursor_underline:hover {
  text-decoration: underline;
}

.clientPortalPadding.blueTheme span.k-dateinput.k-input {
  padding-left: 0;
}
.clientPortalPadding.blueTheme .k-timepicker .k-svg-i-clock {
  opacity: 0;
}

.treatment_upload .fa-trash.text-blue-500 {
  cursor: default !important;
}

.text-blue {
  color: #021f53;
}

.alert-warning.text-warning {
  color: #856404 !important;
  background-color: #fff3cd !important;
  border-color: #ffeeba !important;
  font-size: 13px !important;
  padding: 5px;
}

.limitGoalText {
  max-width: 95%;
}

.text-danger-light-tp {
  font-weight: bold;
  background-color: #f8d7da;
  color: #721c24;
}

.text-success-light-tp {
  font-weight: bold;
  background-color: #d4edda;
  color: #155724;
}

.dashboardModal .k-window-content:last-child {
  padding-bottom: 20px !important;
}

.labelMarginbottom label {
  margin-bottom: 13px;
}

.topbar-items .k-input-md .k-input-inner,
.staff-search-toggle div .search_input_bar input {
  height: 28px;
}


.medium-modal.education .dash-filter-grid-bill {
 box-shadow: none;
  padding:0;
  max-height: 465px;
  scrollbar-width: none;
  height: 100%;
}
.medium-modal .dash-filter-grid-bill td{
  padding: 5px;
}

.datePickerMargin  div .k-input-solid.k-input{
  margin-bottom: 0 !important;
}

.marginTopFix span.k-input-solid.k-input{
  margin-top: 8px !important;
}