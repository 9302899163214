.blue {
  color: #07479a;
  cursor: pointer !important;
  transition: all 0.3s ease-in;
}

/* hover effect common */
.bluebox:hover,
.blue:hover {
  text-decoration: underline;
}
