.file-pdf-document label {
    font-size: 14px !important;
}

.file-pdf-document span {
    font-size: 14px !important;
}

.history-header {
    background-color: #263238 !important;
}

.user-pdf {
    width: 150px !important;
    height: 100px !important;
}

.public-template-view {
    display: flex;
    justify-content: center;
    align-items: center;
}