@media (max-width: 1200px) {
  .responsiveLeft.switch-on {
    text-align: left !important;
    justify-content: start !important;
  }

  .responsiveLeft .switch-title-text.text-deign {
    margin: 0 !important;
  }
}

@media (max-width: 2700px) {
  .css-atbvjh {
    --ChartsLegend-rootOffsetX: -35% !important;
  }
}

@media (max-width: 1920px) {
  .css-atbvjh {
    --ChartsLegend-rootOffsetX: -30% !important;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .small-dailog .k-window.k-dialog {
    width: 55% !important;
    height: 70% !important;
  }
}

@media (min-width: 1201px) {
  .small-dailog .k-window.k-dialog {
    width: 40% !important;
  }
}
@media (max-width: 1720px) {
  .editDiagnosisHeight {
    max-height: 450px;
  }
}
@media (max-width: 1600px) {
  .css-atbvjh {
    --ChartsLegend-rootOffsetX: -25% !important;
  }
  .edit-Service-popup.complaiceHeightFix {
    max-height: calc(100vh - 215px);
  }
  .editDiagnosisHeight {
    max-height: 400px;
  }
}

@media (max-width: 1420px) {
  .flexwrapper {
    flex-wrap: wrap;
  }
  .flexwrapper .major-filter {
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 10px;
  }
  .flexwrapper .major-filter .border-dashed-cus {
    margin: 0;
  }
  .edit-Service-popup.complaiceHeightFix {
    max-height: 460px;
  }
}
@media (max-width: 1390px) {
  .color_change_docs {
    height: auto;
  }

  .dynamic_approve_btn button {
    word-break: break-word;
    text-overflow: ellipsis;
    white-space: break-spaces;
    text-align: start;
    width: auto;
  }

  .css-atbvjh {
    --ChartsLegend-rootOffsetX: -25% !important;
  }

  .span-blocks
    span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
    display: block;
    width: 100%;
  }
}

@media (max-width: 1290px) {
  .edit-Service-popup.complaiceHeightFix {
    max-height: 390px;
  }
}
@media (max-width: 1225px) {
  .client_drop.major-filter {
    margin-left: 30px;
  }
  .content-search-filter.client_list_resposnive .client_drop.major-filter {
    margin-left: 0;
  }
  .content-search-filter.client_list_resposnive.filterRow .border-dashed-cus {
    margin-bottom: 10px !important;
    margin-right: 10px !important;
  }
}

@media (max-width: 1200px) {
  .new-flex-text {
    justify-content: start;
    align-items: center;
  }
  .edit-Service-popup.complaiceHeightFix {
    max-height: 320px;
  }
}

@media (max-width: 1024px) {
  .importClient {
    width: 66%;
    justify-content: space-between;
  }

  .span-blocks
    span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
    display: block;
    width: 100%;
  }
  .message-page .content-search-filter input.icon-searchinput {
    min-width: 100%;
  }
  .btnSuccesReponsive .btn.btn-success {
    font-size: 13px;
  }
  .messageOverlapping .k-upload-dropzone {
    gap: 2px;
  }
}

@media (max-width: 992px) {
  .flexWrapperResponsive {
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .day_event {
    display: flex;
    justify-content: start;
  }

  .side-bar-left .navbar-nav ul.drop-down-list {
    overflow-x: scroll;
  }

  .side-bar-left .navbar-nav {
    margin-bottom: 20px;
  }

  .responsiveEND {
    justify-content: end !important;
  }

  .responsiveLeft .switch-title-text {
    padding: 0 5px !important;
  }

  .inner-tabs-dt {
    overflow-x: scroll;
  }

  .profile-edit-show .blue-primary {
    padding: 3px 3px;
    font-size: 14px;
  }

  .profile-edit-show .blue-primary .k-i-edit.mr-2 {
    margin-right: 5px !important;
    font-size: 14px;
  }

  .gap2 {
    row-gap: 5px;
    flex-wrap: wrap;
  }

  .gap2.marginTop2 {
    flex-wrap: unset;
    justify-content: start !important;
  }

  .css-90d22g > svg {
    height: 70% !important;
  }

  .css-atbvjh {
    --ChartsLegend-rootOffsetX: -22% !important;
  }

  .css-1sb0z0s-MuiChartsSurface-root {
    height: 280px !important;
  }

  .content-search-filter.input_client_list input.filtersearch {
    padding-left: 9px !important;
  }

  .client_drop.major-filter .dropdown {
    margin-bottom: 0;
  }
  .inner-dt {
    min-height: unset;
  }
  .client-service-filter {
    display: inline-block;
  }
  .custom-client-review {
    width: 80%;
  }
  .custom-bg-card {
    width: 100% !important;
  }
  .css-13xjf8z {
    font-size: 11px !important;
  }
  .custom-assignset {
    margin-right: 0px;
    margin-left: auto !important;
  }
  .cus-select-table-drops {
    text-align: start !important;
  }

  h4.address-title {
    font-size: 14px;
    font-weight: 600;
  }

  .signature span.fw-normal.pl-1,
  .signature .text-theme {
    font-size: 13px;
  }

  .sign-show-img img {
    min-height: 200px;
    max-height: 200px;
  }

  .file-template .w-750 {
    width: 100% !important;
  }
  .col-12.m-0.pl-0.responivePadding {
    padding: 20px !important;
  }
  .authtable .tabs-kendoselect .k-animation-container {
    overflow-x: scroll;
  }

  .authtable .k-tabstrip-content {
    padding: 0px !important;
  }
  .authtable .alert {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
@media (max-width: 960px) {
  .filter-left-align {
    width: 350px !important;
  }
}
@media (min-width: 900px) {
  .switch-container {
    display: flex;
    align-items: center;
  }

  .switch-title-text {
    margin-right: 8px;
  }

  .info-icon {
    margin-left: 4px;
  }
  .maxWidthSet {
    max-width: 96%;
  }
}

@media (orientation: landscape) {
  .paddingZero {
    padding-left: 0 !important;
  }

  h3 {
    font-size: 20px;
  }

  .client-profileheader.profile-box-show .inner-uploadimg img {
    width: 80px;
    height: 80px;
  }

  .profile-box-show .inner-uploadimg {
    text-align: left;
  }

  .f-24 {
    font-size: 18px;
  }

  .inner-login-notenetic {
    padding: 35px 45px;
  }

  .span-blocks
    span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
    display: block;
    width: 100%;
  }
  .order-given .k-listbox-actions {
    width: 50px;
  }
}

@media (max-width: 885px) {
  .css-atbvjh {
    --ChartsLegend-rootOffsetX: -20% !important;
  }

  .recharts-responsive-container {
    width: 100% !important;
  }

  .btn-adminstrate {
    /* max-width: 40%; */
    text-overflow: ellipsis;
    font-size: 12px;
  }

  .client_drop.major-filter {
    margin-left: 0;
  }
  div .filter-search {
    right: 8px;
  }
}
@media (max-width: 840px) {
  .med-heading {
    display: block !important;
  }
}
@media (max-width: 768px) {
  .p-2.border-bottom.align-middle > .col-2 {
    padding: 0 !important;
  }

  .p-2.border-bottom.align-middle > .col-3.text-right {
    padding: 0 !important;
  }

  h3 {
    font-size: 20px;
  }

  .importClient {
    width: 100%;
  }

  .importClient h4 {
    font-size: 16px;
  }

  .importClient .blue-primary-outline {
    font-size: 14px;
  }

  .span-blocks
    span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
    display: block;
    width: 100%;
  }

  .row.shadowbox.pt-3.border-radis.px-3 {
    margin: 0 !important;
  }

  .widget-box .k-expander .k-expander-header .k-expander-title,
  .details-info p {
    font-size: 13px;
  }

  .show-height-common {
    padding-right: 0;
  }

  div .filter-search {
    right: 5px;
  }
  .batchCountCard {
    max-height: 155px;
    min-height: 155px;
  }
  .order-given .k-listbox-actions {
    width: 50px;
  }
  .responive-order-2 {
    order: 2 !important;
  }
  .responive-order-1 {
    order: 1 !important;
  }
  .responive-order-3 {
    order: 3 !important;
  }
  .content-search-filter.client_list_resposnive.filterRow,
  .responsiveGap {
    gap: 5px;
  }
}

@media screen and (max-width: 360px) {
  .topbar-items.nav-right ul.list-unstyled.d-flex.align-items-center.mb-0 {
    gap: 3px;
  }
  .drop-user-toogle .dropdown-menu {
    top: 41px !important;
    left: 1px !important;
    min-width: 304px;
  }

  .border-dashed-cus2 {
    margin-left: 24px;
    width: 55px;
  }

  .k-button {
    left: 4px;
  }

  .service-dropdown {
    width: 260px !important;
    margin-left: -198px;
    margin-top: -40px;
  }

  .dash-filter-grid h5 {
    font-size: 10px;
  }
  .position-relative.ml-3.searchicons {
    margin-left: 5px !important;
  }
  .top-header .search-icon {
    left: 5px;
    top: 7px;
    font-size: 13px;
  }
  .position-relative.ml-3.searchicons {
    margin-left: 0px !important;
  }

  .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    padding: 5px 13px 8px 7px !important;
  }
  .cancelBtn,
  .submitButon {
    padding: 7px 6px;
    font-size: 9px;
    top: 177px;
  }

  .dashboard-header {
    margin-left: -8px;
    font-size: 12px;
  }
  .dropdown-service {
    top: 70px;
    left: 10px;
  }
}

@media screen and (max-width: 767px) {
  .p-2.border-bottom.align-middle > .col-2 {
    padding: auto !important;
    text-align: center !important;
  }

  .p-2.border-bottom.align-middle > .col-3.text-right {
    padding: auto !important;
  }

  .treat_plan_width .k-window-content.k-dialog-content {
    max-width: 500px;
    width: auto;
    padding: 0px;
  }

  .treat_plan_width .k-widget.k-window.k-dialog {
    width: 308px;
    display: block;
  }

  .offer-text {
    width: auto;
  }

  .banner-img img {
    width: 100%;
  }

  .lg_modal .k-window.k-dialog {
    width: 80%;
  }

  .width_90 .k-window.k-dialog {
    width: 60% !important;
    height: 40% !important;
  }

  .width_90 .k-window.k-dialog {
    height: 90% !important;
  }

  .width_90.SmallHEight .k-window.k-dialog {
    height: 40% !important;
  }

  .navbar-nav ul.drop-down-list {
    padding-left: 3px;
    margin-top: 8px;
    display: flex;
    height: 45px;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .profile-edit-show {
    padding: 20px 20px;
    width: 100%;
  }

  .profile-edit-show .content-inner {
    min-width: 100%;
    padding-left: 0;
    padding-top: 10px;
  }

  .tabletprofile-view {
    flex-wrap: wrap;
  }

  .frequency p {
    font-size: 12px !important;
  }

  .order-given .k-list-scroller {
    width: 550px;
  }

  .order-given.k-pr-2 {
    padding: 0 !important;
    margin-bottom: 10px !important;
  }

  .navbar-nav ul.drop-down-list li.drop-list {
    padding: 0 12px;
  }

  .height45 .k-window.k-dialog {
    height: 45% !important;
  }

  .inner-login-notenetic h2 {
    font-size: 18px;
    line-height: 19px;
    margin-bottom: 15px;
  }

  .inner-login-notenetic .k-floating-label-container input {
    min-height: 39px;
    padding-left: 11px !important;
    font-size: 14px;
    margin-bottom: 0;
  }

  .login-form h1 {
    height: 35px;
  }

  .css-oniiv7 > svg {
    height: 75% !important;
  }

  .span-blocks
    span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
    display: block;
    width: 100%;
  }

  .Service-RateList {
    margin-left: 0;
  }

  .btn {
    font-size: 13px;
  }

  .content-search-filter.input_client_list input.filtersearch {
    padding-left: 9px !important;
  }

  .notenetic-text img {
    width: 160px;
  }

  .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    padding: 4px 36px 4px 8px !important;
  }

  .flex-responsive {
    flex-wrap: wrap;
  }

  .client_list_resposnive .clear-add-filter.col-md-1 {
    text-align: end;
  }
  .batchCountCard {
    max-height: 200px;
    min-height: 200px;
    margin-top: 5px;
  }
  .headerBottomText {
    top: 76px;
    font-size: 12px;
  }
  .password-hide-show-icon {
    top: 55%;
  }
  .custom-site-drop {
    width: 100% !important;
  }
  .custom-pdf-icon {
    margin: 3px;
    margin-right: 6px;
  }
  .select-table-drops {
    text-align: start !important;
  }
  h4.address-title {
    font-size: 17px;
    font-weight: 600;
  }

  .signature span.fw-normal.pl-1,
  .signature .text-theme {
    font-size: 15px;
  }
  .col-12.m-0.responivePadding {
    padding-top: 10px;
  }
  .client_details {
    margin: 0;
  }

  .gapresponsive {
    gap: 5px;
  }
  .payment-container {
    max-width: 100%;
  }

  .paymentFont {
    margin-top: 30px;
  }
  .paymentFont h6 {
    font-size: 14px;
  }
  .staff-search-toggle span.switch-title-text {
    margin-left: 10px;
  }

  .top-header-staff.position-relative.searchStaff {
    margin-top: 10px;
  }
  .top-header-staff .searchStaff {
    top: 10px;
  }
  .staff-search-toggle span.switch-title-text {
    margin-left: 10px;
  }

  .top-header-staff.position-relative.searchStaff {
    margin-top: 10px;
  }
  .top-header-staff .searchStaff {
    top: 10px;
  }
  header.inner-header .navbar-toggler::after {
    font-size: 20px;
  }
  .topbar-items span.search_input_bar {
    width: 60% !important;
    min-width: 160px;
  }
  .staff-search-toggle.border-bottom {
    padding-bottom: 20px;
  }
  .staff-search-toggle .searchStaff {
    top: 8px;
  }
  div .camera-photo {
    bottom: -7px;
    right: -12px;
  }
  .client-profile div .camera-photo {
    bottom: -27px;
    right: 18px;
  }
  .more-btn-drop a.dropdown-toggle {
    margin-left: 0;
    padding-left: 10px;
  }
  .medium-modal.education .dash-filter-grid-bill {
    max-height: 230px;
}
}

@media screen and (max-width: 675px) {
  .css-atbvjh {
    --ChartsLegend-rootOffsetY: 54% !important;
    overflow: initial !important;
  }

  .css-abgfa0-MuiResponsiveChart-container {
    display: block !important;
  }

  .css-oniiv7 > svg {
    height: 60% !important;
  }

  .btn-adminstrate img {
    width: 10px;
  }

  .btn-adminstrate {
    max-width: 100%;
  }
  .f-18 {
    font-size: 16px;
    line-height: 20px;
  }
  .StaddDocumentation .custom-bg-card {
    padding: 15px !important;
  }
  .StaddDocumentation .container-fluid.StaddDocumentation.blue-theme {
    padding: 0 !important;
  }
  li.icon-size.ml-3.mr-1 {
    margin-left: 5px !important;
  }
  .inner-section-edit.position-relative.text-center.tabs-kendoselect.dateDropdown.mt-2 {
    margin-top: 60px !important;
  }
  .dashboardCharSite.responsiveChart {
    left: 10px;
  }
  .dashboardCharSite.responsiveChart .dropdown-service {
    width: max-content !important;
  }
  .wordWrapResponsive {
    white-space: nowrap;
  }
  .staff-header {
    gap: 0;
    margin-bottom: 0 !important;
  }
  span.switch-label.ml-2 {
    font-size: 12px;
  }
  .staff-title-container {
    margin-bottom: 0;
  }
  .staff-header {
    flex-direction: unset;
  }
}

@media screen and (max-width: 575px) {
  .width_90 .k-window.k-dialog {
    width: 90% !important;
  }

  .xs-modal .k-window.k-dialog .btn.btn-sm {
    padding: 15px !important;
  }
  .xs-modal .k-window.k-dialog {
    width: 80% !important;
    max-height: 150px;
  }

  .custom-report-dialog .blue-primary,
  .custom-report-dialog .grey-secondary {
    font-size: 10px !important;
  }

  .custom-report-dialog .k-dialog {
    width: 300px !important;
    height: auto !important;
  }
  .claim-view-table {
    min-width: 3000px;
  }

  .label-dialog .k-window.k-dialog {
    width: 80% !important;
    max-height: none !important;
  }

  .billing-filter {
    display: inline-block;
    max-width: 45vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .claim-action {
    width: 150px !important;
  }
  .august-calender .k-scheduler-layout-flex .k-scheduler-head,
  .k-scheduler-layout-flex .k-scheduler-body {
    width: 1000px !important;
  }
  .august-calender .kendo-scheduler .k-event .k-event-actions .k-event-delete,
  .k-event .k-event-actions .k-event-delete {
    opacity: 1 !important;
    visibility: visible !important;
  }

  .update-modal .Service-accept {
    width: auto;
  }

  .small-dailog .edit-client-popup {
    padding: 7px 20px;
  }

  div .blue-primary,
  div .grey-secondary {
    font-size: 13px;
  }

  .f-24 {
    font-size: 20px;
  }

  .details-info p {
    font-size: 13px !important;
  }

  .forget-text {
    font-size: 14px !important;
    line-height: 22px;
    margin-top: 10px !important;
  }

  .inner-login-notenetic {
    padding: 10px 18px;
  }

  .navbar-nav li.nav-items a {
    font-size: 13px;
  }

  .k-pr-2,
  .k-pl-0 {
    padding: 0 !important;
  }

  .k-reset-assign-up.k-listbox.k-listbox-toolbar-right {
    width: 390px !important;
  }

  .message-not-found {
    margin: 0 !important;
    margin-top: 20px !important;
  }

  .switch-on {
    margin: 3px !important;
  }

  .width-given.xs-modal .k-window-content {
    width: auto;
  }

  .login-wrapper {
    width: 400px;
  }

  .right-align-docs {
    text-align: start;
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  /* .major-filter .border-dashed-cus {
      margin-right: 13px;
    } */
  .__web-inspector-hide-shortcut__,
  .__web-inspector-hide-shortcut__ *,
  .__web-inspector-hidebefore-shortcut__::before,
  .__web-inspector-hideafter-shortcut__::after {
    visibility: inherit !important;
  }
  .topbar-items.nav-right ul.list-unstyled.d-flex.align-items-center.mb-0 {
    gap: 6px;
  }
  .topbar-items.nav-right .HW_badge {
    height: 13px;
    width: 13px;

    line-height: 13px;
    font-size: 10px;

    top: 13px;
  }
  .filter.d-flex {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .side-bar-left .k-drawer-push {
    display: block;
  }
  .btn.btn-success,
  .btn.btn-sm,
  .btn.blue-primary-outline.d-flex.align-items-center {
    font-size: 13px;
    padding: 5px;
    line-height: 5px;
  }

  .comment-button-responsive {
    width: 100%;
  }
  h4.address-title {
    font-size: 14px;
  }
  .top-bar-show-list h4.address-title {
    margin: 0 !important  ;
  }
  .top-bar-show-list .filter.d-flex.align-items-center.col-lg-4.col-md-6 {
    padding: 0 !important;
  }
  .top-bar-show-list h4.address-title > .d-flex.flex-wrap {
    gap: 18px;
  }
  h4.comments-title {
    font-size: 18px;
    font-weight: 600;
  }

  .flexWrapperBox {
    flex-wrap: wrap;
  }

  button.btn.blue-primary-outline.btn-sm.BTNpadding {
    padding: 10px;
  }

  .gap2 {
    gap: 10px;
  }
  .widget-box.widget-width {
    margin-bottom: 45px !important;
  }
  .comment-button-responsive {
    margin-top: 5px;
    width: 120px;
  }
  .border-spann {
    font-size: 12px;
  }
  .notenetic-text img {
    width: 130px;
  }
  .custom-pdf-icon {
    margin: 0px;
    margin-right: 6px;
  }
  .custom-pdf-icon::before {
    font-size: 14px;
  }
  .sign-show-img img {
    min-height: 150px;
    max-height: 150px;
  }

  span.MuiBadge-root.css-1rzb3uu img {
    max-width: 20px !important;
  }
  .inner-content-bottom {
    padding-top: 100px;
  }
  .gap_responsive {
    gap: 5px;
  }
  .d-flex.pt_30.gap_responsive {
    padding-top: 0 !important;
  }
  button.btnHeight {
    height: 26px;
  }
  .authTable .k-tabstrip-content {
    padding: 0 !important;
  }
  .authTable .tabs-kendoselect .k-animation-container {
    overflow-x: scroll;
  }
  .displayblock {
    display: block !important  ;
  }
  .dashboardCharSite.site-filter-admin-board {
    top: 108px;
  }
  .filterRow {
    padding: 15px;
  }
}

@media screen and (max-width: 380px) {
  .filterRow {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .switch-container-mob {
    flex-direction: column;
    align-items: start;
    gap: 15px;
  }
}

@media screen and (max-width: 480px) {
  .css-atbvjh {
    --ChartsLegend-rootOffsetY: 51% !important;
    --ChartsLegend-rootOffsetX: -60% !important;
    overflow: initial !important;
  }

  .k-listbox .k-list-scroller {
    max-width: 300px !important;
  }

  .css-1sb0z0s-MuiChartsSurface-root {
    height: 270px !important;
  }

  .css-1938izf-MuiResponsiveChart-container {
    display: block !important;
  }

  .recharts-responsive-container {
    height: 100% !important;
  }

  .css-1cv1rxz-MuiResponsiveChart-container {
    display: block !important;
  }

  .css-1wbnhbk > svg {
    height: 60% !important;
  }

  .css-atbvjh {
    --ChartsLegend-rootOffsetX: -80% !important;
  }

  .span-blocks
    span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
    display: block;
    width: 100%;
  }

  .responsiveLeft.switch-on {
    gap: 10px;
  }

  .resposniveMX.mx-2 {
    margin: 0 !important;
  }
  .notenetic-text img {
    width: 120px;
  }
  .start-timer-icon {
    width: 20px;
    height: 30px;
  }
  .nav-right ul li.icon-size svg {
    width: 18px !important;
    height: 18px !important;
  }
  .nav-right ul li.icon-size img {
    width: 18px !important;
    height: 18px !important;
  }
  .custom-client-review {
    width: 70%;
  }
  .custom-assignset {
    display: inline-block;
    margin-right: 0px;
  }
  .new-flex-text {
    column-gap: 10px;
  }
  .notenetic-text img {
    width: 100px;
  }
  .position-relative.ml-3.searchicons {
    margin-left: 8px !important;
  }
  .topbar-items
    span.k-autocomplete.k-input.search_input_bar.k-input-md.k-rounded-md.k-input-solid {
    width: 70% !important;
  }
  .nav-right ul li.icon-size {
    font-size: 19px;
    margin: 0 !important;
  }
  .user-top {
    width: 30px;
    height: 30px;
  }

  .top-header {
    padding: 6px 10px 6px;
  }
  .top-header .headway-badge {
    font-size: 18px;
  }
  .top-header .icon-size .css-4tylvu-MuiBadge-badge {
    font-size: 8px;
    width: 20px;
    min-width: auto;
    padding: 4px;
    height: 19px;
    padding: 0;
  }
  div .search_input_bar {
    font-size: 12px;
    padding: 5px !important;
    min-height: 25px !important;
    padding-left: 25px !important;
  }
  .top-header .search-icon {
    left: 8px;
    top: 6px;
  }
  .topbar-items.nav-right ul.list-unstyled.gap-3 {
    gap: 4px !important;
  }
  .top-header-menu {
    padding: 2px 5px 10px;
  }
  .top-header-menu .css-1c32n2y-MuiBadge-root {
    margin-right: 10px !important;
  }
  .nav-right ul li.icon-size {
    font-size: 10px;
    margin: 0 !important;
  }
  .staff-header {
    flex-direction: column;
  }
  .paddingLeftGIven {
    padding-left: 18px;
  }
  .payemntColumnWidth {
    flex: 0 0 200px !important;
    max-width: 100%;
  }
  .payemntRow {
    flex-wrap: nowrap;
  }
  .payment-card {
    overflow: scroll;
  }
  .staff-search-toggle div .search_input_bar {
    min-height: 34px !important;
  }
  .k-grid .k-table .k-grid-header-sticky,
  .k-grid .k-grid-content-sticky {
    position: initial !important;
  }
  .new-insurance {
    width: 100% !important;
    margin: 0 0 0 0 !important;
  }

  .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    padding: 4px 8px 4px 8px !important;
  }
  .responsiveFieldSearch {
    width: 100%;
  }

  .searchBoxShadow .service-dropdown {
    width: 250px !important;
    margin-left: 0;
  }
  .menu-header-items ul {
    padding-top: 20px;
    position: absolute;
    background: #fff;
    height: 100%;
    padding-left: 10px;
    width: 230px;
  }
  header.inner-header nav.navbar {
    top: 3px;
  }
  .doctor-text span {
    font-size: 22px;
    line-height: 25px;
  }
}
@media screen and (max-width: 460px) {
  .top-header-menu {
    padding: 0 5px 0;
  }
  .icon-size .css-2ao5rv-MuiBadge-badge {
    min-width: 14px;
    height: 14px;
    font-size: 8px !important;
    right: 7px;
  }
}
@media screen and (max-width: 425px) {
  .css-1sitl3j-MuiResponsiveChart-container {
    display: block !important;
  }

  .css-shqaet-MuiResponsiveChart-container {
    display: block !important;
  }

  ul.drop-down-list {
    height: calc(100vh - 420px);
  }

  .inner-login-notenetic {
    padding: 35px 25px;
  }

  .k-pr-2,
  .k-pl-0 {
    padding: 0 !important;
  }

  .k-reset-assign-up.k-listbox.k-listbox-toolbar-right {
    width: 250px !important;
  }

  .width_90 .k-window.k-dialog {
    height: 85% !important;
  }

  .login-wrapper {
    width: 350px;
  }

  .width_90.SmallHEight .k-window.k-dialog {
    height: 40% !important;
  }

  .width_90.mediumHeight .k-window.k-dialog {
    height: 55% !important;
  }

  .css-72i9jy-MuiResponsiveChart-container {
    display: block !important;
  }

  .css-1p0mzyu > svg {
    width: 100%;
    height: 50% !important;
  }

  .span-blocks
    span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
    display: block;
    width: 100%;
  }

  .f-24 {
    font-size: 18px;
  }

  .top-header {
    padding: 0 0;
  }

  .inner-content-bottom {
    padding-top: 90px;
  }

  .top-header .search-icon {
    left: 6px;
  }

  .nav-right ul li.icon-size.mx-3 {
    margin-left: 5px !important;
    margin-right: 10px !important;
  }

  .toop-header .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    padding: 0 15px 0 8px !important;
  }

  div .search_input_bar {
    min-height: 27px;
    padding-left: 20px;
    font-size: 12px !important;
  }

  .header-site-cus .k-dropdownlist {
    width: 165px !important;
  }
  .btn.btn-success,
  .btn.btn-sm,
  .btn.blue-primary-outline.d-flex.align-items-center {
    padding: 10px;
  }
  .add-btn {
    padding: 4px 6px !important;
  }
  .order-given .k-listbox-actions {
    width: 50px;
  }

  .custom-apply-sign {
    padding: 10px 10px;
  }
  .strongly_labels {
    gap: 10px;
  }
  .top-header-menu {
    padding: 8px 5px 4px;
  }
  .header-site-cus {
    margin: 0 0;
  }
  .nav-shadow {
    padding: 0 8px !important;
  }
  .document-dropdown {
    margin-left: -100px;
  }
  .setting-tabs-staff ul .k-item {
    padding: 4px;
    font-size: 13px;
  }
  .tabletprofile-view {
    padding-top: 0;
  }
  .grid-table ul {
    padding: 0;
  }
  .navbar-nav ul.drop-down-list li.drop-list {
    white-space: nowrap;
    display: flex;
    align-items: anchor-center;
    max-height: 30px;
  }
  .k-dialog-wrapper.width_90.heightresponsive {
    height: auto;
    top: calc(100vh - 400px);
  }
  .leftCuttingService .service-dropdown {
    margin-left: 0;
  }
}

@media (max-width: 400px) {
  .dropdown-service {
    width: 150px;
    position: inherit;
  }

  .k-pager-info {
    font-size: 11px;
  }
  .left-mob {
    left: -150px;
  }
}
@media screen and (max-width: 376px) {
  .searchBoxShadow .service-dropdown {
    max-width: 250px !important;
    margin-left: -220px;
  }
  .document-dropdown {
    margin-left: -70px;
  }
  .notenetic-text img {
    width: 80px;
  }
  .dashboard-right {
    gap: 0 0 0 5px;
  }
}
@media screen and (max-width: 370px) {
  .gap2.marginTop2 {
    flex-wrap: wrap;
  }
  .inner-login-notenetic {
    width: 300px;
    margin: auto;
  }
  .custom-client-review {
    width: 50%;
  }
  .new-flex-text {
    column-gap: 5px;
  }

  .dashboardCharSite.responsiveChart {
    left: 10px;
  }
  .authCalender .btn-size-cus {
    padding-left: 0;
    padding-right: 0;
    font-size: 12px !important;
  }
  .authCalender .major-filter .dropdown {
    margin-right: 8px;
  }
  .service-main .btn-size-cus {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .major-filter .dropdown {
    max-width: 90%;
    padding-right: 10px;
  }
}

@media screen and (max-width: 320px) {
  ul.drop-down-list {
    height: calc(100vh - 300px);
  }

  .doctor-text span {
    font-size: 20px;
  }

  .css-shqaet-MuiResponsiveChart-container {
    display: block !important;
  }

  .css-atbvjh {
    --ChartsLegend-rootOffsetY: 41% !important;
  }

  .f-24 {
    font-size: 12px;
  }
}

@media screen and (max-width: 280px) {
  span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
    width: 200px !important;
  }

  .css-shqaet-MuiResponsiveChart-container {
    display: block !important;
  }

  .span-blocks
    span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
    display: block;
    width: 100%;
  }

  .details-info p {
    font-size: 12px !important;
  }

  .inner-login-notenetic {
    width: 260px;
    margin: auto;
    padding: 15px 15px;
  }
}

@media (max-width: 550px) {
  .display-mob {
    display: inline-block !important;
  }
  .display-mob {
    flex-wrap: wrap !important;
  }
}

@media screen and (max-width: 480px) {
  .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    padding: 3px 8px 4px 3px !important;
  }
  .topbar-items .k-input-md .k-input-inner {
    height: 11px;
  }

  .top-header .search-icon {
    left: 8px;
    top: 6px;
    font-size: 12px;
  }
  .border-dashed-cus2 {
    margin-right: 2px;
  }
  .dashboard-header {
    margin-left: -7px;
  }
  .dashboard-header-setting {
    margin-right: 1px;
  }
  .dashboard-right {
    margin-left: 4px;
  }
  .service-dropdown {
    width: 250px !important;
    left: -56px;
  }
  .dashboardInput span.k-dropdownlist {
    height: 30px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .top-header-menu .search_input_bar span.k-clear-value {
    right: -3px;
    top: 2px;
  }
  .top-header-menu .search_input_bar .k-svg-icon {
    width: 12px;
    height: 12px;
  }
  .staff-search-toggle div .search_input_bar input {
    margin-left: 10px;
    height: 20px;
    font-size: 14px;
  }
  .user-name a {
    font-size: 14px;
  }

  .list-profile li a {
    font-size: 13px;
  }
  .list-profile li {
    padding: 3px 0;
  }
}

@media screen and (max-width: 430px) {
  .border-dashed-cus2 {
    margin-left: -3px;
  }
  .dashboard-header {
    margin-left: -8px;
    font-size: 11px;
  }
  .lg_modal .k-window.k-dialog {
    width: 84% !important;
  }
  .topbar-items span.search_input_bar {
    width: 60% !important;
    min-width: 139px;
  }
  .service-dropdown {
    width: 250px !important;
    left: -33px;
  }
  .drop-user-toogle .dropdown-menu {
    top: 38px !important;
  }

  header.inner-header nav.navbar {
    top: 16px;
}
}

@media screen and (max-width: 412px) {
  .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    padding: 6px 18px 5px 0px !important;
  }
  .nav-shadow {
    height: 40px;
    margin-top: 4px;
  }
  .lg_modal .k-window.k-dialog {
    width: 88% !important;
  }
  .cancelBtn,
  .submitButon {
    padding: 6px 6px;
    font-size: 11px;
  }
}

@media screen and (max-width: 414px) {
  .lg_modal .k-window.k-dialog {
    width: 87% !important;
  }

  .dash-filter-grid h5 {
    font-size: 12px;
  }
  h3 {
    font-size: 18px;
  }

  .topbar-items span.search_input_bar {
    width: 60% !important;
    min-width: 120px;
  }

  .dropdown-service {
    top: 57px;
    left: -59px;
  }

  .drop-user-toogle .dropdown-menu {
    top: 37px !important;
    left: 26px !important;
  }
}

@media screen and (max-width: 375px) {
  .width_90 .k-window.k-dialog {
    width: 97% !important;
  }

  .lg_modal .k-window.k-dialog {
    width: 83% !important;
  }
  .btn {
    font-size: 12px !important;
  }
  .dash-filter-grid-bill h5 {
    font-size: 15px;
  }
  .lg_modal .k-window.k-dialog {
    width: 94% !important;
  }
  .k-button {
    left: 7px;
  }
  .cancelBtn,
  .submitButon {
    padding: 6px 8px;
    font-size: 12px;
  }
  .dash-filter-grid h5 {
    font-size: 12px;
  }
  .ml-1,
  .mx-1 {
    font-size: 12px;
  }

  .border-dashed-cus2 {
    margin-left: -6px;
    width: 64px;
    height: 29px;
  }

  .cross-icon {
    margin: 4px;
  }
  .custome-nav {
    background-color: #fff;
    margin-left: -16px;
  }
  .topbar-items span.search_input_bar {
    width: 60% !important;
    min-width: 88px;
  }
  .dashStaff {
    height: 40px;
    width: 40px;
  }
  .doctor-text span {
    font-size: 20px;
  }
  .dropdown-service {
    top: 29px;
    left: -50px;
  }

  .drop-user-toogle .dropdown-menu {
    top: 40px !important;
    left: 32px !important;
  }
}

@media screen and (max-width: 390px) {
  .border-dashed-cus2 {
    margin-left: -5px;
    width: 58px;
    height: 25px;
  }
  .cross-icon {
    font-size: 6px;
    margin: 3px;
  }
  .cancelBtn,
  .submitButon {
    margin: 0 0 10px 23px;
  }
  .topbar-items span.search_input_bar {
    width: 60% !important;
    min-width: 84px;
  }
  .cross-icon {
    width: 14px;
    height: 14px;
  }
  .dropdown-service {
    top: 29px;
    left: -49px;
  }
}

@media screen and (max-width: 768px) {
  .dash-filter-grid h5 {
    font-size: 13px;
  }
}
