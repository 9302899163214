:root {
    --blue-primary: #ea4d36;
}
.custom-loading-color {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    font-weight: bold;
    display: block;
}

.k-loading-text {
    margin-bottom: 20px;
}

.k-loading-image,
.k-loading-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.k-loading-image {
    border: 6px solid var(--blue-primary);
    border-top-color: transparent;
    animation: spin 5s linear infinite;
}

.k-loading-color {
    background-color: var(--blue-primary);
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(231, 230, 254, 0.2);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.custom-loading-color {
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    color: var(--blue-primary) !important;
    transform: translate(-50%, -50%) !important;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}