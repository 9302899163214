$border-radius: 2px;
$primary: #ea4d36;
$secondary: #ababab;
$info: #3e80ed;
$success: #5ec232;
$warning: #fdce3e;
$error: #d51923;
$body-text: #424242;
$body-bg: #ffffff;
$headings-text: #272727;
$subtle-text: #666666;
$disabled-text: #8f8f8f;
$component-text: #424242;
$component-bg: #ffffff;
$base-text: #424242;
$base-bg: #fff;
$hovered-text: #424242;
$hovered-bg: #f9d5a414;
$selected-text: #ffffff;
$selected-bg: #ea4d36;
$kendo-button-text: #424242;
$kendo-button-bg: #f5f5f5;
$link-text: #07479a;
$link-hover-text: #0855ba;
$series-a: purple;
$series-b: #ffe162;
$series-c: #4cd180;
$series-d: #635aff;
$series-e: #ac58ff;
$series-f: #ff5892;


@import "~@progress/kendo-theme-default/dist/all.scss";