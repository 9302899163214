.kblinks {
    color: grey;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
}

.custom-hr {
    border: 0;
    height: 1px;
    background: #ccc;
    margin: 10px 0;
    margin-bottom: 20px;
}